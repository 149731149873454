const messages = {
  titleColorRev: "true",
  buttons: {
    pending: "Pending",
    open: "Open",
    requestAccess: "Request Access",
  },
  myBsApps: '<span class="text-danger">My Applications</span> Bridgestone',
  my: "My Applications",
  directRoute: "Your direct links to Bridgestone web applications.",
  welcomeMessage:
    'Welcome to BRIDGESTONE PLUS <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  showProfile: "Show Profile",
  newMessages: "New message in the inbox",
  myApps: "My Applications",
  allApps: "All Applications",
  myAppsDescription: "All Bridgestone web applications you are registered for.",
  allAppsDescription:
    "All Bridgestone web applications that you might be interested in.",
  myProfile: {
    welcomeMessage:
      "Welcome to your personal area. Here you can update or adjust your information and view your inbox.",
    masterData: "Master Data",
    completeDetails:
      "Complete your personal and company details to ensure correct registration.",
    goToMasterData: "Go to My Data",
    goToInbox: "Go to Inbox",
  },
  masterData: {
    welcomeMessage:
      "Welcome to your personal area. Here you can update or adjust your master data.",
    personalDetails: "Personal Details",
    companyDetails: "Company Details",
    emailAddress: "Email Address",
    title: "Title",
    surname: "Last Name",
    firstName: "First Name",
    jobPosition: "Job Position",
    streetAndHouseNumber: "Company Address",
    postalCode: "Postal Code",
    city: "City",
    country: "Country",
    legalStructure: "Legal Structure",
    customerNumber: "Bridgestone Customer Number",
    importantPleaseRead: "Important, please read",
    customerNumberInfo:
      "To correctly assign your tire orders to various dealers, it is important to provide your customer number.",
    multipleCustomerNumbers:
      "If you have multiple customer numbers with a dealer, e.g., for your main company and branches, please first provide the main company's customer number.",
    salesAssignment:
      "Sales linked to additional customer numbers will be assigned to your main company.",
    supplier: "Supplier",
    addAnotherCustomerNumber: "Add Customer Number",
    save: "Save",
    delete: "Delete",
  },
  inbox: {
    welcomeMessage: "Here you can find notifications and important news.",
    sender: "Sender",
    subject: "Subject",
    date: "Date",
    formConsulting: "Form received - Thank you for completing your profile.",
    back: "Back",
  },
  legalAndContact: {
    cookieSettings: "Cookie Settings",
    dataPolicy: "Data Policy",
    termsOfUse: "Terms of Use",
    helpAndContact: "Help and Contact",
  },
  logInSignUp: {
    logIn: "Log In",
    signUp: "Sign Up",
    logInMessage: "Log in with your email address and password.",
    signUpMessage: "If you are a new user, please sign up.",
  },
};

export default {
  messages,
};
