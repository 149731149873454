const messages = {
  title: "Pagrindinis",
  welcomeMessage:
    'Sveiki atvykę į BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Pagrindinis",
  search: "Ieškoti",
  sortBy: "Rūšiuoti pagal",
  seeMore: "Rodyti daugiau",
  bankstatement: {
    yourScore: "Dabartinis jūsų rinkodaros taškų skaičius:",
    lastUpdate: "Naujausias atnaujinimas:",
    link: "Taškų balanso apžvalga",
  },
  shop: {
    bestSold: "Labiausiai parduodami",
    seasonal: "Sezoniniai",
    affordable: "Įperkami",
    forPoints: "už {points} taškus",
    reachedPoints: "Pasiektas reikiamas taškų skaičius",
    cartRemainPoints: "Likusios taškai",
    remainingPoints: "Trūksta dar {points} taškų",
    noProducts: "Neradinta rezultatų",
  },
  showAllProducts: "Eik į parduotuvę",
};

export default {
  messages,
};
