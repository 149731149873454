import TokenService from "../services/storage.service";

const getters = {
  getMe: (state) => state.user,
  getBankStatementHeadersStandard: (state) =>
    state.bankStatement.columns?.tableHeaders,
  getBankStatementHeadersBoosters: (state) =>
    state.bankStatement.columns?.boosters,
  getBankStatementHeadersAdditional: (state) =>
    state.bankStatement.columns?.all,
  getUserAddresses: (state) => state.addresses,
  getSelectedAddress: (state) => state.addressSelected,
  getPoints: (state) => state.bankStatement.columns?.allPoints,
  getBankStatementData: (state) => state.bankStatement.data,
  getLastBankStatement: (state) => state.lastBankStatement,
  getLastMonthPoints: (state) => state.bankStatement.lastMonthPoints,
  getCurrentMonthMinus: (state) => state.bankStatement.currentMonthMinus,
  getCurrentMonthPlus: (state) => state.bankStatement.currentMonthPlus,
  getLastMonthKickbacks: (state) => state.bankStatement.lastMonthKickbacks,
  getCurrentMonthKickbacksMinus: (state) =>
    state.bankStatement.currentMonthKickbacksMinus,
  getCurrentMonthKickbacksPlus: (state) =>
    state.bankStatement.currentMonthKickbacksPlus,
  getCategories: (state) => state.categories,
  getProducts: (state) => state.products,
  getProfile: (state) => state.profile,
  getMessages: (state) => state.messages,
  getProductsMeta: (state) => state.productsMeta,
  getProductsPageNumber: (state) => state.productsPageNumber,
  getBestSoldProducts: (state) => state.bestSoldProducts,
  getSeasonalProducts: (state) => state.seasonalProducts,
  getSoonAffordableProducts: (state) => state.soonAffordableProducts,
  getProduct: (state) => state.product,
  getCart: (state) => state.cart,
  getProductRequestSent: (state) => state.productRequestSent,
  getCategoriesRequestSent: (state) => state.categoriesRequestSent,
  getJobPositions: (state) => state.jobPositions,
  getTraining: (state) => state.training,
  getTrainings: (state) => state.trainings,
  getQuestions: (state) => state.questions,
  getTrainingResult: (state) => state.trainingResult,
  getTrainingPoints: (state) => state.hasPoints,
  getEndQuizDate: (state) => state.endQuizDate,
  getBTrainings: (state) => state.btrainings,
  getBTraining: (state) => state.btraining,
  getBTrainingDetails: (state) => state.bTrainingDetails,
  getBTrainingCosts: (state) => state.bTrainingCosts,
  getBTrainingTypes: (state) => state.bTrainingTypes,
  getBTrainingResult: (state) => state.bTrainingResult,
  getUserSlides: (state) => state.userSlides,
  getTextPages: (state) => state.textPages,
  getTextPage: (state) => state.textPage,
  getPickupLocations: (state) => state.pickupLocations,
  getWatchedVideo: (state) => state.watchedVideo,
  getPending: (state) => state.isPending,
  getToast: (state) => state.toast,
  getUserOrders: (state) => state.userOrders,
  getUserOrder: (state) => state.userOrder,
  getUserPromotions: (state) => state.userPromotions,
  getNewsFeeds: (state) => state.newsFeeds,
  getCurrentNewsFeed: (state) => state.currentNewsFeed,
  getUserProducts: (state) => state.userProducts,
  getWholesalers: (state) => state.wholesalers,
  getGroups: (state) => state.cooperations,
  getSingleHunter: (state) => state.singleHunter,
  getPrices: (state) => state.prices,
  getPriceCategories: (state) => state.priceCategories,
  getSinglePriceList: (state) => state.singlePriceList,
  getDamageTypes: (state) => state.damageTypes,
  getTyrePositions: (state) => state.tyrePositions,
  getVehicleTyres: (state) => state.vehicleTyres,
  getTyreWears: (state) => state.tyreWears,
  getSalutations: (state) => state.salutations,
  getPointsByCsNumber: (state) => state.pointsByCustomerNumber,
  getTyrePositionChildren: (state) => state.tyrePositionChildren,
  getShowNewInvestment: (state) => state.investmentSupportNew,
  getCertificateTexts: (state) => state.certificateTexts,
  getPercentageNum: (state) => state.profilePercentageNum,
  getMenu: (state) => state.menu,
  getUnreadMessages: (state) => state.unreadMessages,
  getKickbackInfo: (state) => state.kickbackInfo,
  getMediaCategories: (state) => state.mediaCategories,
  getSingleMedia: (state) => state.singleMedia,
  getSingleMediaCategory: (state) => state.singleMediaCategory,
  getAllDealersNumber: (state) => state.allDealers,
  getWshSelect: (state) => state.wshSelect,
  getIsUnknown: (state) => state.inUnknownGroup,
  getDefaultBanners: (state) => state.defaultBanners,
  getDealerApps: (state) => state.dealerApps,
  getPermissions: (state) => state.permissions || TokenService.getPermissions(),
  getMediaFiles: (state) => state.mediaFiles,
  getPaginationLinks: (state) => state.paginationLinks,
  getTotalMediaFiles: (state) => state.totalMediaFiles,
  getTyreLimit: (state) => state.tyreLimit,
  getPromotions: (state) => state.promotions,
  getAllWholesalers: (state) => state.allWholesalers,
  getBrochureCategories: (state) => state.brochureCategories,
  getFileBrochures: (state) => state.brochureFiles,
  getMainBoxes: (state) => state.mainBoxes,
  getFetchMenu: (state) => state.fetchMenu,
  getFetchLeftColumn: (state) => state.fetchLeftColumn,

  getConditionModal: (state) => state.showConditionModal,
  getAccessRestricted: (state) => state.accessRestricted,
  getAccessData: (state) => state.accessData,
  getInitialSurveyStep: (state) => state.initialSurveyStep,
  getParentAttributes: (state) => state.parentAttributes,
  getInitialParentAttributes: (state) => state.initialParentAttributes,
  getBussinessUnits: (state) => state.businessUnits,
  getIsProfilePending: (state) => state.profileIsPending,
  getIsMePending: (state) => state.meIsPending,
  // tenancy part
  isLiteVersion: (state) => {
    console.log(state.isLiteVersion);
    return true;
  },
  availableModules: (state) => {
    return [...state.availableModules]; // Spread to extract array values and make it plain
  },
  tenancyEnabled: (state) => {
    return state.tenancyEnabled;
  },
  tenantId: (state) => {
    return state.tenantId;
  },
  bothRelationOptionDisabled: (state) => {
    return state.bothRelationOptionDisabled;
  },
  fleetRelationOptionDisabled: (state) => {
    return state.fleetRelationOptionDisabled;
  },
  noBussinesRelationOptionDisabled: (state) => {
    return state.noBussinesRelationOptionDisabled;
  },
  getCurrentLocale: (state) => {
    return state.currentLocale;
  },
  getNoRequiredPersonalData: (state) => {
    return state.noRequiredPersonalData;
  },
  getAboutPage: (state) => {
    return state.aboutPage;
  },
  getContactService: (state) => {
    return state.contactService;
  },
  getTenantPermissions: (state) => {
    return state.tenantPermissions;
  },
  getSliderIsLoading: (state) => {
    return state.sliderIsLoading;
  },
  getShowExtraDocumentModal: (state) => {
    return state.showExtraDocumentModal;
  },
  getShowCommitmentLetterModal: (state) => {
    return state.commitmentLetterModal;
  },
  getShowPrivacyPolicyModal: (state) => {
    return state.privacyPolicyModal;
  },
  getCanSeeCartIcon: (state) => {
    return state.canSeeCartIcon;
  },
};

export { getters };
