const messages = {
  title: "Il mio estratto conto personale",
  desc: "Qui puoi visualizzare il tuo estratto conto personale Bridgestone Plus con i tuoi punti bonus accumulati e utilizzati. Tieni presente che l'accredito dei punti per i tuoi ordini di pneumatici può richiedere fino a 6 settimane, a seconda della fonte.",
  score: "Saldo punti",
  regularPoints: "Punti standard",
  boosterPoints: "Punti bonus",
  date: "Data",
  supplySourceReason: "Fonte/Motivo dell'acquisto",
  article: "Articolo",
  brand: "Marca",
  product: "Prodotto",
  type: "Tipo",
  inch: "Pollice",
  season: "Stagione",
  amount: "Quantità",
  standardPoints: "Punti",
  boosterProduct: "Prodotto bonus",
  boosterSeason: "Stagione bonus",
  otherActions: "Altre azioni o motivi",
  totalPoints: "Punti totali",
  income: "Entrate",
  spending: "Spese",
  lastMonth: "Saldo finale",
  loadMore: "Carica altri 20 movimenti",
  typeFilter: "Filtra per fonte",
  dateFilter: "Filtra per periodo",
  customer_number: "Numero cliente",
  currentPoints: "Punti attuali",
  calculationDate: "Data di calcolo",
  positivePoints: "Punti positivi",
  negativePoints: "Già spesi",
  tyresAmount: "Numero di pneumatici all'anno",
  pointsTotal: "Punti totali",
  currentPointState: "Stato attuale dei punti",
  currentKickbackState: "Rimborso attuale", // "Kickback" può essere tradotto come "rimborso"
  points: "Points",
};
export default {
  messages,
};
