<template>
  <MDBCardBody>
    <MDBCardTitle v-html="formattedWelcomeMessage"></MDBCardTitle>
    <MDBCardText>
      <template v-if="
        !isWholesaler && !isStaff && !isWholesalerStaff && canSeeWelcomePoints
      ">
        <p v-if="profilePercentage != 100">
          {{ $t("dashboard.profile.completeProfile") }}
          <span class="success-green fw-bold cursor--pointer" @click="goToMyAccount"
            :disabled="user?.commitment_letter_accept && user?.intro == 1">
            {{ $t("dashboard.profile.completeProfileBold") }}
          </span>
        </p>
        <p v-else>
          {{ $t("dashboard.profile.fullCompleteProfile") }}
        </p>
      </template>
      <DashboardLink v-if="!isWholesaler && !isStaff" class="text-uppercase" :to="{ name: 'stammdaten' }"
        :disabled="user?.commitment_letter_accept && user?.intro == 1">
        {{ $t("account.buttons.showProfile") }}
      </DashboardLink>
    </MDBCardText>
  </MDBCardBody>
</template>

<script setup>
import { MDBCardBody, MDBCardTitle, MDBCardText } from "mdb-vue-ui-kit";
import DashboardLink from "../Dashboard/Dashboard-link.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, watch, ref } from "vue";
import TokenService from "@/services/storage.service";
import { useI18n } from "vue-i18n";

const { t } = useI18n() // Import the translation function from i18n
const store = useStore();
const router = useRouter();

const user = computed(() => store.getters.getMe);
const profile = computed(() => store.getters.getProfile);

const isWholesaler = ref(false);
const isWholesalerStaff = ref(false);
const isStaff = ref(false);
const canSeeWelcomePoints = ref(false);

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesalerStaff")) {
    isWholesalerStaff.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "staff")) {
    isStaff.value = true;
  }

  canSeeWelcomePoints.value = TokenService.showElement("welcomePoints.see");
});

const profilePercentage = computed(() => {
  return sessionStorage.getItem("percentage") || store.getters.getPercentageNum;
});

const goToMyAccount = () => {
  router.push({ name: "stammdaten" });
};

const formattedWelcomeMessage = computed(() => {
  if (profile.value && profile.value.forename && profile.value.name) {
    return t("dashboard.welcomeMessage", {
      first_name: profile.value.forename,
      last_name: profile.value.name
    });
  } else {
    return t("dashboard.welcomeMessage", {
      first_name: '',
      last_name: ''
    });
  }
});
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";
@import "@/styles/welcome_box.scss";
@import "@/styles/mailbox.scss";
</style>
