const messages = {
  title: "Начало",
  welcomeMessage:
    'Добре дошли в BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Начало",
  search: "Търсене",
  sortBy: "Сортиране по",
  seeMore: "Виж повече",
  bankstatement: {
    yourScore: "Вашият текущ брой маркетингови точки:",
    lastUpdate: "Последна актуализация:",
    link: "Преглед на баланса на точките",
  },
  shop: {
    bestSold: "Най-продавани",
    seasonal: "Сезонни",
    affordable: "Достъпни",
    forPoints: "за {points} точки",
    reachedPoints: "Постигната е необходимата бройка точки",
    cartRemainPoints: "Оставащи точки",
    remainingPoints: "Липсват още {points} точки",
    noProducts: "Няма намерени продукти",
  },
  showAllProducts: "Отидете до магазина",
};
export default {
  messages,
};
