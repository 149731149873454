<template>
  <MDBCard class="border-0">
    <CategoryTop small :title="$t('account.profile.title')" :breadcrumbs-parent-routes="['account']"
      :description="!tenantConfig.readOnlyProfile ? $t('account.profile.description') : $t('account.profile.block_description')"
      content-class="px-4 mt-4" left-col="6" right-col="6">
      <template #right>
        <MDBRow v-if="!tenantConfig.readOnlyProfile">
          <MDBCol col="12" class="mb-5">
            <div class="progress">
              <div class="progress-bar bg-danger progress-bar-striped progress-bar-animated" role="progressbar"
                :style="'width:' + fillPercent + '%'" :aria-valuenow="fillPercent" aria-valuemin="0"
                aria-valuemax="100">
                {{ fillPercent }}%
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </template>
    </CategoryTop>
    <template v-if="tenantConfig.readOnlyProfile">
      <ReadOnlyLiteProfile />
    </template>
    <MDBTabs v-model="activeTabId" v-else>
      <MDBRow>
        <MDBCol md="12" class="px-5 pb-4">
          <!-- Tabs navs -->
          <MDBTabNav tabsClasses="profile-tabs" v-if="isLite" pills>
            <MDBTabItem tabId="general" href="general" class="text-center py-1 " :class="`${activeTabId === 'general'
              ? 'bg-dark text-white'
              : 'bg-secondary-gray text-dark'
              }`">
              <div class="item--height d-flex justify-content-center align-items-center"
                v-html="$t('account.profile.general')"></div>
            </MDBTabItem>
            <MDBTabItem tabId="preference" href="preference" class="text-center py-1" :class="`${activeTabId === 'preference'
              ? 'bg-dark text-white'
              : 'bg-secondary-gray text-dark'
              }`">
              <div class="item--height d-flex justify-content-center align-items-center">{{
                $t("account.profile.activityAndProfile") }}
              </div>
            </MDBTabItem>
          </MDBTabNav>
        </MDBCol>
      </MDBRow>


      <MDBTabContent>
        <MDBTabPane tabId="general">
          <VeeForm class="form user-form" @submit="onSubmit">
            <MDBRow>
              <MDBCol xl="4" md="6" class="mb-4">
                <PersonalData ref="personalData" :showDots="true"></PersonalData>
              </MDBCol>

              <MDBCol xl="4" md="6" class="mb-4">
                <OperatingData ref="operatingData" @diffAddress="diffAddress"></OperatingData>
              </MDBCol>

              <MDBCol xl="3" md="6" class="mb-4">
                <div>
                  <label for="legalForm" class="label px-3 text-uppercase pt-5">
                    {{ $t("account.profile.legalForm") }}
                  </label>
                  <input type="text" class="form-control" :placeholder="$t('account.profile.legalForm')"
                    :aria-label="$t('account.profile.legalForm')" aria-describedby="basic-addon1" v-model="legalForm"
                    id="legalForm" />
                </div>

                <div class="switch-wrapper d-flex flex-column justify-content-center pt-4" v-if="!isLite">
                  <div class="form-check form-switch">
                    <input v-model="diffDeliveryAddress" class="form-check-input" type="checkbox" role="switch"
                      name="switch_addr" />
                    <label class="form-check-label font-12" for="switch_addr">{{
                      $t("account.profile.diffDeliveryAddres")
                    }}</label>
                  </div>
                </div>
                <DeliveryData v-show="diffDeliveryAddress" ref="deliveryData"></DeliveryData>
              </MDBCol>
            </MDBRow>
            <MDBRow v-if="!isWholesaler && !isStaff && !isWholesalerStaff && !isLite">
              <MDBCol cols="12" class="mb-4">
                <ClientNumbers></ClientNumbers>
              </MDBCol>
            </MDBRow>

            <MDBRow class="pb-3" :class="isLite ? 'd-none' : ''" v-if="!isLite">
              <MDBCol col="12" class="mb-4">
                <OpeningHoursData ref="openingHoursData"></OpeningHoursData>
                <IndicatorsData ref="indicatorsData"></IndicatorsData>
              </MDBCol>
              <MDBCol col="12" class="mb-4">
                <Field type="checkbox" name="conditions" v-model="conditions" :value="true" :unchecked-value="false"
                  class="form-check-input" :label="$t('account.profile.acceptTerms')" :rules="{ required: true }">
                </Field>
                <span class="px-2 text-decoration-underline--hover cursor--pointer" @click="pushToRules()">{{
                  $t("account.profile.acceptTerms") }}</span>
                <div>
                  <ErrorMessage name="conditions" class="text-danger text-small text-end m-0 pt-1" />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol col="12" class="text-start">
                <div v-html="$t('account.profile.newCustomerNumbersInfo')" class="pb-3"
                  v-if="!isWholesaler && !isStaff && !isLite">
                </div>
                <div v-if="!isGermany && !isLite">
                  <div v-if="me.salespersons_dealers && me.salespersons_dealers.length > 0" class="pb-4">
                    <div v-for="(seller, index) in me.salespersons_dealers" :key="index">
                      <div v-if="!seller.is_internal">
                        <span class="fw-bold">{{ $t("contact.form.contactPerson") }}:</span>
                        {{ seller.name }}
                        <a :href="'mailto:' + seller.email">{{ seller.email }}</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="!isLite">
                  {{ $t('account.profile.contactInfo') }} <a href="mailto:plus@bridgestone.eu">plus@bridgestone.eu</a>
                </div>

                <MDBBtn type="submit" color="danger" :disabled="loading"
                  class="d-flex justify-content-center align-items-center">
                  <span>{{ $t("global.buttons.send") }}</span>
                  <Loader :show="loading" :small="true" spinner="spinner.svg" :tiny="true" />
                </MDBBtn>
                <p v-if="apiError" class="text-danger text-small text-end m-0 pt-2 text-small">
                  {{ apiError }}
                </p>
              </MDBCol>
            </MDBRow>
          </VeeForm>
        </MDBTabPane>
        <MDBTabPane tabId="preference">
          <LiteVersionPreferences v-if="isLite" ref="customerNumbers" />
        </MDBTabPane>
      </MDBTabContent>
    </MDBTabs>
  </MDBCard>
</template>

<script setup>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import CategoryTop from "@/components/General/CategoryTop/index.vue";
import { MDBRow, MDBCol, MDBCard, MDBBtn, MDBTabs, MDBTabNav, MDBTabItem, MDBTabContent, MDBTabPane } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { ref, onMounted, computed, toRaw, watch } from "vue";
import { toast } from "vue3-toastify";
import ReadOnlyLiteProfile from "@/components/User/Profile/ReadOnlyLiteProfile.vue";
import PersonalData from "@/components/User/ProfileForms/PersonalData.vue";
import OperatingData from "@/components/User/ProfileForms/OperatingData.vue";
import DeliveryData from "@/components/User/ProfileForms/DeliveryData.vue";
import OpeningHoursData from "@/components/User/ProfileForms/OpeningHoursData.vue";
import IndicatorsData from "@/components/User/ProfileForms/IndicatorsData.vue";
import ClientNumbers from "@/components/User/ProfileForms/ClientNumbers.vue";
import LiteVersionPreferences from "@/components/User/ProfileForms/LiteVersionPreferences.vue";
import Loader from "@/components/General/Loader/index.vue";

import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useTenantConfig } from "@/composables/useTenantConfig";
import textTransform from "@/plugins/textTransform";

import { ProfileService } from "@/services/profile.service";
import { TextPageService } from "@/services/textPage.service";
import TokenService from "@/services/storage.service";

import { fetchUserProfile, afterSuccess } from "@/composables/profile.js";

const store = useStore();
const router = useRouter();
const { tenantConfig } = useTenantConfig();
// Tenant Configuration
const { t } = useI18n(); // Import the translation function from i18n
const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const personalData = ref(null);
const operatingData = ref(null);
const deliveryData = ref(null);
const openingHoursData = ref(null);
const indicatorsData = ref(null);
const diffDeliveryAddress = ref(false);
const apiError = ref(null);
const legalForm = ref(null);
const isWholesaler = ref(false);
const isWholesalerStaff = ref(false);
const isStaff = ref(false);
const conditions = ref(false);
const loading = computed(() => store.getters.getPending);
const profile = computed(() => store.getters.getProfile);
const me = computed(() => store.getters.getMe);
const isLite = computed(() => store.getters.isLiteVersion).value;
const activeTabId = ref('general')

const diffAddress = (bool) => {
  diffDeliveryAddress.value = bool;
};

const customerNumbers = ref([]);

const profilePercentage = computed(() => {
  return sessionStorage.getItem("percentage") || store.getters.getPercentageNum;
});

const onSubmit = async () => {
  if (!personalData.value.models.forename || !personalData.value.models.name) {
    apiError.value = t('onboarding.requiredFields');
    return
  }
  const { vat, email, username, id, ...personal } = personalData.value.models;
  const { ...operating } = operatingData.value.models;
  const { lang, ...mainAddress } = operating;

  let args = {
    id,
    user: {
      username,
      email,
      country: operating.country,
      lang,
      vat,
      is_active: true,
    },
    profile: {
      user_id: id,
      legalForm: legalForm.value,
      ...personal,
      ...openingHoursData.value?.models,
      ...mainAddress,
      ...indicatorsData.value?.models,
      conditions: conditions.value
    },
    addresses: [mainAddress],
  };

  if (diffDeliveryAddress.value) {
    args.addresses.push(toRaw(deliveryData.value.models));
  }

  if (!diffDeliveryAddress.value && deliveryData.value.models.id) {
    deliveryData.value.models.to_remove = true;
    args.addresses.push(toRaw(deliveryData.value.models));
  }

  if (args.user.country !== "ch") {
    args.user.lang = "de";
  }

  const response = await store.dispatch("updateUser", args);

  if (response.status === 200) {
    await fetchUserProfile(store);
    await afterSuccess(
      id,
      profile.value.id,
      fillPercent.value,
      me.value.plainId,
      store
    );

    apiError.value = null;

    toast(t("toast.profileSaved"), {
      type: "success",
      theme: "colored",
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } else {
    apiError.value = response.data.message;
  }
};

const fillPercent = computed(() => {
  let legalFormPercentage = legalForm.value ? 100 : 0;
  let percentages
  if (!isLite) {
    percentages = [
      personalData.value?.percentage,
      operatingData.value?.percentage,
      !diffDeliveryAddress.value ? 100 : deliveryData.value?.percentage,
      openingHoursData.value?.percentage,
      indicatorsData.value?.percentage,
      legalFormPercentage,
    ];
  } else {
    percentages = [
      personalData.value?.percentage,
      operatingData.value?.percentage,
      !diffDeliveryAddress.value ? 100 : deliveryData.value?.percentage,
      legalFormPercentage,
    ];
  }

  let length = percentages.length;

  return Math.round(percentages.reduce((acc, a) => acc + a, 0) / length);
});

watch(me, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesalerStaff")) {
    isWholesalerStaff.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isStaff.value = true;
  }
  if (profilePercentage.value == 100 && TokenService.showElement("welcomePoints.see")) {
    await ProfileService.storeFullProfilePoints(me.value.plainId);
  }
});


watch(
  () => store.getters.getProfile,
  (newVal) => {
    legalForm.value = newVal.legalForm;
  }
);

watch(diffDeliveryAddress, (newVal) => {
  diffAddress(newVal);
});

onMounted(async () => {
  await fetchUserProfile(store);

  if (isLite) {
    await store.dispatch('loadParentAttributes', me.value.id)
  } else {
    await store.dispatch("loadGroups");
    await store.dispatch("loadJobPositions");
    await store.dispatch("loadUserAddresses");
    await store.dispatch("loadWholeSalers");
  }
  const { id } = personalData.value.models;

  const data = {
    user_id: id,
    profile_id: profile.value.id,
    percentage: fillPercent.value,
  };

  await store.dispatch("storeProfilePercentage", data);
});

const pushToRules = async () => {
  const response = await TextPageService.textPageBySlug("terms-and-conditions");

  if (response.status == 200) {
    router.push({
      name: "text_page",
      params: {
        id: response.data.data.id,
        slug: textTransform.convertToSlug(response.data.data.title),
      },
    });
  }
};

</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
@import "@/styles/profile.scss";

.item--height {
  min-height: 40px !important;
}
</style>
