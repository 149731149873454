const messages = {
  title: "Mājas",
  welcomeMessage:
    '%{first_name} %{last_name}, Laipni lūdzam BRIDGESTONE <span class="text-danger">PLUS</span> platformā',
  home: "Mājas",
  search: "Meklēt",
  sortBy: "Kārtot pēc",
  seeMore: "Skatīt vairāk",
  bankstatement: {
    yourScore: "Jūsu pašreizējais mārketinga punktu skaits:",
    lastUpdate: "Jaunākais atjauninājums:",
    link: "Punktu bilances pārskats",
  },
  shop: {
    bestSold: "Visvairāk pārdotie",
    seasonal: "Sezonālie",
    affordable: "Piemēroti",
    forPoints: "par {points} punktiem",
    reachedPoints: "Nepieciešamais punktu skaits ir sasniegts",
    cartRemainPoints: "Atlikušies punkti",
    remainingPoints: "Trūkst vēl {points} punkti",
    noProducts: "Meklēšanas kritērijiem neatbilst neviens rezultāts",
  },
  showAllProducts: "Iet uz veikalu",
};

export default {
  messages,
};
