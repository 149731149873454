const messages = {
  title: "Sales Promotions",
  desc: "Actively use our sales promotions with your customers. You are, of course, welcome to offer customers both promotions. Read more about the Tire Warranty and Drive Our Best below.",
  participation: {
    title: "Apply for Participation Now",
    desc: "Would you like to implement one or more of our promotions in your store? Then you can apply here to apply for participation. Your company will be activated for the promotion(s), and you will receive promotional materials within 4 weeks promotional materials for your sales dream.<br/><br/>Make your choice:",
    request: "APPLY FOR PARTICIPATION",
    success: "Gracias, su solicitud ha sido enviada.",
  },
};
export default {
  messages,
};
