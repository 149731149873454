import moment from 'moment'
import 'moment/locale/en-gb' // Import English locale
import { useI18n } from 'vue-i18n'
// prettier-ignore
/**
 * Filters data
 */
// Function to determine the correct Moment.js locale from Vue I18n
const getMomentLocale = () => {
    const { locale } = useI18n() // Get the current locale from Vue I18n
    return locale.value === 'en-gb' // Map locales to Moment.js
}

const filters = {
    capitalize: function (input) {
        if (input !== null && input !== undefined) {
            return input.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            })
        }
    },
    // replace diacritical characters with ASCII equivalents
    ascii(value) {
        return String(value)
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
    },
    uppercase: function (input) {
        if (input !== null && input !== undefined) {
            return input.toUpperCase()
        }
    },
    booleanToString: function (input) {
        if (input === true) {
            return 'Yes'
        } else {
            return 'No'
        }
    },
    website: function (input) {
        return input.replace(/(^\w+:|^)\/\//, '')
    },
    hasHttp: function (input) {
        if (input.indexOf('http') === -1) {
            input = 'http://' + input
        }
        return input
    },
    parseDate(date, format = 'YYYY-MM-DD', displayInLocalTime = true) {
        return date ? (displayInLocalTime ? moment.utc(date).local().format(format) : moment(date).format(format)) : ''
    },
    parseDateOnly(date, format = 'YYYY-MM-DD', displayInLocalTime = true) {
        return date ? (displayInLocalTime ? moment.utc(date).local().format(format) : moment(date).format(format)) : ''
    },
    parseTime(date, format = 'HH:mm', displayInLocalTime = true) {
        return date ? (displayInLocalTime ? moment.utc(date).local().format(format) : moment(date).format(format)) : ''
    },
    parseTime24(date, format = 'HH:mm', displayInLocalTime = true) {
        return date ? (displayInLocalTime ? moment.utc(date).local().format(format) : moment(date).format(format)) : ''
    },
    parseDateTime(date, format = 'YYYY-MM-DD, HH:mm', displayInLocalTime = true) {
        return date ? (displayInLocalTime ? moment.utc(date).local().format(format) : moment(date).format(format)) : ''
    },
    formatDate: function (value, displayInLocalTime = true) {
        if (value) {
            return displayInLocalTime
                ? moment.utc(String(value)).local().format(' MM/DD/YYYY, hh:mma')
                : moment(String(value)).format(' MM/DD/YYYY, hh:mma')
        }
    },
    timeAgo(dateString, displayInLocalTime = true) {
        moment.locale(getMomentLocale()) // Dynamically set Moment.js locale
        return displayInLocalTime ? moment.utc(dateString).local().fromNow() : moment(dateString).fromNow()
    },
    formatDecimal: function (value, decimal = 2) {
        return parseFloat(value).toFixed(decimal)
    },
    formatPercent: function (value, decimal = 2) {
        return parseFloat(value).toFixed(decimal) + '%'
    },
    formatNumberWithCommas: function (x, defaultValue = '0.00', ifZeroValue = '') {
        x = x !== null && x !== undefined ? x : false
        if (x === false) {
            return defaultValue
        }
        // if x holds zero value then ex. N/A will be printed
        // ifZeroValue - any string
        if (x === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
            return ifZeroValue
        }
        var parts = String(x).split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return parts.join('.')
    },
    formatFileSize: function (size, defaultValue = '0 B', ifZeroValue = '') {
        size = size !== null && size !== undefined ? size : false
        if (size === false) {
            return defaultValue
        }
        // Handle zero size with a custom message if provided
        if (size === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
            return ifZeroValue
        }

        const units = ['B', 'KB', 'MB', 'GB', 'TB']
        const i = Math.floor(Math.log(size) / Math.log(1024))
        const formattedSize = (size / Math.pow(1024, i)).toFixed(2) // 2 decimal places

        return `${formattedSize.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${units[i]}`
    },
    nl2br: function (text) {
        // Fallback if text is null/undefined or not a string
        if (!text || typeof text !== 'string') {
            return ''
        }
        // Replace all newlines with <br>
        return text.replace(/\n/g, '<br>')
    }
}

export default filters
