const messages = {
  makeChoice: "Vyberte si zde",
  seeAll: "Zobrazit vše",
  clear: "Vymazat",
  back: "Zpět",
  save: "Uložit",
  close: "Zavřít",
  of: "z",
  to: "do",
  copyrights: "© {date} Bridgestone Solutions. Všechna práva vyhrazena.",
  pageNotFound: "Stránka nebyla nalezena",
  accessDenied: "Přístup odepřen",
  search: "Hledat",
  buttons: {
    export: "Exportovat",
    delete: "Smazat",
    save: "Uložit",
    cancel: "Zrušit",
    next: "Další",
    back: "Zpět",
    skip: "Přeskočit",
    send: "Odeslat",
    pointsList: "Seznam bodů",
    clear: "Vymazat",
    readNow: "čtěte nyní",
    more: "Více",
    close: "Zavřít",
    reportError: "Nahlásit chybu",
    accept: "Přijmout",
    download: "Download",
    dealerInfoDownload: "DOWNLOAD",
    toTheBasket: "To basket",
    toOrderDelivery: "Continue to the delivery address",
    discover: "Discover",
    newAddress: "Create new delivery address",
    selectAddressBtn: "Select delivery address",
    toOrderSummary: "To the order overview",
    placeOrder: "Binding order",
    toDashboard: "To homepage",
    newOrder: "New order",
    userOrders: "To your orders",
    toSearch: "Search",
    select: "Select",
  },
  tooltips: {
    info: "Potřebujete pomoc? +420 771 239 989",
  },
  confirmation: {
    header: "Vyžadováno potvrzení",
    message: "Opravdu chcete tuto položku smazat?",
  },
  nav: {
    dashboard: "Domů",
    account: "Můj Profil",
    mailbox: "VAŠE ZPRÁVY",
    singleMailbox: "Zprávy",
    stammdaten: "Základní data",
    news_feeds: "Zprávy",
    pointsOverview: "Points overview",
    about: "O BS Plus",
    service_number: "Contact service",
    shop: "Obchod s odměnami",
    salesPromotions: "Sales promotions",
    shopCategory: "Rewards shop",
    productDetails: "Product details",
    userOrders: "User orders",
    shoppingCart: "Shopping cart",
    orderDelivery: "Shipping address",
    orderSummary: "Order overview",
    orderComplete: "Order complete",
  },
  menu: {
    account: "Můj Profil",
    plus: 'O Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Podpora prodeje",
    mailbox: "VAŠE ZPRÁVY",
    about: "O BS Plus",
    trainingAndCourses: "Školení a kurzy",
    externalTraining: "Externí školení",
    productInfo: "Informace o produktu",
    productBrochures: "Produktové brožury",
    priceLists: "Ceníky",
    price: "Cena",
    complaintForm: "Formulář stížnosti",
    sales_support: "Podpora prodeje",
    contact_service: "Kontaktní údaje a servis",
    logOut: "Odhlásit se",
    news: "Novinky",
    news_feeds: "Zprávy",
    service_number: "Contact service",
    salesPromotions: "Sales promotions",
    shop: "Shop",
  },
  validation: {
    requiredField: "Povinné pole",
  },
};

export default {
  messages,
};
