const messages = {
  titleColorRev: "true",
  buttons: {
    pending: "În așteptare",
    open: "Deschide",
    requestAccess: "Solicită acces",
  },
  myBsApps: '<span class="text-danger">Aplicațiile mele</span> Bridgestone',
  my: "Aplicațiile mele",
  directRoute: "Ruta dvs. directă către toate aplicațiile web Bridgestone.",
  welcomeMessage:
    'Bine ați venit la BRIDGESTONE PLUS <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  showProfile: "Afișați profilul",
  newMessages: "Mesaje noi în căsuța poștală",
  myApps: "Aplicațiile mele",
  allApps: "Toate aplicațiile",
  myAppsDescription:
    "Toate aplicațiile online Bridgestone la care te-ai înregistrat deja.",
  allAppsDescription:
    "Toate aplicațiile online Bridgestone care te-ar putea interesa.",
  myProfile: {
    welcomeMessage:
      "Bine ați venit în zona dvs. personală. Aici, vă puteți actualiza sau ajusta datele de bază și vă puteți vizualiza mesajele.",
    masterData: "Date principale",
    completeDetails:
      "Completați datele dvs. personale și ale companiei pentru o înregistrare reușită.",
    goToMasterData: "Mergeți la Datele principale",
    goToInbox: "Mergeți la inbox ",
  },
  masterData: {
    welcomeMessage:
      "Bine ai venit în zona ta personală. Aici poți actualiza sau modifica datele tale.",
    personalDetails: "Detalii personale",
    companyDetails: "Detalii despre companie",
    emailAddress: "Adresă de e-mail",
    title: "Titlu",
    surname: "Numele de familie",
    firstName: "Prenumele",
    jobPosition: "Funcția",
    streetAndHouseNumber: "Strada și numărul ",
    postalCode: "Codul poștal",
    city: "Oraș",
    country: "Țară",
    legalStructure: "Structură juridică",
    customerNumber: "Numărul clientului Bridgestone",
    importantPleaseRead: "Important vă rugăm să citiți",
    customerNumberInfo:
      "Pentru ca comenzile tale de anvelope să fie corect direcționate către distribuitori și/sau Bridgestone, este important să furnizezi numărul tău de client.",
    multipleCustomerNumbers:
      "Dacă ai mai multe numere de client la distribuitor și/sau Bridgestone, de exemplu pentru afacerea principală și filiale, furnizează mai întâi numărul de client al afacerii principale.",
    salesAssignment:
      "Vânzările legate de celelalte numere de client vor fi atribuite numărului tău de client principal.",
    supplier: "Furnizor",
    addAnotherCustomerNumber: "Adaugă un alt număr de client Bridgestone",
    save: "Salvează",
    delete: "Șterge",
  },
  inbox: {
    welcomeMessage: "Aici vei găsi notificări importante și noutăți.",
    sender: "Expeditor",
    subject: "Subiect",
    date: "Data",
    formConsulting: "Form Consulting - Mulțumim pentru procesarea formularului",
    back: "Înapoi",
  },
  legalAndContact: {
    cookieSettings: "Setări cookie",
    dataPolicy: "Politica de date",
    termsOfUse: "Termen de utilizare",
    helpAndContact: "Ajutor și contact",
  },
  logInSignUp: {
    logIn: "Conectează-te",
    signUp: "Înregistrează-te",
    logInMessage: "Conectați-vă cu adresa dvs. de e-mail și parola.",
    signUpMessage:
      "Dacă sunteți un utilizator nou, vă rugăm să vă înregistrați.",
  },
};

export default {
  messages,
};
