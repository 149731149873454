const messages = {
  makeChoice: "Faça aqui a sua escolha.",
  seeAll: "Ver tudo",
  clear: "Eliminar",
  back: "Voltar",
  save: "Guardar",
  close: "Fechar",
  of: "De",
  to: "à",
  copyrights: "© {date} Bridgestone Solutions. Todos os direitos reservados",
  pageNotFound: "Página não encontrada",
  accessDenied: "Acesso negado",
  search: "Pesquisar",
  buttons: {
    export: "Export",
    delete: "Eliminar",
    save: "Guardar",
    cancel: "Cancelar",
    next: "Continuar",
    back: "Voltar atrás",
    skip: "Saltar",
    send: "Enviar",
    clear: "Eliminar",
    readNow: "Leia agora",
    pointsList: "Lista de pontos",
    more: "Mais",
    close: "Fechar",
    reportError: "Reportar erro",
    accept: "ACEITAR",
    download: "Descarregar",
    dealerInfoDownload: "Download",
    toTheBasket: "Para a cesta",
    toOrderDelivery: "Continue para o endereço de entrega",
    discover: "Descobrir",
    newAddress: "Criar novo endereço de entrega",
    selectAddressBtn: "Select delivery address",
    toOrderSummary: "To the order overview",
    placeOrder: "Binding order",
    toDashboard: "To homepage",
    newOrder: "New order",
    userOrders: "To your orders",
    toSearch: "Search",
    select: "Select",
  },
  tooltips: {
    info: "Precisa de Ajuda?",
  },
  confirmation: {
    header: "Confirmação necessária",
    message: "Deseja eliminar este registo?",
  },
  nav: {
    dashboard: "Início",
    account: "O meu perfil",
    mailbox: "Caixa de entrada",
    singleMailbox: "Mensagem",
    stammdaten: "O meu perfil",
    news_feeds: "Notícias",
    pointsOverview: "Os meus pontos",
    about: "Sobre a Bridgestone",
    service_number: "Contactos e Serviços",
    shop: "Loja de recompensas",
    salesPromotions: "Sales promotions",
    shopCategory: "Rewards shop",
    productDetails: "Product details",
    userOrders: "Pedidos do usuário",
    shoppingCart: "Shopping cart",
    orderDelivery: "Shipping address",
    orderSummary: "Order overview",
    orderComplete: "Order complete",
  },
  menu: {
    account: "O meu perfil",
    plus: 'Sobre a Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Suporte de vendas",
    mailbox: "Caixa de entrada",
    about: "Sobre a Bridgestone",
    trainingAndCourses: "Formações e cursos",
    externalTraining: "Formações externas",
    productInfo: "Informações sobre os produtos",
    productBrochures: "Brochuras de produtos",
    priceLists: "Listas de preços",
    price: "Pricat",
    complaintForm: "Formulário de reclamação",
    sales_support: "Suporte de vendas",
    contact_service: "CONTACTOS E SERVIÇOS",
    logOut: "Desconectar",
    news: "Notícias",
    news_feeds: "Notícias",
    service_number: "Contactos e Serviços",
    stammdaten: "O meu perfil",
    salesPromotions: "Sales promotions",
    shop: "Shop",
  },
  validation: {
    requiredField: "Campo obrigatório",
  },
};

export default {
  messages,
};
