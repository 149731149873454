const messages = {
  makeChoice: "Maak hier uw keuze.",
  seeAll: "Weergeven alles",
  clear: "Verwijderen",
  back: "Terug",
  save: "Opslaan",
  close: "Sluiten",
  of: "Van",
  to: "tot",
  copyrights: "© {date} Bridgestone Solutions. Alle rechten voorbehouden.",
  pageNotFound: "Pagina niet gevonden",
  accessDenied: "Toegang geweigerd",
  search: "Zoeken",
  buttons: {
    export: "Exporteren",
    delete: "Verwijderen",
    save: "Opslaan",
    cancel: "Annuleren",
    next: "Doorgaan",
    back: "TERUG",
    skip: "Overslaan",
    send: "Versturen",
    clear: "Verwijderen",
    readNow: "Lees nu",
    pointsList: "Puntenlijst",
    more: "Meer",
    close: "Sluiten",
    reportError: "Fout melden",
    accept: "Accepteren",
    download: "Download",
    dealerInfoDownload: "Download",
    toTheBasket: "To basket",
    toOrderDelivery: "Continue to the delivery address",
    discover: "Discover",
    newAddress: "Create new delivery address",
    selectAddressBtn: "Select delivery address",
    toOrderSummary: "To the order overview",
    placeOrder: "Binding order",
    toDashboard: "To homepage",
    newOrder: "New order",
    userOrders: "To your orders",
    toSearch: "Search",
    select: "Select",
  },
  tooltips: {
    info: "Hulp nodig? Contacteer plusbelux&commat;bridgestone.eu ",
  },
  confirmation: {
    header: "Bevestiging vereist",
    message: "Wilt u dit record verwijderen?",
  },
  nav: {
    dashboard: "Startpagina",
    account: "Mijn profiel",
    mailbox: "Inbox",
    singleMailbox: "Bericht",
    stammdaten: "Basisgegevens",
    news_feeds: "Nieuws",
    pointsOverview: "Points overview",
    about: "Over BS Plus",
    service_number: "Contact service",
    shop: "Bridgestone <span class='text-danger'>PLUS</span> Shop",
    salesPromotions: "Sales promotions",
    shopCategory: "Rewards shop",
    productDetails: "Product details",
    userOrders: "User orders",
    shoppingCart: "Shopping cart",
    orderDelivery: "Shipping address",
    orderSummary: "Order overview",
    orderComplete: "Order complete",
  },
  menu: {
    account: "Mijn profiel",
    plus: 'Over Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Verkoopondersteuning",
    mailbox: "Inbox",
    about: "Over BS Plus",
    trainingAndCourses: "Trainingen en cursussen",
    externalTraining: "Externe trainingen",
    productInfo: "Productinformatie",
    productBrochures: "Productbrochures",
    priceLists: "Prijslijsten",
    price: "Pricat",
    complaintForm: "Klachtenformulier",
    sales_support: "Verkoopondersteuning",
    contact_service: "Contact & Service",
    logOut: "Uitloggen",
    news: "Nieuws",
    news_feeds: "Nieuws",
    service_number: "Contact service",
    salesPromotions: "Sales promotions",
    shop: "Shop",
  },
  validation: {
    requiredField: "Verplichte velden",
  },
};

export default {
  messages,
};
