const messages = {
  titleColorRev: "true",
  buttons: {
    pending: "En attente",
    open: "Ouvrir",
    requestAccess: "Demande d'accès",
  },
  myBsApps: '<span class="text-danger">Mes Applis</span> Bridgestone',
  my: "Mes Applis",
  directRoute: "Votre accès direct à toutes les applications web Bridgestone",
  myAppsDescription:
    "toutes les applications web Bridgestone pour lesquelles vous êtes déjà membre",
  allAppsDescription:
    "toutes les applications web Bridgestone susceptibles de vous intéresser",
  myApps: "Mes applications",
  allApps: "Toutes les applications",
  myProfile: {
    welcomeMessage:
      "Bienvenue dans votre espace personnel. Ici, vous pouvez mettre à jour ou modifier vos données de référence et consulter votre boîte de réception.",
    masterData: "Données de référence",
    completeDetails:
      "Complétez vos informations personnelles et celles de votre entreprise pour une inscription réussie.",
    goToMasterData: "Accéder à vos données de référence",
    goToInbox: "Aller à la boîte de réception",
  },
  masterData: {
    welcomeMessage:
      "Bienvenue dans votre espace personnel. Ici, vous pouvez mettre à jour ou modifier vos données de référence.",
    personalDetails: "Données personnelles",
    companyDetails: "Coordonnées de l'entreprise",
    emailAddress: "Adresse e-mail",
    title: "Titre",
    surname: "Nom de famille",
    firstName: "Prénom",
    jobPosition: "Fonction",
    streetAndHouseNumber: "Numéro et nom de rue",
    postalCode: "Code postal",
    city: "Ville",
    country: "Pays",
    legalStructure: "Structure juridique",
    customerNumber: "Numéro de client",
    importantPleaseRead: "Important, veuillez lire",
    customerNumberInfo:
      "Pour affecter correctement vos commandes de pneus aux grossistes et/ou à Bridgestone, il est essentiel que vous fournissiez vos numéros de client respectifs.",
    multipleCustomerNumbers:
      "Si vous avez plusieurs numéros de client avec un grossiste et/ou Bridgestone, par exemple, pour votre entreprise principale et vos succursales, veuillez d'abord fournir le numéro de client pour l'entreprise principale.",
    salesAssignment:
      "Les ventes associées aux numéros de client supplémentaires seront alors attribuées à votre entreprise principale.",
    supplier: "Fournisseur",
    addAnotherCustomerNumber: "Ajoutez un autre numéro de client Bridgestone",
    save: "Sauvegarder",
    delete: "Supprimer",
  },
  inbox: {
    welcomeMessage:
      "Vous y trouverez des notifications et des nouvelles importantes.",
    sender: "Expéditeur",
    subject: "Objet",
    date: "Date",
    formConsulting:
      "Vérification du formulaire - Veuillez traiter le formulaire",
    back: "Retour",
  },
  legalAndContact: {
    cookieSettings: "Paramètres des cookies",
    dataPolicy: "Politique des données",
    termsOfUse: "Conditions d'utilisation",
    helpAndContact: "Aide et contact",
  },
  logInSignUp: {
    logIn: "Se connecter",
    signUp: "S'inscrire",
    logInMessage: "Connectez-vous avec votre e-mail et votre mot de passe.",
    signUpMessage:
      "Si vous êtes un nouvel utilisateur, veuillez vous inscrire.",
  },
};

export default {
  messages,
};
