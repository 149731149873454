const messages = {
  title: "Salgsfremstød",
  desc: "Brug vores salgskampagner aktivt overfor dine kunder. Du må naturligvis  gerne tilbyde kunderne begge kampagner. Læs mere om Dækgaranti og Drive Our Best nedenfor.",
  participation: {
    title: "Ansøg om deltagelse nu",
    desc: "Vil du gerne implementere en eller flere af vores kampagner i din butik? Så kan du ansøge her for at ansøge om deltagelse. Din virksomhed vil blive aktiveret til kampagnen/kampagnerne, og du vil modtage reklamemateriale  inden for 4 uger reklamemateriale til din salgsdrøm.<br/><br/>Foretag dit valg:",
    request: "ANSØG OM DELTAGELSE",
    success: "Tak, din anmodning er sendt",
  },
};
export default {
  messages,
};
