<template>
    <VeeForm class="form user-form" @submit="onSubmit">
        <MDBRow v-if="isLite">
            <MDBCol cols="12" md="12">
                <MDBRow>
                    <MDBCol cols="12" md="6">
                        <h4 class="mb-3 text-uppercase">{{ $t("account.profile.activityAndProfile") }}</h4>
                    </MDBCol>
                    <MDBCol cols="12" md="3" offset="3">
                        <h4 class="mb-3 text-uppercase">bridgestone {{ $t('account.profile.clientNrSingular') }}:</h4>
                    </MDBCol>
                </MDBRow>
            </MDBCol>
            <MDBCol cols="12" md="3">
                <div class="d-flex">
                    <input class="form-check-input" type="checkbox" :value="true" :unchecked-value="false"
                        :checked="isCheckedVehicleType()" @change="selectValue('carTyres')"
                        v-model="selections['carTyres']" :disabled="loading" />
                    <label class="form-check-label ps-3">
                        {{ $t('onboarding.carTyres') }}
                    </label>
                </div>
                <div class="d-flex">
                    <input class="form-check-input" type="checkbox" :value="true" :unchecked-value="false"
                        @change="selectValue('motorcyclesTyres')" :checked="isCheckedVehicleType()"
                        v-model="selections['motorcyclesTyres']" :disabled="loading" />
                    <label class="form-check-label ps-3">
                        {{ $t('onboarding.motorcyclesTyres') }}
                    </label>
                </div>
                <div class="d-flex">
                    <input class="form-check-input" type="checkbox" :value="true" :unchecked-value="false"
                        @change="selectValue('truckBusTyres')" :checked="isCheckedVehicleType()"
                        v-model="selections['truckBusTyres']" :disabled="loading" />
                    <label class="form-check-label ps-3">
                        {{ $t('onboarding.truckBusTyres') }}
                    </label>
                </div>
                <div class="d-flex">
                    <input class="form-check-input" type="checkbox" :value="true" :unchecked-value="false"
                        :checked="isCheckedVehicleType()" @change="selectValue('agricultureTyres')"
                        v-model="selections['agricultureTyres']" :disabled="loading" />
                    <label class="form-check-label ps-3">
                        {{ $t('onboarding.agricultureTyres') }}
                    </label>
                </div>
                <div class="d-flex">
                    <input class="form-check-input" type="checkbox" :value="true" :unchecked-value="false"
                        :checked="isCheckedVehicleType()" v-model="selections['otrTyres']"
                        @change="selectValue('otrTyres')" :disabled="loading" />
                    <label class="form-check-label ps-3">
                        {{ $t('onboarding.otrTyres') }}
                    </label>
                </div>

            </MDBCol>
            <MDBCol cols="12" md="6">
                <div class="py-1"><b> {{ $t('onboarding.tradingPartner') }}...</b></div>
                <div class="d-flex">
                    <input type="radio" class="form-check-input" name="bussinessPartner" value="tradingPartnerDirect"
                        v-model="bussinessPartner" @click="storeBussinessRelation('tradingPartnerDirect')"
                        :checked="isChecked('tradingPartnerDirect')" :disabled="loading" />
                    <label for="tradingPartnerDirect" class="form-check-label">{{
                        $t('onboarding.tradingPartnerDirect')
                    }}</label>
                </div>
                <div class="d-flex">
                    <input type="radio" class="form-check-input" name="bussinessPartner" value="tradingPartnerIndirect"
                        v-model="bussinessPartner" :checked="isChecked('tradingPartnerIndirect')"
                        @click="storeBussinessRelation('tradingPartnerIndirect')" :disabled="loading" />
                    <label for="tradingPartnerIndirect" class="form-check-label">{{
                        $t('onboarding.tradingPartnerIndirect')
                    }} </label>
                </div>
                <div class="d-flex" v-if="cannotSeeBothRelation">
                    <input type="radio" class="form-check-input" name="bussinessPartner" value="tradingPartnerBoth"
                        v-model="bussinessPartner" @click="storeBussinessRelation('tradingPartnerBoth')"
                        :checked="isChecked('tradingPartnerBoth')" :disabled="loading" />
                    <label for="tradingPartnerBoth" class="form-check-label">{{
                        $t('onboarding.tradingPartnerBoth')
                    }} </label>
                </div>

                <div v-if="cannotSeeFleet">
                    <div class="py-3"><b> {{ $t('onboarding.fleetResponsibleDirect') }}</b></div>
                    <div class="d-flex">
                        <input type="radio" class="form-check-input" name="bussinessPartner"
                            value="fleetResponsibleDirect" v-model="bussinessPartner"
                            :checked="isChecked('fleetResponsibleDirect')" :disabled="loading"
                            @click="storeBussinessRelation('fleetResponsibleDirect')" />
                        <label for="fleetResponsibleDirect" class="form-check-label">{{
                            $t('onboarding.tradingPartnerDirect')
                            }}</label>
                    </div>
                    <div class="d-flex">
                        <input type="radio" class="form-check-input" name="bussinessPartner"
                            value="fleetResponsibleIndirect" :disabled="loading" v-model="bussinessPartner"
                            :checked="isChecked('fleetResponsibleIndirect')"
                            @click="storeBussinessRelation('fleetResponsibleIndirect')" />
                        <label for="fleetResponsibleIndirect" class="form-check-label">{{
                            $t('onboarding.fleetResponsibleIndirect') }}
                        </label>
                    </div>
                    <div v-if="cannotSeeBothRelation" class="d-flex">
                        <input type="radio" class="form-check-input" name="bussinessPartner"
                            value="fleetResponsibleBoth" :disabled="loading"
                            :checked="isChecked('fleetResponsibleBoth')"
                            @click="storeBussinessRelation('fleetResponsibleBoth')" />
                        <label for="fleetResponsibleBoth" class="form-check-label">{{
                            $t('onboarding.fleetResponsibleBoth')
                            }}</label>
                    </div>
                </div>

                <div class="py-3 d-flex" v-if="cannotSeeNoBussinessRelation">
                    <input type="radio" class="form-check-input" v-model="bussinessPartner" name="bussinessPartner"
                        @click="storeBussinessRelation('noBusinessRelationship')" value="noBusinessRelationship"
                        :disabled="loading" />
                    <label for="sachschaden1" class="form-check-label"
                        v-html="$t('onboarding.noBusinessRelationship')"></label>
                </div>
            </MDBCol>
            <MDBCol cols="12" md="3">
                <div><b>Bridgestone</b></div>

                <div v-for="(data, index) in me.wholesalers_dealers" :key="index" class="pt-2">
                    <div v-if="!data.verifiedCustomerNumber">
                        <div :class="data?.parent_id !== null ? 'ps-2' : ''">
                            <input v-model="data.customer_number" type="text" class="form-control" :placeholder="`${$t(
                                'account.profile.clientNrSingular'
                            )}`" :aria-label="`${$t(
                                'account.profile.clientNrSingular'
                            )}`" aria-describedby="basic-addon1" :disabled="data.verifiedCustomerNumber || loading" />
                        </div>
                    </div>
                </div>

                <div class="pt-3">
                    <input v-model="customer_number" type="text" class="form-control" :placeholder="`${$t(
                        'account.profile.clientNrSingular'
                    )}`" :aria-label="`${$t(
                        'account.profile.clientNrSingular'
                    )}`" aria-describedby="basic-addon1" :disabled="loading" />
                </div>
                <div v-for="(csNumber, index) in customerNumbers" :key="index" class="pt-2">
                    <input v-model="csNumber.customer_number" type="text" class="form-control" :placeholder="`${$t(
                        'account.profile.clientNrSingular'
                    )}`" :aria-label="`${$t(
                        'account.profile.clientNrSingular'
                    )}`" aria-describedby="basic-addon1" :disabled="loading" />
                </div>
                <div class="text-uppercase label cursor--pointer" @click="addNewCsNumber()"
                    :disabled="bussinessPartner === 'fleetResponsibleIndirect' || bussinessPartner === 'fleetResponsibleBoth'">
                    <img :src="require('@/assets/img/plus_btn_small.svg')" alt="logo" />
                    <span class="ps-2">{{ $t('account.profile.addAnotherCsNumber') }}</span>
                </div>
                <div class="text-danger" v-if="errorMessage">{{ $t("global.validation.requiredField") }}. {{
                    $t('onboarding.lengthCsNumber') }}.</div>
            </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol cols="12">
                <MDBBtn type="submit" color="danger" :disabled="loading || disableBtn"
                    class="d-flex justify-content-center align-items-center">
                    <span>{{ $t("global.buttons.send") }}</span>
                    <Loader :show="loading" :small="true" spinner="spinner.svg" :tiny="true" />
                </MDBBtn>
            </MDBCol>
        </MDBRow>

    </VeeForm>
</template>

<script setup>

import { Form as VeeForm } from "vee-validate";
import { MDBRow, MDBCol, MDBBtn } from "mdb-vue-ui-kit";
import Loader from "@/components/General/Loader/index.vue";
import { useStore } from "vuex";
import { ref, computed, watch } from "vue";
import { GroupService } from "@/services/group.service";
import { UserService } from "@/services/user.service";
import { toast } from "vue3-toastify";
import { useI18n } from "vue-i18n";
import { useTenantConfig } from "@/composables/useTenantConfig";
import { useAllowDuplicatedCustomerNumber } from "@/composables/useProfileFieldCheck";
const { t } = useI18n() // Import the translation function from i18n
const { tenantConfig } = useTenantConfig();
const { allowDuplicatedCustomerNumber } = useAllowDuplicatedCustomerNumber(tenantConfig);

import "vue3-toastify/dist/index.css";

const storedLocale = localStorage.getItem("currentLocale");
const cannotSeeBothRelation = computed(() => {
    const notAvailableCodes = store.getters.bothRelationOptionDisabled
    const currentCountry = storedLocale.split('-')

    return !notAvailableCodes.includes(currentCountry[1])
})
const cannotSeeFleet = computed(() => {
    const notAvailableCodes = store.getters.fleetRelationOptionDisabled
    const currentCountry = storedLocale.split('-')

    return !notAvailableCodes.includes(currentCountry[1])
})
const cannotSeeNoBussinessRelation = computed(() => {
    const notAvailableCodes = store.getters.noBussinesRelationOptionDisabled
    const currentCountry = storedLocale.split('-')

    return !notAvailableCodes.includes(currentCountry[1])
})
const store = useStore();
const me = computed(() => store.getters.getMe);
const isLite = computed(() => store.getters.isLiteVersion).value;
const parentAttributes = computed(() => store.getters.getParentAttributes)
const initialParentAttributes = computed(() => store.getters.getInitialParentAttributes);
const loading = computed(() => store.getters.getPending);

const customerNumbers = ref([]);
const bussinessPartner = ref(null)
const errorMessage = ref(false)
const disableBtn = ref(false)

const selections = ref({
    carTyres: false,
    motorcyclesTyres: false,
    truckBusTyres: false,
    agricultureTyres: false,
    otrTyres: false
})

const addNewCsNumber = () => {
    customerNumbers.value.push({
        customer_number: "",
        wholesaler_id: null,
        expected_name: 'Bridgestone'
    })
}
const customer_number = ref(null)
const options = ref([])

const selectValue = (key) => {
    disableBtn.value = false

    store.dispatch('addInitialParentAttributes', []);
    selections.value[key] = !selections.value[key]

    const valueExists = options.value.includes(key);

    if (valueExists) {
        options.value.splice(options.value.indexOf(key), 1);
    } else {
        options.value.push(key);
    }

    store.dispatch('addInitialParentAttributes', {
        user_id: me.value.id,
        options: options.value,
        selection: [bussinessPartner.value]
    });
}

const isCheckedVehicleType = () => {
    if (Object.keys(parentAttributes.value).length > 0) {
        for (const element in parentAttributes.value.map) {
            selections.value[element] = parentAttributes.value.map[element].enabled
        }
    }
}

const isChecked = (value) => {
    return parentAttributes.value.nonCommSel === value
}

watch(parentAttributes, (newVal) => {
    options.value = []
    // special condition for select bussinessPartner value when nonCommSel == false repleace with 'noBusinessRelationship'
    bussinessPartner.value = newVal.nonCommSel ? newVal.nonCommSel : 'noBusinessRelationship'

    for (const element in newVal.map) {
        if (newVal.map[element].enabled) {
            options.value.push(element)
        }
    }

    store.dispatch('addInitialParentAttributes', {
        user_id: me.value.id,
        options: options.value,
        selection: [bussinessPartner.value]
    });
})

const storeBussinessRelation = (entry) => {
    bussinessPartner.value = entry

    store.dispatch('addInitialParentAttributes', {
        user_id: me.value.id,
        options: options.value,
        selection: [bussinessPartner.value]
    });

}

const canAddCsNumber = computed(() => {
    return bussinessPartner.value === 'tradingPartnerDirect' ||
        bussinessPartner.value === 'tradingPartnerBoth' ||
        bussinessPartner.value === 'fleetResponsibleDirect' ||
        bussinessPartner.value === 'fleetResponsibleBoth'
})

const onSubmit = async () => {
    errorMessage.value = false

    const isDigit = /^\d+$/.test(customer_number.value)

    const lengthCheck = customer_number.value && (customer_number.value.length < 6 || customer_number.value.length > 10)

    if ((customer_number.value || customerNumbers.value.length > 1) && !canAddCsNumber.value) {
        toast(t("toast.cannotAddCsNumber"), {
            type: "error",
            theme: "colored",
            transition: toast.TRANSITIONS.SLIDE,
            position: toast.POSITION.BOTTOM_RIGHT,
        });

        return false
    }

    if (((me.value.wholesalers_dealers.length == 0 && !customer_number.value && canAddCsNumber.value) || (!isDigit && customer_number.value) || lengthCheck)) {
        errorMessage.value = true
    } else {
        if (isLite && customer_number.value) {
            const csNrData = {
                expected_name: 'Bridgestone',
                customer_number: customer_number.value,
                dealer_id: me.value.plainId,
                id: null,
                can_be_duplicated: allowDuplicatedCustomerNumber()
            };

            const responseCsNr = await UserService.saveCustomerNumber(csNrData);
            customer_number.value = null
            if (responseCsNr?.data.message === "exists") {
                toast(t("toast.customerNumberExist"), {
                    type: "error",
                    theme: "colored",
                    transition: toast.TRANSITIONS.SLIDE,
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false
            } else if (responseCsNr?.data.message === "changeParentId") {
                toast(t("toast.changeParentId"), {
                    type: "error",
                    theme: "colored",
                    transition: toast.TRANSITIONS.SLIDE,
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                return false
            }

        }

        if (isLite && customerNumbers.value.length > 0) {
            for (const element of customerNumbers.value) {
                if (element.customer_number.length > 0) {

                    const lengthCheck = element.customer_number && element.customer_number.length <= 6 && element.customer_number.length >= 10

                    if (lengthCheck) {
                        toast(t("toast.cannotAddCsNumber"), {
                            type: "error",
                            theme: "colored",
                            transition: toast.TRANSITIONS.SLIDE,
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });

                        return false
                    }

                    const csNrData = {
                        expected_name: 'Bridgestone',
                        customer_number: element.customer_number,
                        dealer_id: me.value.plainId,
                        id: null,
                        can_be_duplicated: allowDuplicatedCustomerNumber()
                    };

                    const respCsNr = await UserService.saveCustomerNumber(csNrData);

                    if (respCsNr?.data.message === "exists") {
                        toast(t("toast.customerNumberExist") + ' ' + element.customer_number, {
                            type: "error",
                            theme: "colored",
                            transition: toast.TRANSITIONS.SLIDE,
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        continue
                    } else if (respCsNr?.data.message === "changeParentId") {
                        toast(t("toast.changeParentId"), {
                            type: "error",
                            theme: "colored",
                            transition: toast.TRANSITIONS.SLIDE,
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        continue
                    }

                    customerNumbers.value = []
                }
            }
        }

        if (isLite) {
            let input = {
                user_id: me.value.plainId,
                options: initialParentAttributes.value.options,
                selection: initialParentAttributes.value.selection
            }

            const response = await GroupService.parentAttributeAsignSelector(input)

            if (response && response.status === 200) {
                toast(t("toast.profileSaved"), {
                    type: "success",
                    theme: "colored",
                    transition: toast.TRANSITIONS.SLIDE,
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                toast(response.response.data.errors['selection.0'], {
                    type: "error",
                    theme: "colored",
                    transition: toast.TRANSITIONS.SLIDE,
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }

        await store.dispatch("me");
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
@import "@/styles/profile.scss";
</style>