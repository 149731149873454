const messages = {
  title:
    'Välkommen till tjänsten BRIDGESTONE <span class="text-danger">PLUS</span>',
  termsAndConditions: "Villkor och anvisningar",
  accept: "Acceptara",
  decline: "Nedgång",
  dataModal:
    "För att vi ska kunna anpassa erbjudandet till dina intressen ber vi dig att kortfattat ange dina produktintressen eller affärsområden.",
  scrollToAccept:
    "Vänligen scrolla ner för att acceptera våra villkor för att fortsätta",
  welcomeMessage: "Välkommen till BRIDGESTONE PLUS",
  tailorOffer:
    "För att vi ska kunna anpassa erbjudandet till dina intressen ber vi dig att kortfattat ange dina produktintressen eller affärsområden.",
  bussinessType:
    "För att vi ska kunna anpassa erbjudandet till dina intressen ber vi dig att kortfattat ange dina produktintressen eller affärsområden.",
  selectAreas: "Vänligen välj* från följande områden",
  multipleAnswers: "(flera svar möjliga):",
  personalPreferences: "Personliga preferenser",
  carTyres: "Bildäck, SUV, utlitity",
  motorcyclesTyres: "Motorcykeldäck",
  truckBusTyres: "Däck för lastbilar och bussar",
  agricultureTyres: "Däck för jordbruksfordon",
  otrTyres: "Entreprenaddäck",
  save: "Spara",
  businessRelationship: "Vad har ni för affärsförbindelser med Bridgestone?",
  selectRelationship: "Vänligen välj*:",
  tradingPartner: "Jag är en handelspartner som är ...",
  tradingPartnerDirect: "... direktbeställning från Bridgestone",
  tradingPartnerIndirect: "... indirekt beställning (via grossist, t.ex.)",
  tradingPartnerBoth: "... både direkt och indirekt orderläggning ",
  fleetResponsibleDirect: "Jag är en åkeriansvarig som är ... ",
  fleetResponsibleIndirect:
    "... indirekt beställning (via handel eller grossist) ",
  fleetResponsibleBoth: "... direkt och indirekt ordergivning ",
  noBusinessRelationship:
    "<b>Jag har ingen affärsrelation med Bridgestone </b>(varken direkt eller indirekt)",
  yourCustomerNumber: "Ditt Bridgestone kundnummer*:",
  enterCustomerNumber: "Ange ditt kundnummer",
  waitPersonalisedView:
    "Vänligen vänta ett ögonblick, din personliga bild av BRIDGESTONE PLUS kommer att genereras.",
  personalised: "PERSONALISERADE",
  plusInfo:
    "Här hittar du information om ditt Plus-konto och din Plus-brevlåda.",
  continue: "Fortsätt",
  skip: "Hoppa över",
  oneOfTwo: "1 av 2",
  openApps:
    "Du kan direkt öppna de appar som du redan är registrerad för. Du kan också begära åtkomst till andra appar som kan vara relevanta för dig.",
  requiredFields: "Fyll i de obligatoriska fälten: Titel, Förnamn, Efternamn",
  stage: "steg",
  chooseOne: "(Kryssa i minst 1 ruta)",
  required: "*obligatoriska fält",
  lengthCsNumber: "Längden på kundnumret: 6–10 siffror",
  waitForAdminApproval:
    "Tack för att du registrerade dig för Bridgestone Plus Rewards-programmet. Vi granskar för närvarande din ansökan. Du kommer att få ett bekräftelsemail när din ansökan har godkänts.",
};

export default {
  messages,
};
