import i18n from "@/plugins/i18n";
import { rgb } from "pdf-lib";

const parseDate = (date) => {
  if (!date) return;
  return new Date(date).toLocaleDateString("ro-RO");
};

const data = new Date();
const utils = {
  timeAgo: (date) => {
    let messageDate = new Date(date);
    let now = Date.now();

    let seconds = parseInt((now - messageDate) / 1000);
    let minutes = parseInt((now - messageDate) / 60000);
    let hours = parseInt((now - messageDate) / 3600000);
    let days = parseInt((now - messageDate) / (24 * 3600 * 1000));

    if (days > 0) {
      return i18n.global.t("mailbox.time.daysAgo", { time: days });
    }

    if (hours > 0) {
      return i18n.global.t("mailbox.time.hoursAgo", { time: hours });
    }

    if (minutes > 0) {
      return i18n.global.t("mailbox.time.minutesAgo", { time: minutes });
    }

    if (seconds > 0) {
      return i18n.global.t("mailbox.time.secondsAgo", { time: seconds });
    }
  },

  createField: (form, fieldName, text, firstPage, x, y, width, height) => {
    const field = form.createTextField(fieldName);

    field.setText(text);

    field.addToPage(firstPage, {
      x: x,
      y: y,
      width: width,
      height: height,
      backgroundColor: rgb(1, 1, 1),
    });

    const fieldGet = form.getTextField(fieldName);
    fieldGet.setFontSize(10);
  },

  formatDatePicker: (date) => {
    return `${parseDate(date)}`;
  },

  setYesNoText: (data) => {
    return data ? i18n.global.t("yesText") : i18n.global.t("noText");
  },

  pdfFirstPageContent: {
    complaint_id: {
      fieldName: "claimFrom.complaint_id",
      text: null,
      x: 170,
      y: 345,
      width: 390,
      height: 20,
    },
    ordinance: {
      fieldName: "claimFromContent.ordinance",
      text: null,
      x: 170,
      y: 325,
      width: 390,
      height: 20,
    },
    generalEmail: {
      fieldName: "claimFromContent.generalEmail",
      text: null,
      x: 170,
      y: 305,
      width: 390,
      height: 20,
    },
    country: {
      fieldName: "claimFromContent.country",
      text: null,
      x: 170,
      y: 285,
      width: 390,
      height: 20,
    },
    pickup_location_id: {
      fieldName: "claimFromContent.pickup_location_id",
      text: null,
      x: 170,
      y: 265,
      width: 390,
      height: 20,
    },
    tyre_wear_id: {
      fieldName: "claimFromContent.tyre_wear_id",
      text: null,
      x: 170,
      y: -295,
      width: 390,
      height: 20,
    },
    firstRegistration: {
      fieldName: "claimFromContent.firstRegistration",
      text: null,
      x: 170,
      y: -235,
      width: 130,
      height: 20,
    },
    enginePower: {
      fieldName: "claimFromContent.enginePower",
      text: null,
      x: 440,
      y: -235,
      width: 120,
      height: 20,
    },
    originalEquipment: {
      fieldName: "claimFromContent.originalEquipment",
      text: null,
      x: 170,
      y: -255,
      width: 390,
      height: 20,
    },
    vehicleManufacturer: {
      fieldName: "claimFromContent.vehicleManufacturer",
      text: null,
      x: 170,
      y: -215,
      width: 130,
      height: 20,
    },
    vehicleModelType: {
      fieldName: "claimFromContent.vehicleModelType",
      text: null,
      x: 440,
      y: -215,
      width: 120,
      height: 20,
    },
    vehicle_tyre_id: {
      fieldName: "claimFromContent.vehicle_tyre_id",
      text: null,
      x: 170,
      y: -195,
      width: 390,
      height: 20,
    },

    addressTyreDealer: {
      salutation_id: {
        fieldName: "claimFromContent.addressTyreDealer.salutation_id",
        text: null,
        x: 170,
        y: 225,
        width: 130,
        height: 20,
      },
      name: {
        fieldName: "claimFromContent.addressTyreDealer.name",
        text: null,
        x: 170,
        y: 205,
        width: 260,
        height: 20,
      },
      street: {
        fieldName: "claimFromContent.addressTyreDealer.street",
        text: null,
        x: 170,
        y: 185,
        width: 260,
        height: 20,
      },
      postal_code: {
        fieldName: "claimFromContent.addressTyreDealer.postal_code",
        text: null,
        x: 170,
        y: 165,
        width: 260,
        height: 20,
      },
      phone: {
        fieldName: "claimFromContent.addressTyreDealer.phone",
        text: null,
        x: 170,
        y: 145,
        width: 260,
        height: 20,
      },
      contact_person: {
        fieldName: "claimFromContent.addressTyreDealer.contact_person",
        text: null,
        x: 170,
        y: 125,
        width: 260,
        height: 20,
      },
      customer_number: {
        fieldName: "claimFromContent.addressTyreDealer.customer_number",
        text: null,
        x: 430,
        y: 225,
        width: 130,
        height: 20,
      },
    },

    addressOfIntermediary: {
      salutation_id: {
        fieldName: "claimFromContent.addressOfIntermediary.salutation_id",
        text: null,
        x: 170,
        y: 85,
        width: 390,
        height: 20,
      },
      name: {
        fieldName: "claimFromContent.addressOfIntermediary.name",
        text: null,
        x: 170,
        y: 65,
        width: 390,
        height: 20,
      },
      street: {
        fieldName: "claimFromContent.addressOfIntermediary.street",
        text: null,
        x: 170,
        y: 45,
        width: 390,
        height: 20,
      },
      postal_code: {
        fieldName: "claimFromContent.addressOfIntermediary.postal_code",
        text: null,
        x: 170,
        y: 25,
        width: 390,
        height: 20,
      },
      phone: {
        fieldName: "claimFromContent.addressOfIntermediary.phone",
        text: null,
        x: 170,
        y: 5,
        width: 390,
        height: 20,
      },
      contact_person: {
        fieldName: "claimFromContent.addressOfIntermediary.contact_person",
        text: null,
        x: 170,
        y: -15,
        width: 390,
        height: 20,
      },
    },

    addressEndCustomerData: {
      salutation_id: {
        fieldName: "claimFromContent.addressEndCustomerData.salutation_id",
        text: null,
        x: 170,
        y: -55,
        width: 390,
        height: 20,
      },
      name: {
        fieldName: "claimFromContent.addressEndCustomerData.name",
        text: null,
        x: 170,
        y: -75,
        width: 390,
        height: 20,
      },
      street: {
        fieldName: "claimFromContent.addressEndCustomerData.street",
        text: null,
        x: 170,
        y: -95,
        width: 390,
        height: 20,
      },
      postal_code: {
        fieldName: "claimFromContent.addressEndCustomerData.postal_code",
        text: null,
        x: 170,
        y: -115,
        width: 390,
        height: 20,
      },
      phone: {
        fieldName: "claimFromContent.addressEndCustomerData.phone",
        text: null,
        x: 170,
        y: -135,
        width: 390,
        height: 20,
      },
      contact_person: {
        fieldName: "claimFromContent.addressEndCustomerData.contact_person",
        text: null,
        x: 170,
        y: -155,
        width: 390,
        height: 20,
      },
    },
  },

  pdfSecondPageContent: {
    brandSpareTyre: {
      fieldName: "claimFromContent.brandSpareTyre",
      text: null,
      x: 180,
      y: 45,
      width: 150,
      height: 20,
    },
    damage: {
      fieldName: "claimFromContent.damage",
      text: null,
      x: 140,
      y: 65,
      width: 40,
      height: 20,
    },
    personalInjury: {
      fieldName: "claimFromContent.personalInjury",
      text: null,
      x: 265,
      y: 65,
      width: 65,
      height: 20,
    },
    isTyreReplaced: {
      fieldName: "claimFromContent.isTyreReplaced",
      text: null,
      x: 415,
      y: 65,
      width: 65,
      height: 20,
    },
    regulation2: {
      fieldName: "claimFromContent.regulation2",
      text: null,
      x: 510,
      y: 65,
      width: 45,
      height: 20,
    },
    clerkName: {
      fieldName: "claimFromContent.clerkName",
      text: null,
      x: 480,
      y: 45,
      width: 75,
      height: 20,
    },
    complaintReason: {
      fieldName: "claimFromContent.complaintReason",
      text: null,
      x: 180,
      y: 25,
      width: 375,
      height: 20,
    },
    complaintReasonText: {
      fieldName: "claimFromContent.complaintReasonText",
      text: null,
      x: 180,
      y: -15,
      width: 375,
      height: 40,
    },

    tyre1: {
      tyre_position_id: {
        fieldName: "claimFromContent.tyreIndex1.tirePosition",
        text: null,
        x: 165,
        y: 320,
        width: 390,
        height: 20,
      },
      size: {
        fieldName: "claimFromContent.tyreIndex1.tireSizeExample",
        text: null,
        x: 165,
        y: 300,
        width: 135,
        height: 20,
      },
      profile: {
        fieldName: "claimFromContent.tyreIndex1.profile",
        text: null,
        x: 500,
        y: 300,
        width: 55,
        height: 20,
      },
      index: {
        fieldName: "claimFromContent.tyreIndex1.speedLoadIndex",
        text: null,
        x: 370,
        y: 300,
        width: 80,
        height: 20,
      },
      dot: {
        fieldName: "claimFromContent.tyreIndex1.dot",
        text: null,
        x: 165,
        y: 280,
        width: 135,
        height: 20,
      },
      serial_number: {
        fieldName: "claimFromContent.tyreIndex1.TruckSerialNo",
        text: null,
        x: 500,
        y: 280,
        width: 55,
        height: 20,
      },
    },

    tyre2: {
      tyre_position_id: {
        fieldName: "claimFromContent.tyreIndex2.tirePosition",
        text: null,
        x: 165,
        y: 260,
        width: 390,
        height: 20,
      },
      size: {
        fieldName: "claimFromContent.tyreIndex2.tireSizeExample",
        text: null,
        x: 165,
        y: 240,
        width: 135,
        height: 20,
      },
      profile: {
        fieldName: "claimFromContent.tyreIndex2.profile",
        text: null,
        x: 500,
        y: 240,
        width: 55,
        height: 20,
      },
      index: {
        fieldName: "claimFromContent.tyreIndex2.speedLoadIndex",
        text: null,
        x: 370,
        y: 240,
        width: 80,
        height: 20,
      },
      dot: {
        fieldName: "claimFromContent.tyreIndex2.dot",
        text: null,
        x: 165,
        y: 220,
        width: 135,
        height: 20,
      },
      serial_number: {
        fieldName: "claimFromContent.tyreIndex2.TruckSerialNo",
        text: null,
        x: 500,
        y: 220,
        width: 55,
        height: 20,
      },
    },

    tyre3: {
      tyre_position_id: {
        fieldName: "claimFromContent.tyreIndex3.tirePosition",
        text: null,
        x: 165,
        y: 200,
        width: 390,
        height: 20,
      },
      size: {
        fieldName: "claimFromContent.tyreIndex3.tireSizeExample",
        text: null,
        x: 165,
        y: 180,
        width: 135,
        height: 20,
      },
      profile: {
        fieldName: "claimFromContent.tyreIndex3.profile",
        text: null,
        x: 500,
        y: 180,
        width: 55,
        height: 20,
      },
      index: {
        fieldName: "claimFromContent.tyreIndex3.speedLoadIndex",
        text: null,
        x: 370,
        y: 180,
        width: 80,
        height: 20,
      },
      dot: {
        fieldName: "claimFromContent.tyreIndex3.dot",
        text: null,
        x: 165,
        y: 160,
        width: 135,
        height: 20,
      },
      serial_number: {
        fieldName: "claimFromContent.tyreIndex3.TruckSerialNo",
        text: null,
        x: 500,
        y: 160,
        width: 55,
        height: 20,
      },
    },

    tyre4: {
      tyre_position_id: {
        fieldName: "claimFromContent.tyreIndex4.tirePosition",
        text: null,
        x: 165,
        y: 140,
        width: 390,
        height: 20,
      },
      size: {
        fieldName: "claimFromContent.tyreIndex4.tireSizeExample",
        text: null,
        x: 165,
        y: 120,
        width: 135,
        height: 20,
      },
      profile: {
        fieldName: "claimFromContent.tyreIndex4.profile",
        text: null,
        x: 500,
        y: 120,
        width: 55,
        height: 20,
      },
      index: {
        fieldName: "claimFromContent.tyreIndex4.speedLoadIndex",
        text: null,
        x: 370,
        y: 120,
        width: 80,
        height: 20,
      },
      dot: {
        fieldName: "claimFromContent.tyreIndex4.dot",
        text: null,
        x: 165,
        y: 100,
        width: 135,
        height: 20,
      },
      serial_number: {
        fieldName: "claimFromContent.tyreIndex4.TruckSerialNo",
        text: null,
        x: 500,
        y: 100,
        width: 55,
        height: 20,
      },
    },
  },

  pdfFieldsPage1: [
    {
      fieldName: "claimFrom.number",
      text: i18n.global.t("claimFrom.pdf.number"),
      x: 30,
      y: 345,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.ordinance",
      text: i18n.global.t("complaintForm.fields.ordinance"),
      x: 30,
      y: 325,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.yourEmail",
      text: i18n.global.t("yourEmail"),
      x: 30,
      y: 305,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.country",
      text: i18n.global.t("account.profile.country"),
      x: 30,
      y: 285,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.pickupLocation",
      text: i18n.global.t("complaintForm.fields.pickupLocation"),
      x: 30,
      y: 265,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.formOfAddress",
      text: i18n.global.t("account.profile.formOfAddress"),
      x: 30,
      y: 225,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.clientNrSingular",
      text: i18n.global.t("account.profile.clientNrSingular"),
      x: 300,
      y: 225,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.nameFirma",
      text: i18n.global.t("contact.form.nameFirma"),
      x: 30,
      y: 205,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.strHouseNr",
      text: i18n.global.t("contact.form.strHouseNr"),
      x: 30,
      y: 185,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.postcodeCity",
      text: `${i18n.global.t("account.profile.postcode")} / ${i18n.global.t(
        "account.profile.location"
      )}`,
      x: 30,
      y: 165,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.phone",
      text: i18n.global.t("account.profile.phone"),
      x: 30,
      y: 145,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.contactPerson",
      text: i18n.global.t("contact.form.contactPerson"),
      x: 30,
      y: 125,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.intermediaryAddress.formOfAddress",
      text: i18n.global.t("account.profile.formOfAddress"),
      x: 30,
      y: 85,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.intermediaryAddress.nameFirma",
      text: i18n.global.t("contact.form.nameFirma"),
      x: 30,
      y: 65,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.intermediaryAddress.strHouseNr",
      text: i18n.global.t("contact.form.strHouseNr"),
      x: 30,
      y: 45,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.intermediaryAddress.postcodeCity",
      text: `${i18n.global.t("account.profile.postcode")} / ${i18n.global.t(
        "account.profile.location"
      )}`,
      x: 30,
      y: 25,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.intermediaryAddress.phone",
      text: i18n.global.t("account.profile.phone"),
      x: 30,
      y: 5,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.intermediaryAddress.contactPerson",
      text: i18n.global.t("contact.form.contactPerson"),
      x: 30,
      y: -15,
      width: 140,
      height: 20,
    },

    {
      fieldName: "claimFrom.vehicleDetails.formOfAddress",
      text: i18n.global.t("account.profile.formOfAddress"),
      x: 30,
      y: -55,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.vehicleDetails.nameFirma",
      text: i18n.global.t("contact.form.nameFirma"),
      x: 30,
      y: -75,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.vehicleDetails.strHouseNr",
      text: i18n.global.t("contact.form.strHouseNr"),
      x: 30,
      y: -95,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.vehicleDetails.postcodeCity",
      text: `${i18n.global.t("account.profile.postcode")} / ${i18n.global.t(
        "account.profile.location"
      )}`,
      x: 30,
      y: -115,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.vehicleDetails.phone",
      text: i18n.global.t("account.profile.phone"),
      x: 30,
      y: -135,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.vehicleDetails.contactPerson",
      text: i18n.global.t("contact.form.contactPerson"),
      x: 30,
      y: -155,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.vehicleTyres.contactPerson",
      text: i18n.global.t("claimFrom.pdf.vehicleTyres"),
      x: 30,
      y: -195,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.vehicleTyres.vehicleManufacturer",
      text: i18n.global.t("contact.form.vehicleManufacturer"),
      x: 30,
      y: -215,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.vehicleTyres.vehicleModelType",
      text: i18n.global.t("claimFrom.pdf.vehicleModelType"),
      x: 300,
      y: -215,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.vehicleTyres.enginePower",
      text: i18n.global.t("claimFrom.pdf.enginePower"),
      x: 300,
      y: -235,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.vehicleTyres.firstRegistration",
      text: i18n.global.t("contact.form.firstRegistration"),
      x: 30,
      y: -235,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.vehicleTyres.originalEquipment",
      text: i18n.global.t("contact.form.originalEquipment"),
      x: 30,
      y: -255,
      width: 140,
      height: 20,
    },
    {
      fieldName: "claimFrom.vehicleTyres.tireMilageHours",
      text: i18n.global.t("contact.form.tireMilageHours"),
      x: 30,
      y: -275,
      width: 530,
      height: 20,
    },
    {
      fieldName: "claimFrom.vehicleTyres.tireMilage",
      text: i18n.global.t("contact.form.tireMilage"),
      x: 30,
      y: -295,
      width: 140,
      height: 20,
    },
  ],

  pdfFieldTitlesPage1: [
    {
      text: `${data.getDate()}.${data.getMonth() + 1}.${data.getFullYear()}`,
      x: 510,
      y: 370,
      size: 14,
    },
    {
      text: i18n.global.t("complaintForm.sections.regulator.title"),
      x: 30,
      y: 250,
      size: 10,
    },
    {
      text: i18n.global.t("contact.form.intermediaryAddress"),
      x: 30,
      y: 110,
      size: 10,
    },
    {
      text: i18n.global.t("addressEndCustomer"),
      x: 30,
      y: -30,
      size: 10,
    },
    {
      text: i18n.global.t("contact.form.vehicleDetails"),
      x: 30,
      y: -170,
      size: 10,
    },
  ],

  pdfFieldTitlesPage2: [
    {
      text: i18n.global.t("contact.form.tires"),
      x: 30,
      y: 345,
      size: 10,
    },
    {
      text: i18n.global.t("claimFrom.pdf.damage"),
      x: 30,
      y: 90,
      size: 10,
    },
  ],

  pdfFieldsPage2: [
    //tyre 1
    {
      fieldName: "claimFrom.tyreIndex1",
      text: "1",
      x: 30,
      y: 320,
      width: 15,
      height: 20,
    },
    {
      fieldName: "tyreIndex1.tirePosition",
      text: i18n.global.t("contact.form.tirePosition"),
      x: 45,
      y: 320,
      width: 120,
      height: 20,
    },
    {
      fieldName: "tyreIndex1.tireSizeExample",
      text: i18n.global.t("claimFrom.pdf.tireSizeExample"),
      x: 45,
      y: 300,
      width: 120,
      height: 20,
    },
    {
      fieldName: "tyreIndex1.dot",
      text: i18n.global.t("claimFrom.pdf.dot"),
      x: 45,
      y: 280,
      width: 120,
      height: 20,
    },
    {
      fieldName: "tyreIndex1.profile",
      text: i18n.global.t("claimFrom.pdf.profile"),
      x: 450,
      y: 300,
      width: 50,
      height: 20,
    },
    {
      fieldName: "tyreIndex1.speedLoadIndex",
      text: i18n.global.t("claimFrom.pdf.speedLoadIndex"),
      x: 300,
      y: 300,
      width: 70,
      height: 20,
    },
    {
      fieldName: "tyreIndex1.TruckSerialNo",
      text: i18n.global.t("contact.form.TruckSerialNo"),
      x: 300,
      y: 280,
      width: 200,
      height: 20,
    },
    //tyre 2
    {
      fieldName: "claimFrom.tyreIndex2",
      text: "2",
      x: 30,
      y: 260,
      width: 15,
      height: 20,
    },
    {
      fieldName: "tyreIndex2.tirePosition",
      text: i18n.global.t("contact.form.tirePosition"),
      x: 45,
      y: 260,
      width: 120,
      height: 20,
    },
    {
      fieldName: "tyreIndex2.tireSizeExample",
      text: i18n.global.t("claimFrom.pdf.tireSizeExample"),
      x: 45,
      y: 240,
      width: 120,
      height: 20,
    },
    {
      fieldName: "tyreIndex2.dot",
      text: i18n.global.t("claimFrom.pdf.dot"),
      x: 45,
      y: 220,
      width: 120,
      height: 20,
    },
    {
      fieldName: "tyreIndex2.profile",
      text: i18n.global.t("claimFrom.pdf.profile"),
      x: 450,
      y: 240,
      width: 50,
      height: 20,
    },
    {
      fieldName: "tyreIndex2.speedLoadIndex",
      text: i18n.global.t("claimFrom.pdf.speedLoadIndex"),
      x: 300,
      y: 240,
      width: 70,
      height: 20,
    },
    {
      fieldName: "tyreIndex2.TruckSerialNo",
      text: i18n.global.t("contact.form.TruckSerialNo"),
      x: 300,
      y: 220,
      width: 200,
      height: 20,
    },
    //tyre 3
    {
      fieldName: "claimFrom.tyreIndex3",
      text: "3",
      x: 30,
      y: 200,
      width: 15,
      height: 20,
    },
    {
      fieldName: "tyreIndex3.tirePosition",
      text: i18n.global.t("contact.form.tirePosition"),
      x: 45,
      y: 200,
      width: 120,
      height: 20,
    },
    {
      fieldName: "tyreIndex3.tireSizeExample",
      text: i18n.global.t("claimFrom.pdf.tireSizeExample"),
      x: 45,
      y: 180,
      width: 120,
      height: 20,
    },
    {
      fieldName: "tyreIndex3.dot",
      text: i18n.global.t("claimFrom.pdf.dot"),
      x: 45,
      y: 160,
      width: 120,
      height: 20,
    },
    {
      fieldName: "tyreIndex3.profile",
      text: i18n.global.t("claimFrom.pdf.profile"),
      x: 450,
      y: 180,
      width: 50,
      height: 20,
    },
    {
      fieldName: "tyreIndex3.speedLoadIndex",
      text: i18n.global.t("claimFrom.pdf.speedLoadIndex"),
      x: 300,
      y: 180,
      width: 70,
      height: 20,
    },
    {
      fieldName: "tyreIndex3.TruckSerialNo",
      text: i18n.global.t("contact.form.TruckSerialNo"),
      x: 300,
      y: 160,
      width: 200,
      height: 20,
    },
    //tyre 4
    {
      fieldName: "claimFrom.tyreIndex4",
      text: "4",
      x: 30,
      y: 140,
      width: 15,
      height: 20,
    },
    {
      fieldName: "tyreIndex4.tirePosition",
      text: i18n.global.t("contact.form.tirePosition"),
      x: 45,
      y: 140,
      width: 120,
      height: 20,
    },
    {
      fieldName: "tyreIndex4.tireSizeExample",
      text: i18n.global.t("claimFrom.pdf.tireSizeExample"),
      x: 45,
      y: 120,
      width: 120,
      height: 20,
    },
    {
      fieldName: "tyreIndex4.dot",
      text: i18n.global.t("claimFrom.pdf.dot"),
      x: 45,
      y: 100,
      width: 120,
      height: 20,
    },
    {
      fieldName: "tyreIndex4.profile",
      text: i18n.global.t("claimFrom.pdf.profile"),
      x: 450,
      y: 120,
      width: 50,
      height: 20,
    },
    {
      fieldName: "tyreIndex4.speedLoadIndex",
      text: i18n.global.t("claimFrom.pdf.speedLoadIndex"),
      x: 300,
      y: 120,
      width: 70,
      height: 20,
    },
    {
      fieldName: "tyreIndex4.TruckSerialNo",
      text: i18n.global.t("contact.form.TruckSerialNo"),
      x: 300,
      y: 100,
      width: 200,
      height: 20,
    },
    //damage
    {
      fieldName: "claimFrom.damage",
      text: i18n.global.t("contactPage.damage"),
      x: 30,
      y: 65,
      width: 110,
      height: 20,
    },
    {
      fieldName: "claimFromDamage.personalInjury",
      text: i18n.global.t("claimFrom.pdf.personalInjury"),
      x: 180,
      y: 65,
      width: 85,
      height: 20,
    },
    {
      fieldName: "claimFromDamage.tiresReplaced",
      text: i18n.global.t("claimFrom.pdf.tiresReplaced"),
      x: 330,
      y: 65,
      width: 85,
      height: 20,
    },
    {
      fieldName: "claimFromDamage.agb",
      text: i18n.global.t("claimFrom.pdf.agb"),
      x: 480,
      y: 65,
      width: 30,
      height: 20,
    },
    {
      fieldName: "claimFromDamage.replacementStamp",
      text: i18n.global.t("claimFrom.pdf.replacementStamp"),
      x: 30,
      y: 45,
      width: 150,
      height: 20,
    },
    {
      fieldName: "claimFromDamage.clerkName",
      text: i18n.global.t("claimFrom.pdf.clerkName"),
      x: 330,
      y: 45,
      width: 150,
      height: 20,
    },
    {
      fieldName: "claimFromDamage.complaintReason",
      text: i18n.global.t("claimFrom.pdf.complaintReason"),
      x: 30,
      y: 25,
      width: 150,
      height: 20,
    },
    {
      fieldName: "claimFromDamage.complaintReasonText",
      text: i18n.global.t("claimFrom.pdf.complaintReasonText"),
      x: 30,
      y: 5,
      width: 150,
      height: 20,
    },
  ],
};

export default utils;
