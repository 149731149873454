const messages = {
  title: "Můj osobní výpis z účtu",
  desc: "Zde si můžete prohlédnout svůj osobní výpis z účtu Bridgestone Plus s vašimi získanými a použitými bonusovými body. Upozorňujeme, že připsání bodů za vaše objednávky pneumatik se může v závislosti na zdroji lišit a může trvat až 6 týdnů od data objednávky.",
  score: "Stav bodů",
  regularPoints: "Běžné body",
  boosterPoints: "Bonusové body",
  date: "Datum",
  supplySourceReason: "Zdroj/Důvod nákupu",
  article: "Článek",
  brand: "Značka",
  product: "Produkt",
  type: "Typ",
  inch: "Palce",
  season: "Sezóna",
  amount: "Množství",
  standardPoints: "Body",
  boosterProduct: "Bonusový produkt",
  boosterSeason: "Bonusová sezóna",
  otherActions: "Ostatní akce nebo důvody",
  totalPoints: "Celkový počet bodů",
  income: "Příjmy",
  spending: "Výdaje",
  lastMonth: "Závěrečná bilance",
  loadMore: "Načíst dalších 20 transakcí",
  typeFilter: "Filtrovat podle zdroje",
  dateFilter: "Filtrovat podle období",
  customer_number: "Zákaznické číslo",
  currentPoints: "Aktuální body",
  calculationDate: "Datum výpočtu",
  positivePoints: "Pozitivní body",
  negativePoints: "Již utratěné",
  tyresAmount: "Množství pneumatik ročně",
  pointsTotal: "Celkový počet bodů",
  currentPointState: "Aktuální stav bodů",
  currentKickbackState: "Aktuální cashback", // "Kickback" lze přeložit jako "cashback"
  points: "Points",
};
export default {
  messages,
};
