const messages = {
  title: "Welkom Bridgestone <span class='text-danger'>PLUS</span>",
  termsAndConditions: "Algemene voorwaarden",
  accept: "Accepteren",
  decline: "Afwijzen",
  dataModal:
    "Om dit aanbod beter op uw behoeften af te stemmen, vragen wij u kort uw interesses op het gebied van producten of sectoren aan te geven.",
  scrollToAccept:
    "Scroll naar beneden om onze voorwaarden te accepteren en door te gaan",
  welcomeMessage: "Welkom bij Bridgestone Plus",
  tailorOffer:
    "Om dit aanbod beter op uw behoeften af te stemmen, vragen wij u kort uw interesses op het gebied van producten of sectoren aan te geven.",
  bussinessType:
    "Om dit aanbod beter op uw behoeften af te stemmen, vragen wij u kort uw interesses op het gebied van producten of sectoren aan te geven.",
  selectAreas: "Kies uit de volgende opties*",
  multipleAnswers: "(meerdere antwoorden mogelijk)",
  personalPreferences: "Persoonlijke Voorkeuren",
  carTyres: "Personenwagen-, SUV-, bedrijfswagenbanden",
  motorcyclesTyres: "Motorbanden",
  truckBusTyres: "Vrachtwagen- en busbanden",
  agricultureTyres: "Landbouwbanden",
  otrTyres: "Grondverzetbanden",
  save: "Opslaan",
  businessRelationship: "Wat is uw zakelijke relatie met Bridgestone?",
  selectRelationship: "Kies uit de volgende opties*:",
  tradingPartner: "Ik ben een handelspartner die...",
  tradingPartnerDirect: "... rechtstreeks bestelt bij Bridgestone",
  tradingPartnerIndirect:
    "... indirect bestelt (bijvoorbeeld via een groothandel)",
  tradingPartnerBoth: "... zowel rechtstreeks als indirect bestelt",
  fleetResponsibleDirect: "Ik ben een wagenparkbeheerder die...",
  fleetResponsibleIndirect:
    "... indirect bestelt (via een distributeur of groothandel)",
  fleetResponsibleBoth: "... zowel rechtstreeks als indirect bestelt",
  noBusinessRelationship:
    "<b>Ik heb geen zakelijke relatie met Bridgestone</b> (noch rechtstreeks, noch indirect)",
  yourCustomerNumber: "Uw Bridgestone-klantnummer*:",
  enterCustomerNumber: "Voer uw klantnummer in",
  waitPersonalisedView:
    "Een ogenblik geduld, uw persoonlijke weergave van BRIDGESTONE PLUS wordt gegenereerd.",
  personalised: "Gepersonaliseerd",
  plusInfo: "Hier vindt u informatie over uw account en uw Plus-inbox.",
  continue: "Doorgaan",
  skip: "Overslaan",
  oneOfTwo: "1 van 2",
  openApps:
    "U kunt direct de applicaties openen waarvoor u al bent geregistreerd. U kunt ook toegang aanvragen tot andere applicaties die mogelijk interessant voor u zijn.",
  requiredFields: "Vul de verplichte velden in: Titel, Voornaam, Achternaam",
  stage: "stap",
  chooseOne: "(Kies een van de volgende opties)",
  required: "*verplichte velden",
  lengthCsNumber: "Lengte van het klantnummer: 6-10 cijfers",
  waitForAdminApproval:
    "Bedankt voor uw aanmelding voor het Bridgestone Plus Rewards-programma. Wij beoordelen momenteel uw aanvraag. Zodra uw aanvraag is goedgekeurd, ontvangt u een bevestigingsmail.",
};
export default {
  messages,
};
