const messages = {
  titleColorRev: "true",
  buttons: {
    pending: "In attesa",
    open: "Apri",
    requestAccess: "Richiedi accesso",
  },
  myBsApps: '<span class="text-danger">Le mie applicazioni</span> Bridgestone',
  my: "Le mie applicazioni",
  directRoute: "Percorso diretto a tutte le applicazioni online Bridgestone.",
  welcomeMessage:
    'Benvenuto in BRIDGESTONE PLUS <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  showProfile: "Mostra profilo",
  newMessages: "Nuovi messaggi nella tua posta in arrivo",
  myApps: "Le mie applicazioni",
  allApps: "Tutte le applicazioni",
  myAppsDescription: "tutte le app di Bridgestone a cui sei già registrato.",
  allAppsDescription:
    "tutte le app di Bridgestone che potrebbero interessarti.",
  myProfile: {
    welcomeMessage:
      "Benvenuto nella tua area personale. Qui puoi aggiornare o modificare i tuoi dati e visualizzare la tua casella di posta.",
    masterData: "Anagrafica",
    completeDetails:
      "Compila i tuoi dettagli personali e aziendali per la registrazione.",
    goToMasterData: "Accedi all'anagrafica",
    goToInbox: "Accedi alla posta in arrivo",
  },
  masterData: {
    welcomeMessage:
      "Benvenuto nella tua area personale. Qui puoi aggiornare o modificare i tuoi dati.",
    personalDetails: "Dati personali",
    companyDetails: "Dati aziendali",
    emailAddress: "Indirizzo email",
    title: "Titolo",
    surname: "Cognome",
    firstName: "Nome",
    jobPosition: "Posizione lavorativa",
    streetAndHouseNumber: "Indirizzo",
    postalCode: "CAP",
    city: "Città",
    country: "Paese",
    legalStructure: "Struttura legale",
    customerNumber: "Numero cliente Bridgestone",
    importantPleaseRead: "Importante, leggi attentamente",
    customerNumberInfo:
      "Per fare in modo che i tuoi ordini di pneumatici siano correttamente indirizzati ai distributori e/o a Bridgestone, è importante fornire il tuo numero cliente.",
    multipleCustomerNumbers:
      "Se possiedi più numeri cliente presso il distributore e/o Bridgestone, ad esempio per la tua attività principale e le filiali, fornisci prima il numero cliente dell'attività principale.",
    salesAssignment:
      "Le vendite relative agli altri numeri cliente saranno quindi assegnate al numero cliente principale.",
    supplier: "Fornitore",
    addAnotherCustomerNumber: "Aggiungi un altro numero cliente Bridgestone",
    save: "Salva",
    delete: "Elimina",
  },
  inbox: {
    welcomeMessage: "Qui troverai notifiche importanti e novità.",
    sender: "Mittente",
    subject: "Oggetto",
    date: "Data",
    formConsulting: "Form Consulting - Grazie per l'elaborazione del modulo",
    back: "Indietro",
  },
  legalAndContact: {
    cookieSettings: "Impostazioni cookie",
    dataPolicy: "Politica sulla privacy",
    termsOfUse: "Termini di utilizzo",
    helpAndContact: "Assistenza e contatti",
  },
  logInSignUp: {
    logIn: "Accedi",
    signUp: "Registrati",
    logInMessage: "Accedi con la tua email e password.",
    signUpMessage: "Se sei un nuovo utente, registrati.",
  },
};

export default {
  messages,
};
