const messages = {
  title: "Mit personlige kontooversigt",
  desc: "Her kan du se din personlige Bridgestone Plus kontooversigt med dine optjente og brugte bonuspoint. Bemærk venligst, at det kan tage op til 6 uger, før point for dine dækordrer krediteres, afhængigt af kilden.",
  score: "Pointstatus",
  regularPoints: "Normale point",
  boosterPoints: "Bonuspoint",
  date: "Dato",
  supplySourceReason: "Kilde/Købsgrund",
  article: "Artikel",
  brand: "Mærke",
  product: "Produkt",
  type: "Type",
  inch: "Tommer",
  season: "Sæson",
  amount: "Mængde",
  standardPoints: "Point",
  boosterProduct: "Bonusprodukt",
  boosterSeason: "Bonussesong",
  otherActions: "Andre handlinger eller årsager",
  totalPoints: "Samlede point",
  income: "Indbetalinger",
  spending: "Udbetalinger",
  lastMonth: "Afsluttende saldo",
  loadMore: "Indlæs yderligere 20 transaktioner",
  typeFilter: "Filtrer efter kilde",
  dateFilter: "Filtrer efter periode",
  customer_number: "Kundenummer",
  currentPoints: "Aktuelle point",
  calculationDate: "Beregningsdato",
  positivePoints: "Positive point",
  negativePoints: "Allerede brugt",
  tyresAmount: "Antal dæk årligt",
  pointsTotal: "Samlede point",
  currentPointState: "Aktuel pointstatus",
  currentKickbackState: "Aktuel cashback", // "Kickback" er normalt oversat til "cashback" på dansk
  points: "Points",
};
export default {
  messages,
};
