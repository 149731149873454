const messages = {
  title: "Caixa de entrada ",
  desc: "Encontre aqui notificações e notícias importantes.",
  newMessages: "Tem novas mensagens na sua caixa de entrada",
  link: "MOSTRAR MENSAGENS",
  showMessages: "MOSTRAR MENSAGENS",
  message: {
    sender: "Remetente",
    thread: "Assunto",
    date: "Data",
  },
  sender: {
    system: "BRIDGESTONE PLUS",
  },
  back: "Voltar atrás",
  time: {
    secondsAgo: "Enviado há {time} segundos",
    minutesAgo: "Enviado hace {time} minutos",
    hoursAgo: "Enviado há {time} horas",
    daysAgo: "Enviado há {time} dias",
  },
};

export default {
  messages,
};
