const messages = {
  title: "Försäljningskampanjer",
  desc: "Använd aktivt våra försäljningskampanjer med dina kunder. Du får naturligtvis gärna erbjuda kunderna båda kampanjerna. Läs mer om Däckgarantin och Drive Our Best nedan.",
  participation: {
    title: "Ansök om deltagande nu",
    desc: "Vill du implementera en eller flera av våra kampanjer i din butik? Då kan du ansöka om deltagande här. Ditt företag kommer att aktiveras för kampanjen/kampanjerna, och du kommer att få reklammaterial inom 4 veckor, reklammaterial för din försäljningsdröm.<br/><br/>Gör ditt val:",
    request: "ANSÖK OM DELTAGANDE",
    success: "Ďakujeme, vaša žiadosť bola odoslaná",
  },
};
export default {
  messages,
};
