const messages = {
  title: "Mans personīgais konta izraksts",
  desc: "Šeit varat apskatīt savu personīgo Bridgestone Plus konta izrakstu ar nopelnītajiem un izmantotajiem bonuspunktiem. Lūdzu, ņemiet vērā, ka punktu pievienošana par riepu pasūtījumiem var aizņemt līdz 6 nedēļām, atkarībā no avota.",
  score: "Punktu atlikums",
  regularPoints: "Parasti punkti",
  boosterPoints: "Bonuspunkti",
  date: "Datums",
  supplySourceReason: "Avots/Pirkšanas iemesls",
  article: "Prece",
  brand: "Zīmols",
  product: "Produkts",
  type: "Tips",
  inch: "Colls",
  season: "Sezona",
  amount: "Daudzums",
  standardPoints: "Punkti",
  boosterProduct: "Bonusprodukts",
  boosterSeason: "Bonussezona",
  otherActions: "Citas darbības vai iemesli",
  totalPoints: "Kopā punkti",
  income: "Ienākumi",
  spending: "Iztērēts",
  lastMonth: "Noslēguma atlikums",
  loadMore: "Ielādēt vēl 20 darījumus",
  typeFilter: "Filtrēt pēc avota",
  dateFilter: "Filtrēt pēc perioda",
  customer_number: "Klienta numurs",
  currentPoints: "Pašreizējie punkti",
  calculationDate: "Aprēķina datums",
  positivePoints: "Pozitīvie punkti",
  negativePoints: "Jau iztērēti",
  tyresAmount: "Riepu skaits gadā",
  pointsTotal: "Kopā punkti",
  currentPointState: "Pašreizējais punktu stāvoklis",
  currentKickbackState: "Pašreizējā atmaksa", // "Kickback" var tulkot kā "atmaksa"
  points: "Points",
};
export default {
  messages,
};
