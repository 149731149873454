const messages = {
  title: "Kezdőlap",
  welcomeMessage:
    'Üdvözöljük a BRIDGESTONE <span class="text-danger">PLUS</span>-ban, %{first_name} %{last_name}',
  home: "Kezdőlap",
  search: "Keresés",
  sortBy: "Rendezés",
  seeMore: "Több megtekintése",
  bankstatement: {
    yourScore: "Jelenlegi marketingpontjainak száma:",
    lastUpdate: "Legutóbbi frissítés:",
    link: "Pontegyensúly áttekintése",
  },
  shop: {
    bestSold: "Legjobban fogyó",
    seasonal: "Szezonális",
    affordable: "Megfizethető",
    forPoints: "{points} pontért",
    reachedPoints: "Elérted a szükséges pontszámot",
    cartRemainPoints: "Hátralévő pontok",
    remainingPoints: "Hiányzik még {points} pont",
    noProducts: "Nem található találat",
  },
  showAllProducts: "Menj boltba",
};
export default {
  messages,
};
