const messages = {
  title: "Minu isiklik kontooväide",
  desc: "Siin saad vaadata oma Bridgestone Plus isiklikku kontooväidet koos teenitud ja kasutatud boonuspunktidega. Palun pead meeles, et rehvi tellimuste eest punktide lisamine võib sõltuvalt allikast võtta kuni 6 nädalat.",
  score: "Punktide saldo",
  regularPoints: "Tavaline punktid",
  boosterPoints: "Boonuspunktid",
  date: "Kuupäev",
  supplySourceReason: "Allikas/Ostu põhjus",
  article: "Artikkel",
  brand: "Bränd",
  product: "Toode",
  type: "Tüüp",
  inch: "Toll",
  season: "Hooaeg",
  amount: "Kogus",
  standardPoints: "Punktid",
  boosterProduct: "Boonusprodukt",
  boosterSeason: "Boonushooaeg",
  otherActions: "Muud toimingud või põhjused",
  totalPoints: "Kokku punkte",
  income: "Tulu",
  spending: "Kulu",
  lastMonth: "Lõppsaldo",
  loadMore: "Lae veel 20 tehingut",
  typeFilter: "Filtreeri allika järgi",
  dateFilter: "Filtreeri perioodi järgi",
  customer_number: "Kliendi number",
  currentPoints: "Praegused punktid",
  calculationDate: "Arvutuskuupäev",
  positivePoints: "Positiivsed punktid",
  negativePoints: "Juba kulutatud",
  tyresAmount: "Rehvide arv aastas",
  pointsTotal: "Kokku punkte",
  currentPointState: "Praegune punktide olek",
  currentKickbackState: "Praegune tagasimakse", // "Kickback" saab tõlkida ka "raha tagasi"
  points: "Points",
};
export default {
  messages,
};
