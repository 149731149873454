const messages = {
  title: "Mój profil",
  desc: "Witamy w Twojej strefie osobistej. Tutaj możesz zaktualizować lub dostosować <br/>swoje dane główne i sprawdzić swoją skrzynkę odbiorczą.",
  descStaff:
    "Witamy w Twojej strefie osobistej. Tutaj możesz zaktualizować lub dostosować swoje dane główne.",
  buttons: {
    showProfile: "Pokaż profil",
    userProfile: "Przejdź do danych głównych",
    mailbox: "Przejdź do skrzynki odbiorczej",
  },
  categories: {
    baseData: {
      title: "Dane główne",
      desc: "Uzupełnij swoje dane osobowe i firmowe, aby zakończyć rejestrację.",
    },
    mailbox: {
      desc: "Tutaj znajdziesz ważne wiadomości i aktualności.",
    },
    bankstatement: {
      title: "Moje punkty",
      desc: "Tutaj możesz sprawdzić stan swojego konta Bridgestone Plus i zobaczyć zdobyte oraz wykorzystane punkty nagród.",
    },
  },
  profile: {
    general: "Dane osobiste i firmowe",
    title: "Moje dane główne",
    description:
      "Witamy w Twojej strefie osobistej. Tutaj możesz zaktualizować lub dostosować <br/>swoje dane główne i sprawdzić swoją skrzynkę odbiorczą.",
    block_description:
      "Witamy w Twojej strefie osobistej. Tutaj możesz zobaczyć swoje dane",
    personalData: "Dane osobowe",
    password: "Hasło",
    password1: "Hasło (opcjonalne)*",
    password2: "Hasło (powtórzenie)*",
    formOfAddress: "Tytuł",
    choose: "Wybierz tytuł",
    mr: "Pan",
    mrs: "Pani",
    diverse: "Inny",
    firstname: "Imię",
    surname: "Nazwisko",
    position: "Stanowisko",
    operatingData: "Dane firmy",
    company: "Nazwa firmy",
    legalForm: "Forma prawna",
    name: "Nazwa",
    streetHouseNr: "Ulica i numer domu",
    street: "Ulica",
    postcode: "Kod pocztowy",
    location: "Miejscowość",
    country: "Kraj",
    de: "Niemcy",
    at: "Austria",
    ch: "Szwajcaria",
    nl: "Holandia",
    it: "Włochy",
    language: "Język",
    french: "Francuski",
    italian: "Włoski",
    german: "Niemiecki",
    nederland: "Holenderski",
    openingHours: "Godziny pracy i kontakt",
    phone: "Telefon",
    website: "Strona internetowa",
    operatingIndicators: "Kluczowe wskaźniki operacyjne",
    tireSale: "Sprzedaż opon rocznie (sztuki)",
    tireSaleLabel: "Sprzedaż opon w dużych ilościach",
    inches: "Z tego ≥ 18 cali (w %)",
    inchesLabel: "Z tego 18 cali i więcej (w %)",
    segmentSale:
      "Sprzedaż opon według segmentu (udział Budget / Mid / Premium w %)",
    premBudget: "Premium",
    midBudget: "Średni",
    budBudget: "Budżetowy",
    distribution: "PODZIAŁ LATO / ZIMA / CAŁY ROK (w %)",
    tyreSizes: "Liczba magazynów na sezon (komplety opon)",
    diffDeliveryAddres: "Dodaj adres dostawy",
    yourEmailAddress: "Twój adres e-mail",
    clientNr: "Numery klienta",
    clientNrSingular: "Numer klienta",
    clientNrContent:
      '<span class="text-danger fw-bold">WAŻNE - PRZECZYTAJ:</span><br /><br /> Aby przypisać zamówienia opon u hurtownika i/lub w Bridgestone, musisz <b>wprowadzić swoje odpowiednie numery klienta.</b><br/><br/>Jeśli <b>masz wiele numerów klienta u hurtownika i/lub w Bridgestone,</b> np. dla głównego sklepu i oddziałów, najpierw wprowadź numer klienta głównego sklepu.<br/><br/><b>Sprzedaż z innych numerów klienta zostanie przypisana do głównego sklepu.</b>',
    supplySource: "Źródło zaopatrzenia",
    email: "Adres e-mail",
    staffEmail: "Adres e-mail uczestnika",
    registered: "Zarejestrowano w dniu",
    registeredAt: "Zarejestrowano w dniu",
    lastLogIn: "Ostatnie logowanie",
    details: "Szczegóły",
    inDetail: "{name} w szczegółach",
    bsClientNr: "Numer klienta Bridgestone",
    bftClientNr: "Twój numer klienta Best4Tires",
    acceptTerms: "Akceptuję warunki uczestnictwa i regulamin.",
    newCustomerNumbersInfo:
      '<span class="text-danger fw-bold">WAŻNE - PRZECZYTAJ:</span><br />Podczas pierwszego wprowadzenia numeru klienta Twoje dane zostaną zweryfikowane pod kątem zgodności z naszymi kryteriami dystrybucji. Decyzja o aktywacji numeru klienta i powiązaniu go z Twoim profilem może zająć do 5 dni roboczych.',
    selectWholeSaler: "u dostawcy (proszę wybrać)",
    cooperation: "CZŁONKOSTWO / WSPÓŁPRACA",
    error100: "Suma wartości musi wynosić 100%.",
    errorRange: "Wartość musi mieścić się w przedziale od 0 do 100",
    btw_number_label: "Numer identyfikacji podatkowej VAT Twojej firmy",
    btw_number: "Numer VAT",
    mobile: "Numer telefonu",
    mobile_label: "Twój numer telefonu komórkowego",
    iban_number: "Numer IBAN Twojej firmy",
    iban_number_label: "Numer IBAN",
    yourCsNr: "Twoje numery klienta",
    yourLastName: "Twoje nazwisko",
    positionLabel: "Wybierz stanowisko",
    yourFirstName: "Twoje imię",
    companyLabel: "Nazwa firmy",
    numberOfSetsOfSeasonalTiresBudget: "Magazynowanie roczne",
    contactInfo: "W razie pytań prosimy o kontakt:",
    addAnotherCsNumber: "Dodaj kolejny numer klienta Bridgestone",
    activityAndProfile: "DZIAŁALNOŚĆ I PROFIL",
  },
  countries: {
    al: "Albania",
    ad: "Andorra",
    at: "Austria",
    be: "Belgium",
    by: "Belarus",
    ba: "Bosnia and Herzegovina",
    bg: "Bulgaria",
    hr: "Croatia",
    cy: "Cyprus",
    cz: "Czechia",
    dk: "Denmark",
    ee: "Estonia",
    fi: "Finland",
    fr: "France",
    ge: "Georgia",
    gr: "Greece",
    es: "Spain",
    nl: "Netherlands",
    ie: "Ireland",
    is: "Iceland",
    kz: "Kazakhstan",
    xk: "Kosovo",
    li: "Liechtenstein",
    lt: "Lithuania",
    lu: "Luxembourg",
    lv: "Latvia",
    mk: "North Macedonia",
    mt: "Malta",
    md: "Moldova",
    mc: "Monaco",
    de: "Germany",
    no: "Norway",
    pl: "Poland",
    pt: "Portugal",
    ru: "Russia",
    ro: "Romania",
    sm: "San Marino",
    rs: "Serbia",
    sk: "Slovakia",
    si: "Slovenia",
    se: "Sweden",
    ch: "Switzerland",
    tr: "Turkey",
    ua: "Ukraine",
    hu: "Hungary",
    gb: "United Kingdom",
    it: "Italy",
    va: "Vatican City",
  },
};

export default {
  messages,
};
