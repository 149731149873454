const messages = {
  title: "Pardavimo akcijos",
  desc: "Aktyviai naudokite mūsų pardavimo akcijas su savo klientais. Žinoma, galite pasiūlyti klientams abi akcijas. Skaitykite daugiau apie Padangų garantiją ir Drive Our Best žemiau.",
  participation: {
    title: "Pateikite paraišką dalyvauti dabar",
    desc: "Ar norėtumėte įdiegti vieną ar kelias mūsų akcijas savo parduotuvėje? Tada galite pateikti paraišką dalyvauti čia. Jūsų įmonė bus aktyvuota akcijai (-oms) ir per 4 savaites gausite reklaminę medžiagą savo pardavimo svajonei.<br/><br/>Padarykite savo pasirinkimą:",
    request: "PATEIKTI PARAIŠKĄ DALYVAUTI",
    success: "Dėkojame, jūsų užklausa išsiųsta",
  },
};
export default {
  messages,
};
