const messages = {
  title: "Avaleht",
  welcomeMessage:
    'Tere tulemast BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Avaleht",
  search: "Otsi",
  sortBy: "Sorteeri",
  seeMore: "Vaata rohkem",
  bankstatement: {
    yourScore: "Teie praegune turunduspunktide arv:",
    lastUpdate: "Viimane värskendus:",
    link: "Punktide saldo ülevaade",
  },
  shop: {
    bestSold: "Kõige paremini müüvad",
    seasonal: "Hooajalised",
    affordable: "Taskukohased",
    forPoints: "eest {points} punkti eest",
    reachedPoints: "Oled saavutanud vajaliku punktisumma",
    cartRemainPoints: "Jääkpunktid",
    remainingPoints: "Puudub veel {points} punkti",
    noProducts: "Tulemusteid ei leitud",
  },
  showAllProducts: "Mine poodi",
};

export default {
  messages,
};
