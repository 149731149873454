const messages = {
  title: "Môj osobný výpis",
  desc: "Tu si môžeš pozrieť svoj osobný výpis Bridgestone Plus spolu s nazbieranými a použitými bonusovými bodmi. Maj na pamäti, že pripísanie bodov za objednávky pneumatík môže trvať až 6 týždňov, v závislosti od zdroja.",
  score: "Stav bodov",
  regularPoints: "Štandardné body",
  boosterPoints: "Bonusové body",
  date: "Dátum",
  supplySourceReason: "Zdroj/Dôvod nákupu",
  article: "Tovar",
  brand: "Značka",
  product: "Produkt",
  type: "Typ",
  inch: "Palec",
  season: "Sezóna",
  amount: "Množstvo",
  standardPoints: "Body",
  boosterProduct: "Bonusový produkt",
  boosterSeason: "Bonusová sezóna",
  otherActions: "Iné akcie alebo dôvody",
  totalPoints: "Celkový počet bodov",
  income: "Príjem",
  spending: "Výdavky",
  lastMonth: "Záverečný zostatok",
  loadMore: "Načítať ďalších 20 transakcií",
  typeFilter: "Filtrovať podľa zdroja",
  dateFilter: "Filtrovať podľa obdobia",
  customer_number: "Číslo zákazníka",
  currentPoints: "Aktuálne body",
  calculationDate: "Dátum výpočtu",
  positivePoints: "Pozitívne body",
  negativePoints: "Už minuté",
  tyresAmount: "Počet pneumatík ročne",
  pointsTotal: "Celkový počet bodov",
  currentPointState: "Aktuálny stav bodov",
  currentKickbackState: "Aktuálna refundácia", // "Kickback" môžeme preložiť ako "refundácia"
  points: "Points",
};
export default {
  messages,
};
