const messages = {
  title: "Hjem",
  welcomeMessage:
    'Velkommen til BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Hjem",
  search: "Søg efter",
  sortBy: "Sorter efter",
  seeMore: "Se mere",
  bankstatement: {
    yourScore: "Dit nuværende antal marketingpoint:",
    lastUpdate: "Seneste opdatering:",
    link: "Point balance oversigt",
  },
  shop: {
    bestSold: "Bedst sælgende",
    seasonal: "Sæsonbestemt",
    affordable: "Prisvenligt",
    forPoints: "for {points} point",
    reachedPoints: "Det nødvendige antal point er nået",
    cartRemainPoints: "Resterende point",
    remainingPoints: "{points} point mangler",
    noProducts: "Ingen produkter fundet",
  },
  showAllProducts: "Gå til shoppen",
};
export default {
  messages,
};
