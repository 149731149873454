const messages = {
  makeChoice: "Válasszon itt.",
  seeAll: "Összes megtekintése",
  clear: "Törlés",
  back: "Vissza",
  save: "Mentés",
  close: "Bezárás",
  of: "-ból/-ből",
  to: "-ig",
  copyrights: "© {date} Bridgestone Solutions. Minden jog fenntartva.",
  pageNotFound: "Az oldal nem található",
  accessDenied: "Hozzáférés megtagadva",
  search: "Keresés",
  buttons: {
    export: "Exportálás",
    delete: "Törlés",
    save: "Mentés",
    cancel: "Mégse",
    next: "Következő",
    back: "Vissza",
    skip: "Átugrás",
    send: "Küldés",
    readNow: "Olvass most",
    pointsList: "Pontlista",
    clear: "Törlés",
    more: "Több",
    close: "Bezárás",
    reportError: "Hiba bejelentése",
    accept: "Elfogadás",
    download: "Download",
    dealerInfoDownload: "Download",
    toTheBasket: "To basket",
    toOrderDelivery: "Continue to the delivery address",
    discover: "Discover",
    newAddress: "Create new delivery address",
    selectAddressBtn: "Select delivery address",
    toOrderSummary: "To the order overview",
    placeOrder: "Binding order",
    toDashboard: "To homepage",
    newOrder: "New order",
    userOrders: "To your orders",
    toSearch: "Search",
    select: "Select",
  },
  tooltips: {
    info: "Segítségre van szüksége? +36 30 548 10 40",
  },
  confirmation: {
    header: "Megerősítés szükséges",
    message: "Biztosan törölni szeretné ezt a bejegyzést?",
  },
  nav: {
    dashboard: "Kezdőlap",
    account: "Profilom",
    mailbox: "Bejövő üzenetek",
    singleMailbox: "Üzenetek",
    stammdaten: "Alapadatok",
    news_feeds: "Hír",
    pointsOverview: "Points overview",
    about: "A BS Plus-ról",
    service_number: "Contact service",
    shop: "Jutalombolt",
    salesPromotions: "Sales promotions",
    shopCategory: "Rewards shop",
    productDetails: "Product details",
    userOrders: "User orders",
    shoppingCart: "Shopping cart",
    orderDelivery: "Shipping address",
    orderSummary: "Order overview",
    orderComplete: "Order complete",
  },
  menu: {
    account: "Profilom",
    plus: 'A Bridgestone <span class="text-danger">Plus</span> oldalról',
    salesSupport: "Értékesítési támogatás",
    mailbox: "Bejövő üzenetek",
    about: "A BS Plus-ról",
    trainingAndCourses: "Képzések és tanfolyamok",
    externalTraining: "Külső képzés",
    productInfo: "Termékinformáció",
    productBrochures: "Termékismertetők",
    priceLists: "Árlisták",
    price: "Ár",
    complaintForm: "Panaszbejelentő űrlap",
    sales_support: "Értékesítési támogatás",
    contact_service: "Kapcsolat és szolgáltatás",
    logOut: "Kijelentkezés",
    news: "Hírek",
    news_feeds: "Hír",
    service_number: "Contact service",
    salesPromotions: "Sales promotions",
    shop: "Shop",
  },
  validation: {
    requiredField: "Kötelező mező",
  },
};
export default {
  messages,
};
