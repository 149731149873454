<template>
    <VeeForm class="form user-form" @submit="storeExchange">
        <MDBCard>
            <div class="py-5 px-5">
                <h1 v-html="$t('kickbackExchange.title')"></h1>
                <MDBRow>
                    <MDBCol cols="12" md="6">
                        <div class="pt-2">
                            <div class="d-flex justify-content-center align-items-center">

                                <Field v-model="kickbacks" type="number" class="form-control"
                                    aria-describedby="basic-addon1"
                                    :rules="{ min_money: 1, max_money: maxPoints, required: true }" name="kickbacks"
                                    label="Kickback" /> <span class="ps-2">&euro;</span>
                            </div>

                            <ErrorMessage name="kickbacks" class="text-danger text-small text-end m-0 pt-1" />
                        </div>
                    </MDBCol>
                    <MDBCol cols="12" md="6">
                        <div class="pt-2 text-center" v-if="kickbacks">
                            {{ $t('kickbackExchange.forAdd') }}
                        </div>
                        <div class="text-success fw-bold font-20 text-center text-lowercase" v-if="kickbacks">
                            {{ kickbacks * 4 }} {{ $t('bankstatement.points') }}
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol cols="12" md="6"></MDBCol>
                    <MDBCol cols="12" md="6">
                        <div class="pt-2 text-center" v-if="kickbacks">
                            {{ $t('kickbackExchange.afterExchange') }}
                        </div>
                        <div class="text-success fw-bold font-20 text-center text-lowercase" v-if="kickbacks">
                            {{ sumResult() }} {{ $t('bankstatement.points') }}
                        </div>
                    </MDBCol>
                </MDBRow>


                <small><sup>*</sup></small><small
                    v-html="$t('kickbackExchange.disclaimer', { tyres: shopLimit?.limit, year: currentYear })"></small>
                <div class="pt-4">
                    <MDBBtn color="primary" @click="closeModal()" class="bg-secondary-gray">{{
                        $t("global.buttons.cancel") }}
                    </MDBBtn>
                    <MDBBtn color="danger" type="submit">{{ $t("buttons.convert") }}</MDBBtn>
                </div>
            </div>
        </MDBCard>
    </VeeForm>
</template>

<script setup>
import { defineRule } from 'vee-validate';

import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { ref, computed, defineEmits } from "vue";
import { MDBCard, MDBBtn, MDBCol, MDBRow } from "mdb-vue-ui-kit";
import { useStore } from "vuex";

import i18n from "@/plugins/i18n";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { UserService } from "@/services/user.service";

defineRule('min_money', (value, minValue) => {
    // The field is empty so it should pass
    if (!value || !value.length) {
        return true;
    }

    if (value < minValue) {
        return i18n.global.t('validators.min_money', { min: minValue[0] });
    }
    return true;
});

defineRule('max_money', (value, maxValue) => {
    // The field is empty so it should pass
    if (!value || !value.length) {
        return true;
    }

    if (value > maxValue) {
        return i18n.global.t('validators.max_money', { max: maxValue[0] });
    }
    return true;
});

const store = useStore();
const kickbacks = ref(0)
const emit = defineEmits(["close"]);
const currentYear = new Date().getFullYear()

const user = computed(() => store.getters.getMe);
const maxPoints = computed(() => user.value.kick_back_fees);
const shopLimit = computed(() => store.getters.getTyreLimit);
const userPoints = computed(() => user.value.points);

const storeExchange = async () => {
    if (shopLimit.value && shopLimit.value.limit > user.value.current_tyres_amount) {
        toast(i18n.global.t('kickbackExchange.error', { tyres: shopLimit?.value.limit }), {
            type: "error",
            theme: "colored",
            transition: toast.TRANSITIONS.SLIDE,
            position: toast.POSITION.BOTTOM_RIGHT,
        });

        return false;
    } else {
        const data = {
            kickbacks: kickbacks.value
        }
        const response = await UserService.exchangeKickbacks(data)

        if (response?.status == 200) {
            window.location.reload()
        }
    }
}

const closeModal = () => {
    emit("close", false);
};

const sumResult = () => {
    return parseInt(userPoints.value) + parseInt(kickbacks.value * 4)
}
</script>