const messages = {
  title: "Moje osobiste wyciągi",
  desc: "Tutaj możesz zobaczyć swoje osobiste wyciągi Bridgestone Plus wraz z uzbieranymi i wykorzystanymi punktami bonusowymi. Pamiętaj, że dodawanie punktów za zamówienia opon może zająć nawet 6 tygodni, w zależności od źródła.",
  score: "Saldo punktów",
  regularPoints: "Punkty standardowe",
  boosterPoints: "Punkty bonusowe",
  date: "Data",
  supplySourceReason: "Źródło/Powód zakupu",
  article: "Artykuł",
  brand: "Marka",
  product: "Produkt",
  type: "Typ",
  inch: "Cal",
  season: "Sezon",
  amount: "Ilość",
  standardPoints: "Punkty",
  boosterProduct: "Produkt bonusowy",
  boosterSeason: "Sezon bonusowy",
  otherActions: "Inne działania lub powody",
  totalPoints: "Łączna liczba punktów",
  income: "Przychód",
  spending: "Wydatki",
  lastMonth: "Saldo końcowe",
  loadMore: "Załaduj kolejne 20 transakcji",
  typeFilter: "Filtruj według źródła",
  dateFilter: "Filtruj według okresu",
  customer_number: "Numer klienta",
  currentPoints: "Aktualna liczba punktów",
  calculationDate: "Data obliczeń",
  positivePoints: "Punkty dodatnie",
  negativePoints: "Już wydane",
  tyresAmount: "Liczba opon rocznie",
  pointsTotal: "Łączna liczba punktów",
  currentPointState: "Aktualny stan punktów",
  currentKickbackState: "Aktualny zwrot", // "Kickback" można przetłumaczyć jako "zwrot"
  seasons: {
    summer: "Letnie",
    winter: "Zimowe",
    all: "Wielosezonowe",
  },
  points: "Punkty",
};
export default {
  messages,
};
