<template>
    <div class="px-4 pt-0 pb-5">
        <MDBRow>
            <MDBCol xl="4" md="6" class="mb-4">
                <h4 class="mb-3 text-uppercase">{{ $t("account.profile.personalData") }}</h4>
                <MDBRow>
                    <MDBCol col="12">
                        <div class="label text-uppercase">
                            {{ $t("account.profile.email") }}
                        </div>
                        <div class="text-gray fw-bold">{{ me.email }}</div>
                        <div class="label pt-2 text-uppercase">
                            {{ $t("account.profile.firstname") }}
                        </div>
                        <div class="text-gray fw-bold">{{ profile.forename }}</div>
                        <div class="label  pt-2 text-uppercase">
                            {{ $t("account.profile.surname") }}
                        </div>
                        <div class="text-gray fw-bold">{{ profile.name }}</div>
                    </MDBCol>
                </MDBRow>
            </MDBCol>
            <MDBCol xl="4" md="6" class="mb-4">
                <h4 class="mb-3 text-uppercase">{{ $t("account.profile.operatingData") }}</h4>
                <MDBRow>
                    <MDBCol col="12">
                        <div class="label text-uppercase">
                            {{ $t("account.profile.company") }}
                        </div>
                        <div class="text-gray fw-bold">{{ profile.company ?? '-' }}</div>
                        <div class="label pt-2 text-uppercase">
                            {{ $t("account.profile.streetHouseNr") }}
                        </div>
                        <div class="text-gray fw-bold">{{ profile.street ?? '-' }}</div>
                        <div class="label pt-2 text-uppercase">
                            {{ $t("account.profile.postcode") }}
                        </div>
                        <div class="text-gray fw-bold">{{ profile.postal_code ?? '-' }}</div>
                        <div class="label pt-2 text-uppercase">
                            {{ $t("account.profile.location") }}
                        </div>
                        <div class="text-gray fw-bold">{{ profile.city ?? '-' }}</div>
                    </MDBCol>
                </MDBRow>
            </MDBCol>
            <MDBCol xl="4" md="6" class="mb-4">
                <h4 class="mb-3 text-uppercase">{{ $t("account.profile.legalForm") }}</h4>

                <div v-if="me.wholesalers_dealers && me.wholesalers_dealers.length > 0">
                    <div><b>Bridgestone</b></div>
                    <div v-for="(data, index) in me.wholesalers_dealers" :key="index" class="pt-2 text-gray fw-bold">
                        {{ data.customer_number }}
                    </div>
                </div>
                <div v-else>-</div>
            </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol>
                <MDBBtn type="button" color="danger" @click="redirectToContact()">
                    {{ $t('global.buttons.reportError') }}
                </MDBBtn>
            </MDBCol>
        </MDBRow>
    </div>
</template>

<script setup>
import { MDBRow, MDBCol, MDBBtn } from "mdb-vue-ui-kit";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();
const router = useRouter();

const me = computed(() => store.getters.getMe)
const profile = computed(() => store.getters.getProfile)

const redirectToContact = () => {
    router.push({ name: "service_number" });
};
</script>