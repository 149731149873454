const messages = {
  title: "Home",
  welcomeMessage:
    'Welcome to BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Home",
  search: "Search for",
  sortBy: "Sort by",
  seeMore: "See more",
  bankstatement: {
    yourScore: "Your current number of marketing points:",
    lastUpdate: "Last update:",
    link: "Points balance overview",
  },
  shop: {
    bestSold: "Best sold",
    seasonal: "Seasonal",
    affordable: "Affordable",
    forPoints: "for {points} points",
    reachedPoints: "Required points reached",
    cartRemainPoints: "Points remaining",
    remainingPoints: "{points} Points remaining",
    noProducts: "The search criteria returned no hits",
  },
  showAllProducts: "Go to shop",
};

export default {
  messages,
};
