const messages = {
  title: "Predajné akcie",
  desc: "Aktívne využívajte naše predajné akcie u svojich zákazníkov. Samozrejme, môžete zákazníkom ponúknuť obe akcie. Prečítajte si viac o Záruke na pneumatiky a Drive Our Best nižšie.",
  participation: {
    title: "Požiadajte o účasť teraz",
    desc: "Chcete implementovať jednu alebo viacero našich akcií vo svojej predajni? Potom môžete požiadať o účasť tu. Vaša spoločnosť bude aktivovaná pre akciu/akcie a dostanete propagačné materiály do 4 týždňov, propagačné materiály pre váš predajný sen.<br/><br/>Urobte svoju voľbu:",
    request: "POŽIADAŤ O ÚČASŤ",
    success: "Ďakujeme, vaša žiadosť bola odoslaná",
  },
};
export default {
  messages,
};
