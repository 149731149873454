const messages = {
  title: "Inicio",
  welcomeMessage:
    'Bienvenido a BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Inicio",
  search: "Buscar",
  sortBy: "Ordenar por",
  seeMore: "Ver más ",
  bankstatement: {
    yourScore: "Su número actual de puntos de marketing:",
    lastUpdate: "Última actualización:",
    link: "Resumen del saldo de puntos",
  },
  shop: {
    bestSold: "Más vendidos",
    seasonal: "De temporada",
    affordable: "Económicos",
    forPoints: "por {points} puntos",
    reachedPoints: "Se ha alcanzado el nivel de puntos requerido",
    cartRemainPoints: "Puntos restantes",
    remainingPoints: "Faltan {points} puntos",
    noProducts: "No se encontraron resultados",
  },
  showAllProducts: "Ir a la tienda",
};

export default {
  messages,
};
