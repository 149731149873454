import { createRouter, createWebHistory } from "vue-router";
import BankStatement from "../views/BankStatement";
import Shop from "./Shop";
import Auth from "./Auth";
import Info from "./Info";
import ProductInfo from "./ProductInfo";
import Trainings from "./Trainings";
import Dashboard from "@/components/Dashboard/index";
import ExampleProducts from "@/components/Dashboard/ExampleProducts";
import PageNotFound from "../views/404.vue";
import AccessDenied from "../views/AccessDenied.vue";
import User from "./User";
import store from "../store";
import TokenService from "@/services/storage.service";
import { HTTP_STATUS_CODES } from "@/configs/constants";

// Define a function to dynamically resolve the component based on Vuex state
const resolveComponent = async () => {
  if (store.getters.isLiteVersion) {
    return import(
      /* webpackChunkName: "lite-version" */ "@/layouts/LiteVersion.vue"
    );
  } else {
    return import(/* webpackChunkName: "full-version" */ "@/views/Default.vue");
  }
};

const routes = [
  {
    path: "/bank_statement",
    name: "BankStatement",
    component: BankStatement,
    meta: {
      requiredLang: ["de", "ch"],
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: resolveComponent,
    props: {
      content: Dashboard,
      data: ExampleProducts,
    },
    meta: {
      // TODO: check roles to work with LV. Commented by AZ
      //requiredRole: ["dealer", "admin", "staff"],
    },
  },
  ...Shop,
  ...Auth,
  ...Trainings,
  ...ProductInfo,
  ...User,
  ...Info,
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: PageNotFound,
  },
  {
    path: "/access-denied",
    name: "accessDenied",
    component: AccessDenied,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  // Check required role
  let requiredRole = to.meta.requiredRole;
  let requiredPermission = to.meta.permission;

  if (requiredRole) {
    try {
      let user = store.getters.getMe;
      let response;
      if (!user) {
        response = await store.dispatch("me");
      }
      if (
        (response?.status === HTTP_STATUS_CODES.UNAUTHORIZED &&
          response?.statusText === "Unauthorized") ||
        !user
      ) {
        next("/");
        return;
      }

      if (requiredPermission) {
        let haveAccess = TokenService.showElement(requiredPermission);
        if (!haveAccess) {
          next("/access-denied");
        }
      }

      // case when user needs to be approved as direct/indirect
      //  let restrictedAccess = await store.getters.getAccessRestricted;
      //   if (restrictedAccess === true && to.path !== '/dashboard') {
      //     next("/dashboard")
      //  }

      let role = user.roles.find((item) => requiredRole.includes(item.name));
      if (!role) {
        next("/account/dashboard");
        return;
      }
    } catch (e) {
      console.log(e);
      next("/dashboard");
      return;
    }
  }
  // case when user needs to be approved as direct/indirect
  // Check if the user has restricted access
  // let restrictedAccess = await store.getters.getAccessRestricted;

  // Check if restricted access is true and the route is not "/dashboard"
  // if (restrictedAccess === true && to.path !== '/dashboard') {
  //   next("/dashboard");  // Redirect to dashboard if condition is met
  // } else {
  next(); // Allow the navigation
  // }
});

export default router;
