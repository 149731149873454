const messages = {
  title: "Welcome Bridgestone <span class='text-danger'>PLUS</span>",
  termsAndConditions: "Terms and Conditions",
  accept: "Accept",
  decline: "Decline",
  dataModal:
    "To tailor this offer to your interests, we kindly ask you to briefly specify your product interests or business areas.",
  scrollToAccept: "Scroll down to accept our terms to proceed",
  welcomeMessage: "Welcome to Bridgestone Plus",
  tailorOffer:
    "To tailor this offer to your interests, we kindly ask you to briefly specify your product interests or business areas.",
  bussinessType:
    "To tailor this offer to your interests, we kindly ask you to briefly specify your product interests or business areas.",
  selectAreas: "Please select from the following areas",
  multipleAnswers: "(multiple answers are possible):",
  personalPreferences: "Personal Preferences",
  carTyres: "Tyres for cars, SUVs, utility",
  motorcyclesTyres: "Tyres for motorcycles",
  truckBusTyres: "Tyres for trucks and buses",
  agricultureTyres: "Tyres for agricultural vehicles",
  otrTyres: "OTR tyres",
  save: "Save",
  businessRelationship: "What is your business relationship with Bridgestone?",
  selectRelationship: "Please select*:",
  tradingPartner: "I am a trading partner who...",
  tradingPartnerDirect: "... orders directly from Bridgestone",
  tradingPartnerIndirect: "... orders indirectly (via wholesalers, etc.)",
  tradingPartnerBoth: "... orders both directly and indirectly",
  fleetResponsibleDirect: "I am a fleet manager who...",
  fleetResponsibleIndirect: "... orders indirectly (via trade or wholesale)",
  fleetResponsibleBoth: "... orders both directly and indirectly",
  noBusinessRelationship:
    "<b>I have no business relationship with Bridgestone</b> (neither direct nor indirect)",
  yourCustomerNumber: "Your Bridgestone Customer Number*:",
  enterCustomerNumber: "Enter your customer number",
  waitPersonalisedView:
    "Please wait a moment, your personalized view of BRIDGESTONE PLUS will be generated.",
  personalised: "PERSONALISED",
  plusInfo:
    "Here you can find information about your Plus account and your Plus inbox.",
  continue: "Continue",
  skip: "Skip",
  oneOfTwo: "1 of 2",
  openApps:
    "You can directly open the apps you are already registered for. You can also request access to other apps that may be relevant to you.",
  requiredFields:
    "Please complete the mandatory fields: Title, First Name, Last Name",
  stage: "Step",
  chooseOne: "(Please check at least 1 box)",
  required: "*mandatory fields",
  lengthCsNumber: "Customer number length: 6-10 digits",
  waitForAdminApproval:
    "Thank you for enrolling in the Bridgestone Plus Rewards program. We are currently reviewing your application. You will receive a confirmation email once your application has been approved.",
};

export default {
  messages,
};
