const messages = {
  title: "Domů",
  welcomeMessage:
    'Vítejte v BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Domů",
  search: "Hledat",
  sortBy: "Seřadit podle",
  seeMore: "Zobrazit více",
  bankstatement: {
    yourScore: "Váš aktuální počet marketingových bodů:",
    lastUpdate: "Nejnovější aktualizace:",
    link: "Přehled bodového zůstatku",
  },
  shop: {
    bestSold: "Nejprodávanější",
    seasonal: "Sezónní",
    affordable: "Cenově dostupné",
    forPoints: "za {points} bodů",
    reachedPoints: "Byl dosažen požadovaný počet bodů",
    cartRemainPoints: "Zbývající body",
    remainingPoints: "Chybí ještě {points} bodů",
    noProducts: "Nenalezeny žádné produkty",
  },
  showAllProducts: "Jděte do obchodu",
};
export default {
  messages,
};
