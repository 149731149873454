const messages = {
  makeChoice: "Направете своя избор тук",
  seeAll: "Виж всички",
  clear: "Изчисти",
  back: "Назад",
  save: "Запази",
  close: "Затвори",
  of: "от",
  to: "до",
  copyrights: "© {date} Bridgestone Solutions. Всички права запазени.",
  pageNotFound: "Страницата не е намерена",
  accessDenied: "Достъпът е отказан",
  search: "Търсене",
  buttons: {
    export: "Експортиране",
    delete: "Изтрий",
    save: "Запази",
    cancel: "Отказ",
    next: "Напред",
    back: "Назад",
    skip: "Пропусни",
    send: "Изпрати",
    pointsList: "Списък с точки",
    clear: "Изчисти",
    readNow: "прочетете сега",
    more: "повече",
    close: "Затвори",
    reportError: "Докладване за грешка",
    accept: "Приеми",
    download: "Download",
    dealerInfoDownload: "DOWNLOAD",
    toTheBasket: "To basket",
    toOrderDelivery: "Continue to the delivery address",
    discover: "Discover",
    newAddress: "Create new delivery address",
    selectAddressBtn: "Select delivery address",
    toOrderSummary: "To the order overview",
    placeOrder: "Binding order",
    toDashboard: "To homepage",
    newOrder: "New order",
    userOrders: "To your orders",
    toSearch: "Search",
    select: "Select",
  },
  tooltips: {
    info: "Нуждаете се от помощ? xx xx xx xx",
  },
  confirmation: {
    header: "Изисква се потвърждение",
    message: "Искате ли да изтриете този запис?",
  },
  nav: {
    dashboard: "Начало",
    account: "моя профил",
    mailbox: "Входяща поща",
    singleMailbox: "Съобщения",
    stammdaten: "Основни данни",
    news_feeds: "Новини",
    pointsOverview: "Points overview",
    about: "За BS Plus",
    service_number: "Contact service",
    shop: "Магазин за награди",
    salesPromotions: "Sales promotions",
    shopCategory: "Rewards shop",
    productDetails: "Product details",
    userOrders: "User orders",
    shoppingCart: "Shopping cart",
    orderDelivery: "Shipping address",
    orderSummary: "Order overview",
    orderComplete: "Order complete",
  },
  menu: {
    account: "моя профил",
    plus: 'За Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Търговска подкрепа",
    mailbox: "Входяща поща",
    about: "За BS Plus",
    trainingAndCourses: "Обучения и курсове",
    externalTraining: "Външно обучение",
    productInfo: "Информация за продукта",
    productBrochures: "Брошури на продуктите",
    priceLists: "Ценови листи",
    price: "Цена",
    complaintForm: "Формуляр за жалби",
    sales_support: "Търговска подкрепа",
    contact_service: "Контакти и обслужване",
    logOut: "Изход",
    news: "Новини",
    news_feeds: "Новини",
    service_number: "Contact service",
    salesPromotions: "Sales promotions",
    shop: "Shop",
  },
  validation: {
    requiredField: "Задължително поле",
  },
};

export default {
  messages,
};
