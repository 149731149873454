const messages = {
  makeChoice: "Izdari savu izvēli šeit.",
  seeAll: "Skatīt visu",
  clear: "Notīrīt",
  back: "Atpakaļ",
  save: "SAGLABĀT",
  close: "Aizvērt",
  of: "No",
  to: "līdz",
  copyrights: "© {date} Bridgestone Solutions. Visas tiesības aizsargātas.",
  pageNotFound: "Lapa nav atrasta",
  accessDenied: "Piekļuve liegta",
  search: "Meklēt",
  buttons: {
    export: "Eksportēt",
    delete: "Dzēst",
    save: "SAGLABĀT",
    cancel: "Atcelt",
    next: "Nākamais",
    back: "Atpakaļ",
    skip: "Izlaist",
    send: "SAGLABĀT",
    readNow: "Skaityk dabar",
    pointsList: "Punktu saraksts",
    clear: "Notīrīt",
    more: " Vairāk",
    close: "Aizvērt",
    reportError: "Ziņot par kļūdu",
    accept: "Pieņemt",
    download: "Download",
    dealerInfoDownload: "Download",
    toTheBasket: "To basket",
    toOrderDelivery: "Continue to the delivery address",
    discover: "Discover",
    newAddress: "Create new delivery address",
    selectAddressBtn: "Select delivery address",
    toOrderSummary: "To the order overview",
    placeOrder: "Binding order",
    toDashboard: "To homepage",
    newOrder: "New order",
    userOrders: "To your orders",
    toSearch: "Search",
    select: "Select",
  },
  tooltips: {
    info: "Vai nepieciešama palīdzība? +371 29 16 22 12",
  },
  confirmation: {
    header: "Nepieciešams apstiprinājums",
    message: "Vai tiešām vēlaties dzēst šo ierakstu?",
  },
  nav: {
    dashboard: "Skums",
    account: "Mans Profils",
    mailbox: "Iesūtne",
    singleMailbox: "Ziņojumi",
    stammdaten: "Pamatdati",
    news_feeds: "Jaunumi",
    pointsOverview: "Points overview",
    about: "Par BS Plus",
    service_number: "Contact service",
    shop: "Atlīdzības veikals",
    salesPromotions: "Sales promotions",
    shopCategory: "Rewards shop",
    productDetails: "Product details",
    userOrders: "User orders",
    shoppingCart: "Shopping cart",
    orderSummary: "Order overview",
    orderComplete: "Order complete",
  },
  menu: {
    account: "Mans Profils",
    plus: 'Par Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Pārdošanas atbalsts",
    mailbox: "Iesūtne",
    about: "Par BS Plus",
    trainingAndCourses: "Apmācības un kursi",
    externalTraining: "Ārējā apmācība",
    productInfo: "Produkta informācija",
    productBrochures: "Produktu brošūras",
    priceLists: "Cenu saraksti",
    price: "Cena",
    complaintForm: "Sūdzības forma",
    sales_support: "Pārdošanas atbalsts",
    contact_service: "Kontakti un serviss",
    logOut: "Izrakstīties",
    news: "Jaunumi",
    news_feeds: "Jaunumi",
    service_number: "Contact service",
    salesPromotions: "Sales promotions",
    shop: "Shop",
  },
  validation: {
    requiredField: "Obligāts lauks",
  },
};

export default {
  messages,
};
