const messages = {
  title: "My personal account statement",
  desc: "Here you can view your personal Bridgestone Plus account statement with your earned and used bonus points. Please note that it may take up to 6 weeks for points to be credited for your tire orders, depending on the source.",
  score: "Points balance",
  regularPoints: "Regular points",
  boosterPoints: "Bonus points",
  date: "Date",
  supplySourceReason: "Source/Reason for purchase",
  article: "Article",
  brand: "Brand",
  product: "Product",
  type: "Type",
  inch: "Inch",
  season: "Season",
  amount: "Quantity",
  standardPoints: "Points",
  boosterProduct: "Bonus product",
  boosterSeason: "Bonus season",
  otherActions: "Other actions or reasons",
  totalPoints: "Total points",
  income: "Income",
  spending: "Expenses",
  lastMonth: "Closing balance",
  loadMore: "Load more 20 transactions",
  typeFilter: "Filter by source",
  dateFilter: "Filter by period",
  customer_number: "Customer number",
  currentPoints: "Current points",
  calculationDate: "Calculation date",
  positivePoints: "Positive points",
  negativePoints: "Already spent",
  tyresAmount: "Number of tires per year",
  pointsTotal: "Total points",
  currentPointState: "Current points status",
  currentKickbackState: "Current cashback",
  points: "Points",
};
export default {
  messages,
};
