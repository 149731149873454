const messages = {
  title: "Laipni lūdzam Bridgestone <span class='text-danger'>PLUS</span>!",
  termsAndConditions: "Noteikumi un nosacījumi",
  accept: "Pieņemt",
  decline: "Noraidīt",
  dataModal:
    "Lai pielāgotu šo piedāvājumu jūsu interesēm, lūdzu, norādiet savas produktu intereses vai darbības jomas.",
  scrollToAccept: "Ritiniet uz leju, lai pieņemtu mūsu noteikumus un turpinātu",
  welcomeMessage: "Laipni lūdzam Bridgestone Plus",
  tailorOffer:
    "Lai pielāgotu šo piedāvājumu jūsu interesēm, lūdzu, norādiet savas produktu intereses vai darbības jomas.",
  bussinessType:
    "Lai pielāgotu šo piedāvājumu jūsu interesēm, lūdzu, norādiet savas produktu intereses vai darbības jomas.",
  selectAreas: "Lūdzu, izvēlieties no šādām jomām",
  multipleAnswers: "(var izvēlēties vairākas atbildes):",
  personalPreferences: "Personīgās preferences",
  carTyres: "Riepas vieglajām automašīnām, SUV un mikroautobusiem",
  motorcyclesTyres: "Motociklu riepas",
  truckBusTyres: "Kravas automašīnu un autobusu riepas",
  agricultureTyres: "Lauksaimniecības tehnikas riepas",
  otrTyres: "Industriālās tehnikas riepas",
  save: "SAGLABĀT",
  businessRelationship: "Kādas ir jūsu biznesa attiecības ar Bridgestone?",
  selectRelationship: "Lūdzu, izvēlieties*:",
  tradingPartner: "Es esmu tirdzniecības partneris, kurš...",
  tradingPartnerDirect: "... veic tiešu pasūtījumu no Bridgestone",
  tradingPartnerIndirect:
    "... veic netiešu pasūtījumu (piem., caur vairumtirdzniecību)",
  tradingPartnerBoth: "... veic gan tiešu, gan netiešu pasūtījumu",
  fleetResponsibleDirect: "Es esmu autoparka vadītājs, kurš...",
  fleetResponsibleIndirect:
    "... veic netiešu pasūtījumu (caur mazumtirdzniecību vai vairumtirdzniecību)",
  fleetResponsibleBoth: "... veic gan tiešu, gan netiešu pasūtījumu",
  noBusinessRelationship:
    "<b>Man nav biznesa attiecību ar Bridgestone </b>(ne tiešu, ne netiešu)",
  yourCustomerNumber: "Jūsu Bridgestone klienta numurs*:",
  enterCustomerNumber: "Ievadiet savu klienta numuru",
  waitPersonalisedView:
    "Lūdzu, uzgaidiet brīdi, kamēr tiek ģenerēts jūsu personīgais BRIDGESTONE PLUS skats.",
  personalised: "PERSONALIZĒTS",
  plusInfo: "Šeit atradīsiet informāciju par savu Plus kontu un Plus iesūtni.",
  continue: "Turpināt",
  skip: "Izlaist",
  oneOfTwo: "1 no 2",
  openApps:
    "Jūs varat tieši atvērt tās lietotnes, kurās jau esat reģistrējies. Jūs varat arī pieprasīt piekļuvi citām lietotnēm, kas jums varētu būt aktuālas.",
  requiredFields: "Lūdzu, aizpildiet obligātos laukus: Tituls, Vārds, Uzvārds",
  stage: "posms",
  chooseOne: "(Lūdzu, atzīmējiet vismaz 1 izvēles rūtiņu)",
  required: "*obligāti lauki",
  lengthCsNumber: "Klienta numura garums: 6-10 cipari",
  waitForAdminApproval:
    "Paldies, ka reģistrējāties programmai Bridgestone Plus Rewards. Pašlaik mēs izskatām jūsu pieteikumu. Tiklīdz jūsu pieteikums būs apstiprināts, jūs saņemsit apstiprinājuma e-pastu.",
};

export default {
  messages,
};
