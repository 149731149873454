const messages = {
  makeChoice: "Vyberte si tu.",
  seeAll: "Zobraziť všetko",
  clear: "Vyčistiť",
  back: "Späť",
  save: "Uložiť",
  close: "Zatvoriť",
  of: "z",
  to: "do",
  copyrights: "© {date} Bridgestone Solutions. Všetky práva vyhradené.",
  pageNotFound: "Stránka nebola nájdená",
  accessDenied: "Prístup zamietnutý",
  search: "Hľadať",
  buttons: {
    export: "Exportovať",
    delete: "Vymazať",
    save: "Uložiť",
    cancel: "Zrušiť",
    next: "Ďalej",
    back: "Späť",
    skip: "Preskočiť",
    send: "Odoslať",
    readNow: "Přečtěte si nyní",
    pointsList: "Zoznam bodov",
    clear: "Vyčistiť",
    more: "Viac",
    close: "Zatvoriť",
    reportError: "Nahlásiť chybu",
    accept: "Prijať",
    download: "Download",
    dealerInfoDownload: "Download",
    toTheBasket: "To basket",
    toOrderDelivery: "Continue to the delivery address",
    discover: "Discover",
    newAddress: "Create new delivery address",
    selectAddressBtn: "Select delivery address",
    toOrderSummary: "To the order overview",
    placeOrder: "Binding order",
    toDashboard: "To homepage",
    newOrder: "New order",
    userOrders: "To your orders",
    toSearch: "Search",
    select: "Select",
  },
  tooltips: {
    info: "Potrebujete pomoc? +421 905 306 674",
  },
  confirmation: {
    header: "Vyžaduje sa potvrdenie",
    message: "Chcete túto položku vymazať?",
  },
  nav: {
    dashboard: "Domov",
    account: "Môj Profil",
    mailbox: "VAŠE ZPRÁVY",
    singleMailbox: "Správy",
    stammdaten: "Profil",
    news_feeds: "Správy",
    pointsOverview: "Points overview",
    about: "O BS Plus",
    service_number: "Contact service",
    shop: "Obchod s odmenami",
    salesPromotions: "Sales promotions",
    shopCategory: "Rewards shop",
    productDetails: "Product details",
    userOrders: "User orders",
    shoppingCart: "Shopping cart",
    orderDelivery: "Shipping address",
    orderSummary: "Order overview",
    orderComplete: "Order complete",
  },
  menu: {
    account: "Môj Profil",
    plus: 'O Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Podpora predaja",
    mailbox: "VAŠE ZPRÁVY",
    about: "O BS Plus",
    trainingAndCourses: "Školenia a kurzy",
    externalTraining: "Externé školenie",
    productInfo: "Informácie o produktoch",
    productBrochures: "Produktové brožúry",
    priceLists: "Cenníky",
    price: "Cena",
    complaintForm: "Formulár sťažnosti",
    sales_support: "Podpora predaja",
    contact_service: "Kontaktné údaje a servis",
    logOut: "Odhlásiť sa",
    news: "Novinky",
    news_feeds: "Správy",
    service_number: "Contact service",
    salesPromotions: "Sales promotions",
    shop: "Shop",
  },
  validation: {
    requiredField: "Povinné pole",
  },
};

export default {
  messages,
};
