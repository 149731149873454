const messages = {
  makeChoice: "Træf dit valg her",
  seeAll: "Se alle",
  clear: "Ryd",
  back: "Tilbage",
  save: "Gem",
  close: "Luk",
  of: "Af",
  to: "til",
  copyrights: "© {date} Bridgestone. Alle rettigheder forbeholdes.",
  pageNotFound: "Siden blev ikke fundet",
  accessDenied: "Adgang nægtet",
  search: "Søg",
  buttons: {
    export: "Eksportér",
    delete: "Slet",
    save: "Gem",
    cancel: "Annuller",
    next: "Næste",
    back: "Tilbage",
    skip: "Spring over",
    send: "Gem",
    pointsList: "Pointliste",
    clear: "Ryd",
    readNow: "læs nu",
    more: "Mere",
    close: "Luk",
    reportError: "Rapporter fejl",
    accept: "Acceptere",
    download: "Download",
    dealerInfoDownload: "DOWNLOAD FORHANDLERINFORMATION",
    toTheBasket: "To basket",
    toOrderDelivery: "Continue to the delivery address",
    discover: "Discover",
    newAddress: "Create new delivery address",
    selectAddressBtn: "Select delivery address",
    toOrderSummary: "To the order overview",
    placeOrder: "Binding order",
    toDashboard: "To homepage",
    newOrder: "New order",
    userOrders: "To your orders",
    toSearch: "Search",
    select: "Select",
  },
  tooltips: {
    info: "Har du brug for hjælp? +45 87 64 66 64",
  },
  confirmation: {
    header: "Bekræftelse krævet",
    message: "Vil du slette denne post?",
  },
  nav: {
    dashboard: "Hjem",
    account: "Min Profil",
    mailbox: "Indbakke",
    singleMailbox: "Beskeder",
    stammdaten: "Stamdata",
    news_feeds: "Nyheder",
    pointsOverview: "Points overview",
    about: "Om BS Plus",
    service_number: "Contact service",
    shop: "Belønningsbutik",
    salesPromotions: "Salgsfremstød",
    shopCategory: "Rewards shop",
    productDetails: "Product details",
    userOrders: "User orders",
    shoppingCart: "Shopping cart",
    orderDelivery: "Shipping address",
    orderSummary: "Order overview",
  },
  menu: {
    account: "Min Profil",
    plus: 'Om Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Salgsstøtte",
    mailbox: "Indbakke",
    about: "Om BS Plus",
    trainingAndCourses: "Uddannelse og kurser",
    externalTraining: "Ekstern træning",
    productInfo: "Produktinformation",
    productBrochures: "Produktbrochurer",
    priceLists: "Prislister",
    price: "Pris",
    complaintForm: "Klageformular",
    sales_support: "Salgsstøtte",
    contact_service: "Kontaktoplysninger og service",
    logOut: "Log ud",
    news: "Nyheder",
    news_feeds: "Nyheder",
    service_number: "Contact service",
    salesPromotions: "Salgsfremstød",
    shop: "Shop",
  },
  validation: {
    requiredField: "Påkrævet felt",
  },
};
export default {
  messages,
};
