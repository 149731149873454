const messages = {
  title: "Prodejní akce",
  desc: "Aktivně využívejte naše prodejní akce pro své zákazníky. Samozřejmě můžete zákazníkům nabídnout obě akce. Přečtěte si více o Záruce na pneumatiky a Drive Our Best níže.",
  participation: {
    title: "Žádejte o účast nyní",
    desc: "Chcete implementovat jednu nebo více našich akcí ve svém obchodě? Pak můžete zde žádat o účast. Vaše společnost bude aktivována pro akci/akce a obdržíte reklamní materiály do 4 týdnů reklamní materiály pro váš prodejní sen.<br/><br/>Proveďte svůj výběr:",
    request: "ŽÁDEJTE O ÚČAST",
    success: "Děkujeme, vaše žádost byla odeslána",
  },
};
export default {
  messages,
};
