const messages = {
  title: "Os meus pontos",
  desc: "Aqui pode consultar o seu extrato de conta pessoal Bridgestone Plus, com os pontos de recompensa recebidos e utilizados. Note que o crédito dos pontos referentes aos seus pedidos de pneus, dependendo da origem, pode demorar até 6 semanas após a realização do pedido para ser contabilizado.",
  score: "Saldo de pontos",
  regularPoints: "Pontos regulares",
  boosterPoints: "Pontos bônus",
  date: "Data",
  supplySourceReason: "Canal de Compra",
  article: "Artigo",
  brand: "Marca",
  product: "Produto",
  type: "Tipo",
  inch: "Jante",
  season: "Temporada",
  amount: "Quantidade",
  standardPoints: "Pontos",
  boosterProduct: "Produto bônus",
  boosterSeason: "Estação bônus",
  otherActions: "Ação",
  totalPoints: "Pontos totais",
  income: "Entradas",
  spending: "Saídas",
  lastMonth: "Saldo final",
  loadMore: "Carregar mais 20 transações",
  typeFilter: "Filtrar Por Canal",
  dateFilter: "Filtrar Por Data",
  customer_number: "Nº do cliente",
  currentPoints: "Pontos atuais",
  calculationDate: "Data de cálculo",
  positivePoints: "Pontos positivos",
  negativePoints: "Já gastos",
  tyresAmount: "Quantidade de pneus por ano",
  pointsTotal: "Total de pontos",
  currentPointState: "Saldo de pontos atual",
  currentKickbackState: "Cashback atual",
  seasons: {
    summer: "Verão",
    winter: "Inverno",
    all: "Todos",
  },
  points: "Pontos",
};
export default {
  messages,
};
