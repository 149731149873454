const messages = {
  title: "Myyntikampanjat",
  desc: "Käytä aktiivisesti myyntikampanjoitamme asiakkaidesi kanssa. Voit tietysti tarjota asiakkaille molempia kampanjoita. Lue lisää Rengastakuusta ja Drive Our Best -kampanjasta alta.",
  participation: {
    title: "Hae osallistumista nyt",
    desc: "Haluatko toteuttaa yhden tai useamman kampanjamme myymälässäsi? Voit sitten hakea osallistumista täältä. Yrityksesi aktivoidaan kampanjaan/kampanjoihin ja saat 4 viikon kuluessa mainosmateriaaleja myyntiunelmaasi varten.<br/><br/>Tee valintasi:",
    request: "HAE OSALLISTUMISTA",
    success: "Grazie, la tua richiesta è stata inviata",
  },
};
export default {
  messages,
};
