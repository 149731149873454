const messages = {
  makeChoice: "Faites votre choix ici.",
  seeAll: "See All",
  clear: "Supprimer",
  back: "Retour",
  save: "Enregistrer",
  of: "De",
  to: "à",
  copyrights: "© {date} Bridgestone Solutions. Tous droits réservés.",
  pageNotFound: "Page non trouvée",
  accessDenied: "Accès refusé",
  search: "Recherche",
  buttons: {
    export: "Exporter",
    delete: "Supprimer",
    save: "Sauvegarder",
    cancel: "Cancel",
    next: "Continuer",
    back: "RETOUR",
    clear: "Effacer",
    skip: "Sauter",
    send: "Envoyer",
    close: "Fermer",
    pointsList: "Liste des points",
    readNow: "Lire maintenant",
    more: "Plus",
    reportError: "Signaler une erreur",
    accept: "Accepter",
    download: "Download",
    dealerInfoDownload: "Download",
    toTheBasket: "To basket",
    toOrderDelivery: "Continue to the delivery address",
    discover: "Discover",
    newAddress: "Create new delivery address",
    selectAddressBtn: "Select delivery address",
    toOrderSummary: "To the order overview",
    placeOrder: "Binding order",
    toDashboard: "To homepage",
    newOrder: "New order",
    userOrders: "To your orders",
    toSearch: "Search",
    select: "Select",
  },
  tooltips: {
    info: "Besoin d'aide? 04 84 49 29 00",
  },
  confirmation: {
    header: "Confirmation Required",
    message: "Do you want to delete this record?",
  },
  nav: {
    dashboard: "Accueil",
    account: "Mon profil",
    mailbox: "Boîte de réception",
    singleMailbox: "Message",
    stammdaten: "Données de référence",
    news_feeds: "Actualités",
    pointsOverview: "Points overview",
    about: "À propos de BS Plus",
    service_number: "Contact service",
    shop: "Boutique des primes",
    salesPromotions: "Sales promotions",
    shopCategory: "Rewards shop",
    productDetails: "Product details",
    userOrders: "User orders",
    shoppingCart: "Shopping cart",
    orderDelivery: "Shipping address",
    orderSummary: "Order overview",
    orderComplete: "Order complete",
  },
  menu: {
    account: "Mon profil",
    plus: 'À propos de Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Soutien à la vente",
    mailbox: "Boîte de réception",
    about: "À propos de BS Plus",
    trainingAndCourses: "Formations et cours",
    externalTraining: "Formations externes",
    productInfo: "Informations sur les produits",
    productBrochures: "Brochures sur les produits",
    priceLists: "Listes de prix",
    price: "Pricat",
    complaintForm: "Formulaire de réclamation",
    sales_support: "Soutien à la vente",
    contact_service: "Contact & Service",
    logOut: "Se déconnecter",
    news: "Actualités",
    news_feeds: "Actualités",
    salesPromotions: "Sales promotions",
    shop: "Shop",
  },
  validation: {
    requiredField: "Champs obligatoires",
  },
};

export default {
  messages,
};
