<template>
  <Loader :show="loading" v-if="loading"></Loader>
  <MDBRow v-else>
    <MDBCol col="12">
      <Breadcrumbs :parent-routes="['shop', 'hunterShop']" :route-name="hunter.title" :showGoBack="true"
        class="breadcrumbContainer" />
    </MDBCol>
    <MDBCol md="7">
      <MDBCard class="mb-3 mb-md-0">
        <MDBCardBody class="d-flex justify-content-center product-image-wrap">
          <img :src="hunter.poster" :alt="$t('productImg')" class="product-image" />
        </MDBCardBody>
      </MDBCard>
    </MDBCol>

    <MDBCol md="5">
      <MDBCard class="product-main-data">
        <MDBCardBody class="d-flex flex-column justify-content-between">
          <div>
            <h5 class="font-16">{{ hunter.title }}</h5>

            <div class="font-16">{{ hunter.short_description }}</div>
            <div class="fw-bold pt-2">
              <div>{{ $t("hunter.priceInfo") }}:</div>
              <div>{{ hunter.price }} €</div>
            </div>
            <div class="fw-bold pt-2">
              <div>{{ $t("hunter.pointsInfo") }}:</div>
              <div>{{ hunter.price * 4 }}</div>
            </div>
            <div class="font-16 pt-2 text-uppercase">
              {{ $t("hunter.investInfo") }}
            </div>
            <div class="font-16 pt-2">{{ $t("hunter.contactInfo") }}:</div>

            <div v-if="hunter.contacts?.length > 0">
              <div class="font-16 pt-2" v-for="(contact, index) in hunter.contacts" :key="index">
                <div>{{ contact.company }}</div>
                <div>
                  {{
                    contact.first_name
                  }}
                  {{ contact.last_name }}
                </div>
                <div>
                  Email:
                  <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
                </div>
                <div>Tel.: {{ contact.phone }}</div>
              </div>
            </div>
          </div>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from "mdb-vue-ui-kit";
import Breadcrumbs from "@/components/General/Breadcrumbs/index";
import Loader from "@/components/General/Loader/index";
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();

const loading = computed(() => store.getters.getPending);
const hunter = computed(() => store.getters.getSingleHunter);
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";

.product-image-wrap {
  height: 466px;
}

.product-image {
  max-height: 100%;
}

.product-main-data {
  min-height: 466px;
}

.breadcrumbContainer {
  padding: 0 0 20px 0;
}
</style>
