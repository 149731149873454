<template>
  <MDBCard v-if="canSeeBox" class="mb-3 mb-lg-0 points-container" :class="[
    {
      intro__step: user?.commitment_letter_accept && user?.intro == 2,
    },
  ]">
    <MDBCardBody>
      <MDBCardTitle class="title title-slash text-uppercase font-12 font-md-25">
        {{ $t("bankstatement.score") }}
      </MDBCardTitle>
      <MDBCardText>
        <p class="mb-0 font-xxxl-18 font-11 fw-bold">
          {{ $t("dashboard.bankstatement.yourScore") }}
        </p>
        <h2 class="success-green font-18 font-md-25 fw-bold">
          {{ user.display_points ? user.points ?? 0 : 0 }} {{ $t("bankstatement.points") }}
        </h2>
        <p class="text-small">
          {{ $t("dashboard.bankstatement.lastUpdate") }}
          {{
            lastUpdate?.date
              ? dates.parseDate(lastUpdate?.date)
              : dates.parseDate(new Date())
          }}
        </p>
        <DashboardLink :to="{ name: 'pointsOverview' }" :disabled="(user?.commitment_letter_accept && user?.intro == 2) ||
          isStaff ||
          !canSeeAccountStatement
          ">
          {{ $t("dashboard.bankstatement.link") }}
        </DashboardLink>
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>
</template>

<script setup>
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import DashboardLink from "@/components/Dashboard/Dashboard-link.vue";
import { ref, watch, computed, onMounted } from "vue";
import dates from "@/plugins/dates";
import TokenService from "@/services/storage.service";
// Destructure the `hasPermission` method from the mixin
const store = useStore();

const user = computed(() => store.getters.getMe);
const permissions = computed(() => store.getters.getPermissions);
const isStaff = ref(false);
const canSeeBox = ref(false);
const canSeeAccountStatement = ref(false);
const tenantCanViewPoints = computed(() =>
  store.getters.getTenantPermissions.includes("points")
);
watch(user, (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "staff")) {
    isStaff.value = true;
  }
  if (tenantCanViewPoints.value === true) {
    canSeeBox.value = TokenService.showElement("pointBox.see");

    canSeeAccountStatement.value = TokenService.showElement(
      "accountStatement.see"
    );
  }
});

watch(permissions, () => {
  if (tenantCanViewPoints.value === true) {
    canSeeBox.value = TokenService.showElement("pointBox.see");
    canSeeAccountStatement.value = TokenService.showElement("accountStatement.see");
  }
});

const lastUpdate = computed(() => store.getters.getLastBankStatement);

onMounted(() => {
  canSeeAccountStatement.value = TokenService.showElement(
    "accountStatement.see"
  );
})
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.points-container {
  min-height: 300px;
}

.points-nl-container {
  @media screen and (max-width: $xxxlBreakpoint) {
    min-height: 194px;
  }

  @media screen and (max-width: $xxlBreakpoint) {
    min-height: 167px;
  }

  min-height: 240px;
}
</style>
