const messages = {
  title: "Verkoopacties",
  desc: "Gebruik onze verkoopacties actief bij uw klanten. U mag de klanten uiteraard beide acties aanbieden. Lees hieronder meer over de Bandengarantie en Drive Our Best.",
  participation: {
    title: "Vraag nu deelname aan",
    desc: "Wilt u een of meer van onze acties in uw winkel implementeren? Dan kunt u hier deelneming aanvragen. Uw bedrijf wordt geactiveerd voor de actie(s) en u ontvangt binnen 4 weken promotiemateriaal voor uw verkoopdroom.<br/><br/>Maak uw keuze:",
    request: "DEELNAME AANVRAGEN",
    success: "Bedankt, uw verzoek is verzonden",
  },
};
export default {
  messages,
};
