const messages = {
  title: "Startseite",
  welcomeMessage:
    'Willkommen bei BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Startseite",
  search: "Suche",
  sortBy: "Sortieren nach",
  seeMore: "Alle anzeigen",
  bankstatement: {
    yourScore: "Ihre aktuelle Anzahl an Marketingpunkten:",
    lastUpdate: "Letztes Update:",
    link: "Übersicht über den Punktestand",
  },
  shop: {
    bestSold: "Meistgekauft",
    seasonal: "Saisonales",
    affordable: "Fast geschafft",
    forPoints: "für {points} Punkte",
    reachedPoints: "Benötigten Punktestand erreicht",
    cartRemainPoints: "Punkte verbleibend",
    remainingPoints: "{points} Punkte noch fehlend",
    noProducts: "Die Suchkriterien ergaben keine Treffer",
  },
  showAllProducts: "Zum Prämienshop",
};

export default {
  messages,
};
