const messages = {
  title: "VAŠE ZPRÁVY",
  desc: "Tu nájdete dôležité správy a novinky.",
  newMessages: "Nové správy vo vašej schránke",
  link: "Zobraziť správy",
  showMessages: "Zobraziť správy",
  message: {
    sender: "Odosielateľ",
    thread: "Predmet",
    date: "Dátum",
  },
  sender: {
    system: "BRIDGESTONE PLUS",
  },
  back: "Späť",
  time: {
    secondsAgo: "{time} sekúnd dozadu",
    minutesAgo: "{time} minút dozadu",
    hoursAgo: "{time} hodín dozadu",
    daysAgo: "{time} dní dozadu",
  },
};

export default {
  messages,
};
