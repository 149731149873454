<template>
    <CategoryTop :title="$t('pageName.favoriteProducts')" :description="$t('favoriteProducts.description')"
        :breadcrumbs-parent-routes="['account']">
    </CategoryTop>
    <Loader :show="loading" v-if="loading"></Loader>
    <div v-else>
        <MDBRow>
            <MDBCol col="12" lg="8">
                <div class="pt-2">
                    <MDBTable borderless class="points-overview">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>{{ $t("shop.order.products") }}</th>
                            </tr>
                        </thead>
                        <tbody v-if="productList.length > 0">
                            <tr v-for="(data, index) in productList" :key="index">
                                <td>
                                    <template v-if="
                                        (data.product.not_available == true && data.product.show_empty == true) ||
                                        data.product.show_empty == true
                                    ">
                                        {{ $t("shop.outOfStock") }}
                                    </template>
                                    <template v-else-if="data.product.not_available == true">
                                        {{ $t("shop.timeUnavailable") }}
                                    </template>
                                    <template v-else>
                                        <router-link :to="{
                                            name: 'productDetails',
                                            params: {
                                                id: data.product.id,
                                            },
                                        }">
                                            {{ $t("buttons.clickHere") }}
                                        </router-link>

                                    </template>
                                </td>
                                <td>
                                    {{ data.product.name }} <b>{{ data.productVariant ?
                                        showAttributes(data.productVariant.attribute_option) : '' }}</b>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <div>{{ $t("shop.order.anyOrder") }}</div>
                        </tbody>
                    </MDBTable>
                </div>
            </MDBCol>
        </MDBRow>
    </div>
</template>

<script setup>
import CategoryTop from "@/components/General/CategoryTop/index.vue";
import { MDBRow, MDBCol, MDBTable } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { onMounted, computed, ref, watch } from "vue";
import Loader from "@/components/General/Loader/index.vue";
import dates from "@/plugins/dates";
import { ProductService } from "@/services/product.service";

const store = useStore();

const user = computed(() => store.getters.getMe);
const loading = computed(() => store.getters.getPending);
const selectedProduct = ref(null);
const query = ref("");
const date = ref(null);

const productList = ref([]);

onMounted(async () => {
    const response = await ProductService.userFavoriteProducts(user.value.plainId)
    productList.value = response.data.data
});

const showAttributes = (attribute_option) => {
    return JSON.parse(attribute_option)
        .map((item) => `${item.label}: ${item.option}`)
        .join(", ");
};

const search = async () => {
    const params = [];
    selectedProduct.value
        ? params.push(`productId=${selectedProduct.value}`)
        : "";
    query.value ? params.push(`search=${query.value}`) : "";
    date.value ? params.push(`date=${dates.parseDate(date.value)}`) : "";

    let data = "?";
    for (const element of params) {
        data += `${element}&`;
    }

    await store.dispatch("loadUserOrders", data.replace(/.$/, ""));
};

watch(query, async (newVal) => {
    if (newVal.length == 0) {
        await search();
    }
});
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";

.points-overview {
    width: 100%;
    border-spacing: 3px;
    border-collapse: separate;

    td,
    th {
        padding: 5px;
        text-align: left;
    }

    th {
        background: $gray;
        color: $backgroundGray;
    }
}

.points-overview>tbody>tr:nth-of-type(2n + 1)>* {
    background-color: $secondaryGray;
}
</style>