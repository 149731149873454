const messages = {
  title: "Minu profiil",
  desc: "Tere tulemast oma isiklikule alale. Siin saate uuendada või kohandada oma <br/>andmeid ja vaadata oma postkasti.",
  descStaff:
    "Tere tulemast oma isiklikule alale. Siin saate uuendada või kohandada oma andmeid.",
  buttons: {
    showProfile: "Näita profiili",
    userProfile: "AVA PÕHIANDMED",
    mailbox: "AVA POSTKAST",
  },
  categories: {
    baseData: {
      title: "Põhiandmed",
      desc: "Täitke oma isiklikud ja ettevõtte andmed korrektseks registreerimiseks.",
    },
    mailbox: {
      desc: "Saage olulisi teateid, tellimuse kinnitus ja uudiseid oma postkasti.",
    },
    bankstatement: {
      title: "Kontoväljavõte",
      desc: "Siin saate vaadata oma isikliku Bridgestone Plus konto olekut ja kontrollida teenitud ja kasutatud preemiapunkte.",
    },
  },
  profile: {
    general: "ISIKUANDMED ETTEVÕTTE ANDMED",
    title: "Põhiandmed",
    description:
      "Tere tulemast oma isiklikule alale. Siin saate uuendada või kohandada oma andmeid.",
    block_description:
      "Tere tulemast oma isiklikku piirkonda. Siin saate vaadata oma andmeid",
    personalData: "ISIKLIKUD ANDMED",
    password: "Parool",
    password1: "Parool (valikuline)*",
    password2: "Parool (korda)*",
    formOfAddress: "Tiitel",
    choose: "Vali tiitel",
    mr: "Hr.",
    mrs: "Pr.",
    diverse: "Muu",
    firstname: "Eesnimi",
    surname: "Perekonnanimi",
    position: "AMETIKOHT ETTEVÕTTES",
    operatingData: "ETTEVÕTTE ANDMED",
    company: "Ettevõte",
    legalForm: "Õiguslik vorm",
    name: "Nimi",
    streetHouseNr: "Ettevõtte aadress",
    street: "Tänav",
    postcode: "Postiindeks",
    location: "Linn",
    country: "Riik",
    de: "Saksamaa",
    at: "Austria",
    ch: "Šveits",
    nl: "Holland",
    it: "Itaalia",
    language: "Keel",
    french: "Prantsuse",
    italian: "Itaalia",
    german: "Saksa",
    nederland: "Hollandi",
    openingHours: "Lahtiolekuajad ja kontakt",
    phone: "Telefon",
    website: "Veebisait",
    operatingIndicators: "Tegutsemisnäitajad",
    tireSale: "Aastane rehvimüük tükkides",
    tireSaleLabel: "Rehvide müük suurtes kogustes",
    inches: "Sealhulgas ≥ 18 tolli (protsentides)",
    inchesLabel: "Sealhulgas 18 tolli või rohkem protsentides",
    segmentSale:
      "Rehvimüük segmentide kaupa (Budget / Keskklass / Premium protsentides)",
    premBudget: "Premium",
    midBudget: "Keskklass",
    budBudget: "Eelarve",
    distribution: "JAOTUS SUVI / TALV / AASTARINGNE (protsentides)",
    tyreSizes: "Hooajaliste rehvide hoiukohtade arv (komplektides)",
    diffDeliveryAddres: "Lisa tarneaadress",
    yourEmailAddress: "Teie e-posti aadress.",
    clientNr: "Kliendinumbrid",
    clientNrSingular: "Kliendinumber",
    clientNrContent:
      "<span class='text-danger fw-bold'>TÄHTIS - PALUN LUGEGE:</span><br /><br /> Oma rehvide ostude korrektseks seostamiseks hulgimüüja ja/või Bridgestone'iga on vajalik, et <b>sisestate oma vastavad kliendinumbrid.</b><br/><br/>Kui teil on <b>mitu kliendinumbrit hulgimüüja ja/või Bridgestone'i juures,</b> näiteks peakontori ja filiaalide jaoks, peate esmalt sisestama peakontori kliendinumbri.<br/><br/><b>Teiste kliendinumbrite müük omistatakse seejärel teie peakontorile.</b>",
    supplySource: "Varustamisallikas",
    email: "E-posti aadress",
    staffEmail: "Osaleja e-posti aadress",
    registered: "Registreeritud",
    registeredAt: "Registreeritud",
    lastLogIn: "Viimane sisselogimine",
    details: "Detailid",
    inDetail: "{name} detailid",
    bsClientNr: "Teie Bridgestone kliendinumber",
    bftClientNr: "Teie Best4Tires kliendinumber",
    acceptTerms: "Nõustu osalemistingimustega.",
    newCustomerNumbersInfo:
      "<span class='text-danger fw-bold'>TÄHTIS - PALUN LUGEGE:</span><br />Kliendinumbri esmakordsel sisestamisel kontrollitakse teie andmeid täpsuse ja meie jaotuskriteeriumide täitmise suhtes. Kliendinumbri(d) aktiveerimise ja profiiliga sidumise otsus võib võtta kuni 5 tööpäeva.",
    selectWholeSaler: "hankija juures (palun valige)",
    cooperation: "LIIGE / KOOSTÖÖ",
    error100: "Väärtuste summa peab olema 100%.",
    errorRange: "Väärtus peab olema vahemikus 0–100",
    btw_number_label: "Teie ettevõtte KMKR-number",
    btw_number: "KMKR-number",
    mobile: "Telefoninumber",
    mobile_label: "Teie mobiiltelefoninumber",
    iban_number: "Teie ettevõtte IBAN-number",
    iban_number_label: "IBAN-number",
    yourCsNr: "Teie kliendinumbrid",
    yourLastName: "Teie perekonnanimi",
    positionLabel: "Valige ametikoht",
    yourFirstName: "Teie eesnimi",
    companyLabel: "Ettevõte",
    numberOfSetsOfSeasonalTiresBudget: "Aastane hoiustamine",
    contactInfo: "Kui teil on küsimusi, võtke meiega ühendust:",
    addAnotherCsNumber: "Lisa Bridgestone kliendinumber",
    activityAndProfile: "TEGEVUSVALDKOND JA PROFIIL",
  },
  countries: {
    al: "Albania",
    ad: "Andorra",
    at: "Austria",
    be: "Belgium",
    by: "Belarus",
    ba: "Bosnia and Herzegovina",
    bg: "Bulgaria",
    hr: "Croatia",
    cy: "Cyprus",
    cz: "Czechia",
    dk: "Denmark",
    ee: "Estonia",
    fi: "Finland",
    fr: "France",
    ge: "Georgia",
    gr: "Greece",
    es: "Spain",
    nl: "Netherlands",
    ie: "Ireland",
    is: "Iceland",
    kz: "Kazakhstan",
    xk: "Kosovo",
    li: "Liechtenstein",
    lt: "Lithuania",
    lu: "Luxembourg",
    lv: "Latvia",
    mk: "North Macedonia",
    mt: "Malta",
    md: "Moldova",
    mc: "Monaco",
    de: "Germany",
    no: "Norway",
    pl: "Poland",
    pt: "Portugal",
    ru: "Russia",
    ro: "Romania",
    sm: "San Marino",
    rs: "Serbia",
    sk: "Slovakia",
    si: "Slovenia",
    se: "Sweden",
    ch: "Switzerland",
    tr: "Turkey",
    ua: "Ukraine",
    hu: "Hungary",
    gb: "United Kingdom",
    it: "Italy",
    va: "Vatican City",
  },
};

export default {
  messages,
};
