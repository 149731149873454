const messages = {
  title: "Accueil",
  welcomeMessage:
    'Bienvenue sur BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Accueil",
  search: "Recherche",
  sortBy: "Trier par",
  seeMore: "Voir plus",
  bankstatement: {
    yourScore: "Votre nombre actuel de points marketing:",
    lastUpdate: "Dernière mise à jour:",
    link: "Aperçu du solde de points",
  },
  shop: {
    bestSold: "Les plus achetés",
    seasonal: "De saison",
    affordable: "On y est presque",
    forPoints: "pour {points} points",
    reachedPoints: "Score requis atteint",
    cartRemainPoints: "Points restants",
    remainingPoints: "{points} Points restants",
    noProducts: "Les critères de recherche n'ont donné aucun résultat",
  },
  showAllProducts: "Vers la boutique des primes",
};

export default {
  messages,
};
