const messages = {
  title: "Pārdošanas akcijas",
  desc: "Aktīvi izmantojiet mūsu pārdošanas akcijas ar saviem klientiem. Protams, jūs varat piedāvāt klientiem abas akcijas. Lasiet vairāk par Riepu garantiju un Drive Our Best zemāk.",
  participation: {
    title: "Piesakieties dalībai tūlīt",
    desc: "Vai vēlaties savā veikalā ieviest vienu vai vairākas mūsu akcijas? Tad jūs varat pieteikties dalībai šeit. Jūsu uzņēmums tiks aktivizēts akcijai(-ām), un jūs 4 nedēļu laikā saņemsiet reklāmas materiālus savam pārdošanas sapnim.<br/><br/>Izvēlieties:",
    request: "PIESAKIETIES DALĪBAI",
    success: "Paldies, jūsu pieprasījums ir nosūtīts",
  },
};
export default {
  messages,
};
