const messages = {
  title: "Shop",
  description:
    "Vítejte v obchodě s odměnami. Zde můžete vyměnit své body Bridgestone Plus <br/> za odměny z různých kategorií. Nabídka produktů <br/> je pravidelně aktualizována. Doufáme, že se vám bude procházení líbit.",
  sort: {
    asc: "Sort by: Lowest Price",
    desc: "Sort by: Highest Price",
  },
  productSearchBox: {
    from: "points from",
    to: "points to",
  },
  noProducts: "The search criteria did not yield any results",
  quantity: "Quantity",
  search: "Search",
  timeUnavailable: "Currently not available at the time",
  product: "Product",
  cart: {
    pointsPerUnit: "Points per unit",
    pointsSum: "Sum of points",
  },
  action: "Action",
  pointsTotal: "Total",
  cartRemainPoints: "Cart remain points",
  shoppingCart: "Shopping cart",
  emptyCart: "Shopping cart is empty.",
  attributes: "Attributes",
  selectAddressP: "Please select your delivery address",
  orderDelivery: "Shipping address",
  selectedProducts: "Selected products",
  orderSummary: "Order overview",
  contactPerson: "Contact person",
  selectedAddress: "Selected address",
  order: {
    success:
      "Thank you! Your order was successful. You will receive an order confirmation in your inbox in just a few minutes.",
    description: "Here you will find an overview of your reward shop orders.",
    date: "Order date",
    number: "Order no.",
    status: {
      created: "Created",
      confirm_order: "Confirm order",
      ready_for_shipment: "Ready for shipment",
      rejected: "Rejected",
      processed: "Processed",
      shipped: "Shipped",
      returned: "Returned",
      finished: "Finished",
    },
    anyOrder: "No orders",
    products: "Products",
    quantity_short: "pcs",
  },
  orders: "Orders",
  notEnoughPoints: "Not enough points available.",
};
export default {
  messages,
};
