<template>
  <MDBRow>
    <MDBCol cols="12" lg="6" xl="4" class="mb-4" v-for="(training, i) in trainings" :key="i"
      :class="`${training.show ? '' : 'd-none'}`">
      <Card :data="training" :linkText="$t('global.buttons.discover')" />
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import Card from "@/components/General/Card/index.vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { ref, computed } from "vue";
import i18n from "@/plugins/i18n";
import TokenService from "@/services/storage.service";
const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");

const trainings = ref([
  {
    name: i18n.global.t("trainings.index.online.title"),
    link: { name: "onlineTrainings" },
    description: i18n.global.t("trainings.index.online.desc"),
    image: require("@/assets/img/online-trainings-poster.png"),
    show: TokenService.showElement("onlineTraining.see"),
  },
  {
    name: i18n.global.t("trainings.index.lectures.title"),
    link: { name: "bridgestoneSchulungen" },
    description: i18n.global.t("trainings.index.lectures.desc"),
    image: require("@/assets/img/schulungen-poster.png"),
    show: TokenService.showElement("bTraining.see") && isGermany.value,
  },
  {
    name: i18n.global.t("trainings.index.external.title"),
    link: { name: "externeTraining" },
    description: i18n.global.t("trainings.index.external.desc"),
    image: require("@/assets/img/externe-schulungen-poster.png"),
    show: TokenService.showElement("externalTraining.see"),
  },
]);
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";

.training {
  background: linear-gradient(142deg,
      rgba(205, 205, 205, 1) 0%,
      rgba(97, 97, 97, 1) 100%);
  min-height: 200px;
}

.training-title {
  color: #000000;
}

.training-link {

  &:hover,
  &:visited,
  &:active {
    color: white;
  }
}

.training-description {
  min-height: 92px;
}
</style>
