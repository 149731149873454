import global from "@/locales/en-GB/global";
import error403 from "@/locales/en-GB/errors/403";
import error404 from "@/locales/en-GB/errors/404";
import error500 from "@/locales/en-GB/errors/500";
import error503 from "@/locales/en-GB/errors/503";
import error504 from "@/locales/en-GB/errors/504";
import maintenance from "@/locales/en-GB/auth/maintenance";
import bankstatement from "@/locales/en-GB/account/bankstatement";
import contactService from "@/locales/en-GB/about/contactService";
import salesPromotions from "@/locales/en-GB/sales/promotions";
import dashboard from "@/locales/en-GB/dashboard/dashboard";
import staff from "@/locales/en-GB/dashboard/staff";
import shop from "@/locales/en-GB/shop/data";

//prettier-ignore
const messages = {
  global: global.messages,
  error403: error403.messages,
  error404: error404.messages,
  error500: error500.messages,
  error503: error503.messages,
  error504: error504.messages,
  maintenance: maintenance.messages,
  bankstatement: bankstatement.messages,
  contactService: contactService.messages,
  salesPromotions: salesPromotions.messages,
  shop: shop.messages,
  dashboard: dashboard.messages,
  staff: staff.messages
}

export default {
  locale: "en-GB",
  messages,
};
