// src/composables/useProfileFieldCheck.js
export function useProfileFieldShow(tenantConfig) {
  const showProfileField = (fieldName) => {
    return (
      tenantConfig.value?.profileFieldsExtraShow?.includes(fieldName) || false
    );
  };

  return {
    showProfileField,
  };
}

export function useProfileFieldHide(tenantConfig) {
  const showProfileField = (fieldName) => {
    return (
      tenantConfig.value?.profileFieldsExtraHide?.includes(fieldName) || false
    );
  };

  return {
    showProfileField,
  };
}

export function useProfileFieldRequired(tenantConfig) {
  const profileFieldRequired = (fieldName) => {
    return (
      tenantConfig.value?.requiredProfileFields?.includes(fieldName) || false
    );
  };

  return {
    profileFieldRequired,
  };
}

export function useRequiredOnboardingSection(tenantConfig) {
  const requiredOnboardSection = (section) => {
    return tenantConfig.value?.requiredOnboardSteps?.includes(section) || false;
  };

  return {
    requiredOnboardSection,
  };
}

export function useAllowDuplicatedCustomerNumber(tenantConfig) {
  const allowDuplicatedCustomerNumber = () => {
    return tenantConfig.value?.allowDuplicatedCustomerNumber || false;
  };

  return {
    allowDuplicatedCustomerNumber,
  };
}

export function useAllowOnboard(tenantConfig) {
  const allowOnboard = () => {
    return tenantConfig.value?.allowOnboard || false;
  };

  return {
    allowOnboard,
  };
}

export function setLinkInWelcomeBox(tenantConfig) {
  const welcomeBoxLink = () => {
    return tenantConfig.value?.linkInWelcomeBox || "stammdaten";
  };

  return {
    welcomeBoxLink,
  };
}
