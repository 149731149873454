const messages = {
  makeChoice: "Wybierz",
  seeAll: "Zobacz wszystko",
  clear: "Wyczyść",
  back: "Wróć",
  save: "Zapisz",
  close: "Zamknij",
  of: "z",
  to: "do",
  copyrights: "© {date} Bridgestone Solutions. Wszelkie prawa zastrzeżone.",
  pageNotFound: "Strona nie znaleziona",
  accessDenied: "Dostęp zabroniony",
  search: "Szukaj",
  buttons: {
    export: "Eksportuj",
    delete: "Usunąć",
    save: "Zapisz",
    cancel: "Anuluj",
    next: "Dalej",
    back: "Wróć",
    clear: "Wyczyść",
    skip: "Pomiń",
    send: "Zapisz",
    close: "Zamknij",
    readNow: "Czytaj więcej",
    pointsList: "Lista punktów",
    more: "Więcej",
    reportError: "Zgłoś błąd",
    accept: "Akceptuj",
    download: "Pobierz",
    dealerInfoDownload: "Pobierz",
    toTheBasket: "Koszyk",
    toOrderDelivery: "Adres dostawy",
    discover: "Sprawdź",
    newAddress: "Nowy adres dostawy",
    selectAddressBtn: "Wybierz adres dostawy",
    toOrderSummary: "Podusmowanie",
    placeOrder: "Złóz zamówienie",
    toDashboard: "Strona gówna",
    newOrder: "New order",
    userOrders: "To your orders",
    toSearch: "Search",
    select: "Select",
  },
  tooltips: {
    info: "Potrzebujesz pomocy? +48 22 393 3791",
  },
  confirmation: {
    header: "Wymagane potwierdzenie",
    message: "Czy chcesz usunąć ten wpis?",
  },
  nav: {
    dashboard: "Panel",
    account: "Mój Profil",
    mailbox: "Skrzynka odbiorcza",
    singleMailbox: "Wiadomości",
    stammdaten: "Dane główne",
    news_feeds: "Aktualności",
    pointsOverview: "Saldo punktów",
    about: "O BS Plus",
    service_number: "Contact service",
    shop: "Bridgestone <span class='text-danger'>PLUS</span> sklep",
    salesPromotions: "Promocja sprzedazy",
    shopCategory: "Sklep",
    productDetails: "Szczegóły porduktu",
    userOrders: "Zamówienia",
    shoppingCart: "Koszyk",
    orderDelivery: "Ades dostawy",
    orderSummary: "Podgląd zamówienia",
    orderComplete: "Order complete",
  },
  menu: {
    account: "Mój Profil",
    plus: 'O Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Wsparcie sprzedaży",
    mailbox: "Skrzynka odbiorcza",
    about: "O BS Plus",
    trainingAndCourses: "Szkolenia i kursy",
    externalTraining: "Szkolenia zewnętrzne",
    productInfo: "Informacje o produktach",
    productBrochures: "Broszury produktowe",
    priceLists: "Cenniki",
    price: "Cena",
    complaintForm: "Formularz reklamacyjny",
    sales_support: "Wsparcie sprzedaży",
    contact_service: "Informacje kontaktowe i serwis",
    logOut: "Wyloguj się",
    news: "Aktualności",
    news_feeds: "Aktualności",
    service_number: "Contact service",
    salesPromotions: "Promocja sprzedazy",
    shop: "Sklep",
  },
  validation: {
    requiredField: "Pole wymagane",
  },
};

export default {
  messages,
};
