const messages = {
  title: 'Bienvenue sur BRIDGESTONE <span class="text-danger">PLUS</span>',
  termsAndConditions: "Conditions générales",
  accept: "Accepter",
  decline: "Décliner",
  dataModal:
    "Pour pouvoir adapter cette offre à vos besoins, nous vous demandons de bien vouloir vous identifier et indiquer vos centres d'intérêt en matière de produits ou de secteurs d'activité.",
  scrollToAccept:
    "Veuillez faire défiler le texte vers le bas pour accepter nos conditions et continuer",
  welcomeMessage: "Bienvenue sur Bridgestone Plus",
  tailorOffer:
    "Pour pouvoir adapter cette offre à vos besoins, nous vous demandons de bien vouloir indiquer brièvement vos centres d'intérêt en matière de produits ou de secteurs d'activité.",
  bussinessType:
    "Pour pouvoir adapter cette offre à vos besoins, nous vous demandons de bien vouloir indiquer brièvement vos centres d'intérêt en matière de produits ou de secteurs d'activité.",
  selectAreas: "Veuillez choisir parmi les propositions suivantes*",
  multipleAnswers: "(plusieurs réponses possibles)",
  personalPreferences: "Préférences Personnelles",
  carTyres: "Pneus voiture, SUV, utilitaire",
  motorcyclesTyres: "Pneus de motos",
  truckBusTyres: "Pneus bus et camion",
  agricultureTyres: "Pneus pour véhicules agricoles",
  otrTyres: "Pneus génie civil",
  save: "Sauvegarder",
  businessRelationship:
    "Quelle est votre relation commerciale avec Bridgestone ?",
  selectRelationship: "Veuillez choisir* parmi les propositions suivantes",
  tradingPartner: "Je suis un partenaire commercial qui",
  tradingPartnerDirect: "... commande directement auprès de Bridgestone",
  tradingPartnerIndirect:
    "… passe commande indirectement (via un grossiste, par exemple)",
  tradingPartnerBoth: "... commande à la fois directement et indirectement",
  fleetResponsibleDirect: "Je suis un responsable de flotte qui...",
  fleetResponsibleIndirect:
    "... passe commande indirectement (via un distributeur ou un grossiste)",
  fleetResponsibleBoth: "... commande à la fois directement et indirectement",
  noBusinessRelationship:
    "<b>Je n’ai pas de relation commerciale avec Bridgestone </b>(ni directement ni indirectement)",
  yourCustomerNumber: "Votre numéro de client Bridgestone* :",
  enterCustomerNumber: "Veuillez entrez votre numéro de client",
  waitPersonalisedView:
    "Veuillez patienter un instant, votre vue personnalisée de BRIDGESTONE PLUS sera générée.",
  personalised: "Personnalisé",
  plusInfo:
    "Vous trouverez ici des informations sur votre compte et votre boîte de réception Plus",
  continue: "Continuer",
  skip: "Passer",
  oneOfTwo: "1 sur 2",
  openApps:
    "Vous pouvez ouvrir directement les applications pour lesquelles vous êtes déjà enregistré. Vous pouvez également demander l'accès à d'autres applications susceptibles de vous intéresser.",
  requiredFields:
    "Remplissez les champs obligatoires: Titre de civilité, Prénom, Nom de famille",
  stage: "étape",
  chooseOne: "(Veuillez cocher au moins 1 case)",
  required: "*champs obligatoires",
  lengthCsNumber: "Longueur du numéro de client: 6-10 chiffres",
  waitForAdminApproval:
    "Merci de vous être inscrit au programme Bridgestone Plus Rewards. Nous examinons actuellement votre candidature. Vous recevrez un e-mail de confirmation une fois votre demande approuvée.",
};

export default {
  messages,
};
