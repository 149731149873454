const messages = {
  title: "Můj profil",
  desc: "Vítejte na své osobní stránce. Zde můžete aktualizovat nebo upravit své <br/>údaje a zobrazit si svou schránku.",
  descStaff:
    "Vítejte ve své osobní oblasti. Zde můžete aktualizovat nebo upravit své údaje.",
  buttons: {
    showProfile: "Zobrazit profil",
    userProfile: "PŘEJÍT NA ZÁKLADNÍ DATA",
    mailbox: "PŘEJÍT DO SCHRÁNKY",
  },
  categories: {
    baseData: {
      title: "Základní data",
      desc: "Vyplňte své osobní a firemní údaje pro správnou registraci.",
    },
    mailbox: {
      desc: "Přijímejte důležité zprávy, potvrzení objednávek a novinky do své schránky.",
    },
    bankstatement: {
      title: "Výpis z účtu",
      desc: "Zde můžete zobrazit stav svého osobního účtu Bridgestone Plus a kontrolovat své získané a použité odměnové body.",
    },
  },
  profile: {
    general: "OSOBNÍ ÚDAJE ÚDAJE O SPOLEČNOSTI",
    title: "Základní data",
    description:
      "Vítejte ve své osobní oblasti. Zde můžete aktualizovat nebo upravit své údaje.",
    block_description:
      "Vítejte ve své osobní oblasti. Zde si můžete prohlédnout své podrobnosti",
    personalData: "OSOBNÍ ÚDAJE",
    password: "Heslo",
    password1: "Heslo (volitelné)*",
    password2: "Heslo (zopakujte)*",
    formOfAddress: "Oslovení",
    choose: "Vyberte oslovení",
    mr: "Pan",
    mrs: "Paní",
    diverse: "Jiné",
    firstname: "Jméno",
    surname: "Příjmení",
    position: "POZICE VE SPOLEČNOSTI",
    operatingData: "ÚDAJE O SPOLEČNOSTI",
    company: "Společnost",
    legalForm: "Právní forma",
    name: "Název",
    streetHouseNr: "Adresa společnosti",
    street: "Ulice",
    postcode: "PSČ",
    location: "Město",
    country: "Země",
    de: "Německo",
    at: "Rakousko",
    ch: "Švýcarsko",
    nl: "Nizozemsko",
    it: "Itálie",
    language: "Jazyk",
    french: "Francouzština",
    italian: "Italština",
    german: "Němčina",
    nederland: "Nizozemština",
    openingHours: "Otevírací doba & kontakt",
    phone: "Telefon",
    website: "Webové stránky",
    operatingIndicators: "Provozní ukazatele",
    tireSale: "Roční prodej pneumatik cca (v kusech)",
    tireSaleLabel: "Prodej pneumatik ve velkém objemu",
    inches: "Z toho ≥ 18 palců (v %)",
    inchesLabel: "Z toho 18 palců nebo více v %",
    segmentSale:
      "Prodej pneumatik podle segmentu (podíl Economy / Střední / Premium v %)",
    premBudget: "Premium",
    midBudget: "Střední",
    budBudget: "Economy",
    distribution: "ROZLOŽENÍ LÉTO / ZIMA / CELOROČNÍ (v %)",
    tyreSizes: "Počet sezónních skladovacích míst (sady pneumatik)",
    diffDeliveryAddres: "Přidat doručovací adresu",
    yourEmailAddress: "Vaše e-mailová adresa.",
    clientNr: "Zákaznická čísla",
    clientNrSingular: "Zákaznické číslo",
    clientNrContent:
      "<span class='text-danger fw-bold'>DŮLEŽITÉ - ČTĚTE PROSÍM:</span><br /><br /> Pro správné přiřazení vašich nákupů pneumatik u velkoobchodníka a/nebo Bridgestone je nutné, abyste <b>uvedli svá příslušná zákaznická čísla.</b><br/><br/>Pokud máte <b>více zákaznických čísel u velkoobchodníka a/nebo Bridgestone,</b> např. pro hlavní prodejnu a pobočky, nejprve uveďte zákaznické číslo hlavní prodejny.<br/><br/><b>Prodeje z ostatních zákaznických čísel pak budou přičteny vaší hlavní prodejně.</b>",
    supplySource: "Zdroj zásobování",
    email: "E-mailová adresa",
    staffEmail: "E-mailová adresa účastníka",
    registered: "Registrováno dne",
    registeredAt: "Registrováno dne",
    lastLogIn: "Poslední přihlášení",
    details: "Podrobnosti",
    inDetail: "{name} v detailu",
    bsClientNr: "Vaše zákaznické číslo Bridgestone",
    bftClientNr: "Vaše zákaznické číslo Best4Tires",
    acceptTerms: "Přijmout podmínky účasti.",
    newCustomerNumbersInfo:
      "<span class='text-danger fw-bold'>DŮLEŽITÉ - ČTĚTE PROSÍM:</span><br />Při prvním zadání zákaznického čísla budou vaše údaje ověřeny z hlediska správnosti a splnění našich distribučních kritérií. Rozhodnutí o aktivaci zákaznického čísla/čísel a jejich propojení s vaším profilem může trvat až 5 pracovních dní.",
    selectWholeSaler: "u dodavatele (prosím vyberte)",
    cooperation: "ČLENSTVÍ / SPOLUPRÁCE",
    error100: "Součet hodnot musí být roven 100%.",
    errorRange: "Hodnota musí být mezi 0 a 100",
    btw_number_label: "Vaše DIČ společnosti",
    btw_number: "DIČ",
    mobile: "Telefonní číslo",
    mobile_label: "Vaše mobilní číslo",
    iban_number: "IBAN vaší společnosti",
    iban_number_label: "IBAN číslo",
    yourCsNr: "Vaše zákaznická čísla",
    yourLastName: "Vaše příjmení",
    positionLabel: "Vyberte pozici",
    yourFirstName: "Vaše jméno",
    companyLabel: "Společnost",
    numberOfSetsOfSeasonalTiresBudget: "Roční skladování",
    contactInfo: "Pokud máte dotazy, kontaktujte nás prosím:",
    addAnotherCsNumber: "Přidat zákaznické číslo Bridgestone",
    activityAndProfile: "OBCHODNÍ AKTIVITY / PROFIL",
  },
  countries: {
    al: "Albania",
    ad: "Andorra",
    at: "Austria",
    be: "Belgium",
    by: "Belarus",
    ba: "Bosnia and Herzegovina",
    bg: "Bulgaria",
    hr: "Croatia",
    cy: "Cyprus",
    cz: "Czechia",
    dk: "Denmark",
    ee: "Estonia",
    fi: "Finland",
    fr: "France",
    ge: "Georgia",
    gr: "Greece",
    es: "Spain",
    nl: "Netherlands",
    ie: "Ireland",
    is: "Iceland",
    kz: "Kazakhstan",
    xk: "Kosovo",
    li: "Liechtenstein",
    lt: "Lithuania",
    lu: "Luxembourg",
    lv: "Latvia",
    mk: "North Macedonia",
    mt: "Malta",
    md: "Moldova",
    mc: "Monaco",
    de: "Germany",
    no: "Norway",
    pl: "Poland",
    pt: "Portugal",
    ru: "Russia",
    ro: "Romania",
    sm: "San Marino",
    rs: "Serbia",
    sk: "Slovakia",
    si: "Slovenia",
    se: "Sweden",
    ch: "Switzerland",
    tr: "Turkey",
    ua: "Ukraine",
    hu: "Hungary",
    gb: "United Kingdom",
    it: "Italy",
    va: "Vatican City",
  },
};

export default {
  messages,
};
