<template>
  <MDBCard class="mb-3 mb-lg-4 welcome--main welcome__height" :class="[
    `${user?.commitment_letter_accept && user?.intro == 1 ? 'intro__step' : ''
    }`,
    props.boxHeight,
  ]">
    <WelcomeBoxContentLV />
  </MDBCard>
  <MailBox />
</template>

<script setup>
import { MDBCard } from "mdb-vue-ui-kit";
import MailBox from "@/components/Dashboard/MailBoxLV.vue";
import WelcomeBoxContentLV from "@/components/Dashboard/WelcomeBoxContentLV.vue";
import { useStore } from "vuex";
import { computed, watch, ref } from "vue";
import { defineProps } from "vue";

const props = defineProps({
  boxHeight: { type: String, default: "welcome__height" },
});
const store = useStore();
const meIsPending = computed(() => store.getters.getIsMePending);
if (!meIsPending.value) {
  store.dispatch("me");
}

const user = computed(() => store.getters.getMe);

const isWholesaler = ref(false);
const isWholesalerStaff = ref(false);
const isStaff = ref(false);

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesalerStaff")) {
    isWholesalerStaff.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "staff")) {
    isStaff.value = true;
  }
});
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";
@import "@/styles/welcome_box.scss";
@import "@/styles/mailbox.scss";

:deep(.card-title) {
  font-size: 20px;

  @media screen and (min-width: $xxlBreakpoint) {
    font-size: 25px;
  }
}

.message-notification {
  background: #f2790d;
  color: white;
  display: inline-block;
  border-radius: 25px;
  width: 18px;
  height: 18px;
  font-size: 12px;
  text-align: center;
  margin-left: 5px;
}

.welcome__height,
.mailbox__height {
  max-height: 300px;
}

.title {
  position: relative;
}
</style>
