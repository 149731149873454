const messages = {
  title: "Home",
  welcomeMessage:
    'Welkom bij BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Home",
  search: "Zoeken",
  sortBy: "Sorteren op",
  seeMore: "Meer weergeven",
  bankstatement: {
    yourScore: "Uw huidige aantal marketingpunten:",
    lastUpdate: "Laatste update:",
    link: "Overzicht puntensaldo",
  },
  shop: {
    bestSold: "Meest verkocht",
    seasonal: "Seizoensgebonden",
    affordable: "Ontdek",
    forPoints: "{points} punten",
    reachedPoints: "Voldoende punten beschikbaar",
    cartRemainPoints: "Resterende punten",
    remainingPoints: "Nog {points} punten nodig",
    noProducts: "De zoekcriteria leverden geen treffers op",
  },
  showAllProducts:
    "Naar de Bridgestone <span class='text-danger'>Plus</span> shop",
};

export default {
  messages,
};
