const messages = {
  title: "Mina profil",
  desc: "Välkommen till ditt personliga område. Här kan du uppdatera eller justera dina <br/>masterdata och se din inkorg.",
  descStaff:
    "Välkommen till ditt personliga område. Här kan du uppdatera eller justera dina stamdata.",
  buttons: {
    showProfile: "Visa profil",
    userProfile: "Gå till Masterdata",
    mailbox: "Gå till inkorgen",
  },
  categories: {
    baseData: {
      title: "Masterdata",
      desc: "Fyll i dina person- och företagsuppgifter för en lyckad registrering.",
    },
    mailbox: {
      desc: "Här hittar du viktiga meddelanden och nyheter.",
    },
    bankstatement: {
      title: "Mina poäng",
      desc: "Här kan du kontrollera statusen på ditt personliga Bridgestone Plus-konto och se dina insamlade och använda belöningspoäng.",
    },
  },
  profile: {
    general: "Personuppgifter Företagsuppgifter",
    title: "Mis Masterdata",
    description:
      "Välkommen till ditt personliga område. Här kan du uppdatera eller justera dina <br/>masterdata och se din inkorg.",
    block_description:
      "Välkommen till ditt personliga område. Här kan du se dina uppgifter",
    personalData: "Personuppgifter",
    password: "Lösenord",
    password1: "Lösenord (valfritt)*",
    password2: "Lösenord (upprepa)*",
    formOfAddress: "Titel",
    choose: "Välj titel",
    mr: "Herr",
    mrs: "Fru",
    diverse: "Annat",
    firstname: "Förnamn",
    surname: "Efternamn",
    position: "Arbetsposition",
    operatingData: "Företagsuppgifter",
    company: "Företag",
    legalForm: "Juridisk form",
    name: "Namn",
    streetHouseNr: "Gatuadres och husnummer",
    street: "Gatu",
    postcode: "Postnummer",
    location: "Stad",
    country: "Land",
    de: "Tyskland",
    at: "Österrike",
    ch: "Schweiz",
    nl: "Nederländerna",
    it: "Italien",
    language: "Språk",
    french: "Franska",
    italian: "Italienska",
    german: "Tyska",
    nederland: "Nederländska",
    openingHours: "Öppettider & kontakt",
    phone: "Telefon",
    website: "Webbplats",
    operatingIndicators: "Nyckeltal för verksamheten",
    tireSale: "Årlig försäljning av däck i styck",
    tireSaleLabel: "Försäljning av stora mängder däck",
    inches: "Varav ≥ 18 tum (i %)",
    inchesLabel: "Varav 18 tum eller mer i %",
    segmentSale:
      "Däckförsäljning efter segment (andel budget / mellan / premium i %)",
    premBudget: "Premium",
    midBudget: "Mellan",
    budBudget: "Budget",
    distribution: "FÖRDELNING SOMMAR / VINTER / HELÅR (i %)",
    tyreSizes: "Antal säsongslagrade däcksatser",
    diffDeliveryAddres: "Lägg till en leveransadress",
    yourEmailAddress: "Din e-postadress",
    clientNr: "Kundnummer",
    clientNrSingular: "Kundenummer",
    clientNrContent:
      "<span class='text-danger fw-bold'>VIKTIGT - LÄS:</span><br /><br /> För korrekt hantering av dina däckbeställningar hos en grossist och/eller Bridgestone måste du <b>ange dina respektive kundnummer.</b><br/><br/>Om du <b>har flera kundnummer hos en grossist och/eller Bridgestone,</b> till exempel för din huvudbutik och filialer, ange först huvudbutikens kundnummer.<br/><br/><b>Försäljningen av de andra kundnumren kopplas sedan till huvudbutiken.</b>",
    supplySource: "Leverantör",
    email: "E-postadress",
    staffEmail: "Deltagarens e-postadress",
    registered: "Registrerad den",
    registeredAt: "Registrerad den",
    lastLogIn: "Senaste inloggning",
    details: "Detaljer",
    inDetail: "{name} i detalj",
    bsClientNr: "Ditt Bridgestone-kundnummer",
    bftClientNr: "Ditt Best4Tires-kundnummer",
    acceptTerms: "Acceptera deltagarvillkor.",
    newCustomerNumbersInfo:
      "<span class='text-danger fw-bold'>VIKTIGT - LÄS:</span><br />Vid första inmatningen av kundnummer kontrolleras dina uppgifter för noggrannhet och att våra distributionskriterier uppfylls. Beslut om aktivering av kundnummer och koppling till din profil kan ta upp till 5 arbetsdagar.",
    selectWholeSaler: "hos leverantören (vänligen välj)",
    cooperation: "MEDLEM / SAMARBETE",
    error100: "Summan av värdena måste vara 100%.",
    errorRange: "Värdet måste vara mellan 0 och 100",
    btw_number_label: "ditt företags momsregistreringsnummer",
    btw_number: "Momsregistreringsnummer",
    mobile: "Telefonnummer",
    mobile_label: "Ditt mobilnummer",
    iban_number: "Ditt företags IBAN",
    iban_number_label: "IBAN-nummer",
    yourCsNr: "Dina kundnummer",
    yourLastName: "Ditt efternamn",
    positionLabel: "Välj en position",
    yourFirstName: "Ditt förnamn",
    companyLabel: "Företag",
    numberOfSetsOfSeasonalTiresBudget: "Lagring per år",
    contactInfo: "Om du har frågor, vänligen kontakta oss:",
    addAnotherCsNumber: "Lägg till ytterligare ett Bridgestone-kundnummer",
    activityAndProfile: "VERKSAMHET OCH PROFIL",
  },
  countries: {
    al: "Albania",
    ad: "Andorra",
    at: "Austria",
    be: "Belgium",
    by: "Belarus",
    ba: "Bosnia and Herzegovina",
    bg: "Bulgaria",
    hr: "Croatia",
    cy: "Cyprus",
    cz: "Czechia",
    dk: "Denmark",
    ee: "Estonia",
    fi: "Finland",
    fr: "France",
    ge: "Georgia",
    gr: "Greece",
    es: "Spain",
    nl: "Netherlands",
    ie: "Ireland",
    is: "Iceland",
    kz: "Kazakhstan",
    xk: "Kosovo",
    li: "Liechtenstein",
    lt: "Lithuania",
    lu: "Luxembourg",
    lv: "Latvia",
    mk: "North Macedonia",
    mt: "Malta",
    md: "Moldova",
    mc: "Monaco",
    de: "Germany",
    no: "Norway",
    pl: "Poland",
    pt: "Portugal",
    ru: "Russia",
    ro: "Romania",
    sm: "San Marino",
    rs: "Serbia",
    sk: "Slovakia",
    si: "Slovenia",
    se: "Sweden",
    ch: "Switzerland",
    tr: "Turkey",
    ua: "Ukraine",
    hu: "Hungary",
    gb: "United Kingdom",
    it: "Italy",
    va: "Vatican City",
  },
};

export default {
  messages,
};
