const messages = {
  title: "Etusivu",
  welcomeMessage:
    'Tervetuloa BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Etusivu",
  search: "Etsi",
  sortBy: "Lajittelu",
  seeMore: "Katso lisää",
  bankstatement: {
    yourScore: "Nykyinen markkinointipisteesi määrä:",
    lastUpdate: "Viimeisin päivitys:",
    link: "Pistetasapainon yleiskatsaus",
  },
  shop: {
    bestSold: "Eniten myyvät",
    seasonal: "Kausikohtaiset",
    affordable: "Edulliset",
    forPoints: "kun {points} pisteellä",
    reachedPoints: "Vaadittu pistemäärä saavutettu",
    cartRemainPoints: "Jäljellä olevat pisteet",
    remainingPoints: "Puuttuu vielä {points} pistettä",
    noProducts: "Tuloksia ei löytynyt",
  },
  showAllProducts: "Mene kauppaan",
};

export default {
  messages,
};
