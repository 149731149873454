const messages = {
  title: "Müügikampaaniad",
  desc: "Kasutage aktiivselt meie müügikampaaniaid oma klientidega. Loomulikult võite klientidele pakkuda mõlemat kampaaniat. Lugege lähemalt Rehvigarantiist ja Drive Our Best'ist allpool.",
  participation: {
    title: "Taotle osalemist kohe",
    desc: "Kas soovite oma poes rakendada ühte või mitut meie kampaaniat? Seejärel saate siin taotleda osalemist. Teie ettevõte aktiveeritakse kampaania(de) jaoks ja te saate 4 nädala jooksul reklaammaterjale oma müügiunistuse jaoks.<br/><br/>Tehke oma valik:",
    request: "TAOTLE OSALEMIST",
    success: "Täname, teie taotlus on saadetud",
  },
};
export default {
  messages,
};
