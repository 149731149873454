const messages = {
  title: "Promocje Sprzedaży",
  desc: "Aktywnie wykorzystuj nasze promocje sprzedażowe w kontaktach z klientami. Oczywiście możesz oferować klientom obie promocje. Przeczytaj więcej o Gwarancji Opon i Drive Our Best poniżej.",
  participation: {
    title: "Złóż wniosek o uczestnictwo teraz",
    desc: "Czy chcesz wdrożyć jedną lub więcej naszych promocji w swoim sklepie? W takim razie możesz złożyć wniosek o uczestnictwo tutaj. Twoja firma zostanie aktywowana do udziału w promocji/promocjach, a Ty otrzymasz materiały promocyjne w ciągu 4 tygodni, materiały promocyjne do realizacji Twojego marzenia o sprzedaży.<br/><br/>Dokonaj wyboru:",
    request: "ZŁÓŻ WNIOSEK O UCZESTNICTWO",
    success: "Dziękuję, Twoje zgłoszenie zostało wysłane",
  },
};
export default {
  messages,
};
