const messages = {
  title: "Acasă",
  welcomeMessage:
    'Bine ați venit la  BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Acasă",
  search: "Căutare",
  sortBy: "Sortare după",
  seeMore: "Vedeți mai multe",
  bankstatement: {
    yourScore: "Numărul dvs. actual de puncte de marketing:",
    lastUpdate: "Ultima actualizare:",
    link: "Prezentare generală a echilibrului de puncte",
  },
  shop: {
    bestSold: "Cele mai bine vândute",
    seasonal: "Sezonale",
    affordable: "La prețuri accesibile",
    forPoints: "pentru {points} puncte",
    reachedPoints: "S-a atins numărul necesar de puncte",
    cartRemainPoints: "Puncte rămase",
    remainingPoints: "Mai sunt necesare {points} puncte",
    noProducts: "Criteriile de căutare nu au returnat niciun rezultat",
  },
  showAllProducts: "Du-te la magazin",
};

export default {
  messages,
};
