<template>
  <MDBCol cols="12" md="5" v-if="!isSuccess">
    <div class="fw-bold pb-2">{{ $t("externeTraining.form.title") }}</div>
    <VeeForm class="form user-form" @submit="onSubmit">
      <div class="fw-bold pb-3">
        {{ $t("hunter.form.title") }}&nbsp;HUNTER {{ hunter.title }}
      </div>
      <div class="pb-4">{{ $t("hunter.form.description") }}</div>
      <div class="fw-bold pb-2">{{ $t("hunter.form.subtitle") }}:</div>
      <div>
        <label for="points" class="label px-3 text-uppercase">{{
          $t("bankstatement.points")
          }}</label>
        <Field type="number" class="form-control" :placeholder="$t('bankstatement.points')"
          :aria-label="$t('bankstatement.points')" aria-describedby="basic-addon1" v-model="setPoints" name="setPoints"
          :rules="{ min_value: 10, max_value: maxPoints, required: true }" :label="$t('bankstatement.points')"
          id="points" />
        <ErrorMessage name="setPoints" class="text-danger text-small text-end m-0 pt-1" />
      </div>
      <div>
        {{
          $t("hunter.pointDiscount", {
            price: calculatePriceDiscount,
          })
        }}
      </div>

      <div class="pt-3">
        <MDBBtn type="submit" color="danger" :disabled="loading"
          class="d-flex justify-content-center align-items-center"><span>{{ $t("buttons.sendRequest") }}</span>
          <Loader :show="loading" :small="true" spinner="spinner.svg" :tiny="true" />
        </MDBBtn>
      </div>
      <p v-if="apiError" class="text-danger text-small text-end m-0 pt-2 text-small">
        {{ apiError }}
      </p>
    </VeeForm>
  </MDBCol>
  <Success v-else />
</template>

<script setup>
import Loader from "@/components/General/Loader/index";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import Success from "./Success.vue";
import { MDBCol, MDBBtn } from "mdb-vue-ui-kit";
import { ref, computed } from "vue";
import { useStore } from "vuex";
const store = useStore();

const user = computed(() => store.getters.getMe);
const maxPoints = computed(() => user.value.points);
const hunter = computed(() => store.getters.getSingleHunter);
const setPoints = ref(0);
const isSuccess = ref(false);
const apiError = ref(null);

const loading = computed(() => store.getters.getPending);

const calculatePriceDiscount = computed(() => {
  return Math.round(setPoints.value * 0.25);
});

const onSubmit = async () => {
  apiError.value = null;
  const data = {
    user_id: user.value.id,
    points: setPoints.value,
    hunter_detail_id: hunter.value.id,
    price: hunter.value.price - calculatePriceDiscount.value,
  };

  const response = await store.dispatch("storeHunterProduct", data);

  if (response?.status == 200) {
    await store.dispatch("me");
    isSuccess.value = true;
  } else {
    apiError.value = response.data.message;
  }
};
</script>
