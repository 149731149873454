const messages = {
  titleColorRev: "true",
  buttons: {
    pending: "Ausstehend",
    open: "Öffnen",
    requestAccess: "Zugang anfragen",
  },
  myBsApps: '<span class="text-danger">Meine Apps</span> Bridgestone',
  my: "Meine Apps",
  directRoute: "Ihre direkten Links zu allen Bridgestone Apps.",
  welcomeMessage:
    'Willkommen bei BRIDGESTONE PLUS <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  showProfile: "Profil ansehen",
  newMessages: "Neue Nachricht im Postfach",
  myApps: "Meine Apps",
  allApps: "Alle Apps",
  myAppsDescription: "alle Bridgestone Apps für die Sie registriert sind.",
  allAppsDescription:
    "alle Bridgestone Apps, die für Sie interessant sein könnten.",
  myProfile: {
    welcomeMessage:
      "Willkommen in Ihrem persönlichen Bereich. Hier können Sie Ihre Daten aktualisieren oder anpassen und Ihren Posteingang einsehen.",
    masterData: "Stammdaten",
    completeDetails:
      "Vervollständigen Sie Ihre persönlichen und Unternehmensdaten, um eine korrekte Registrierung zu gewährleisten.",
    goToMasterData: "Zu meinen Stammdaten",
    goToInbox: "Zu meinem Postfach",
  },
  masterData: {
    welcomeMessage:
      "Willkommen in Ihrem persönlichen Bereich. Hier können Sie Ihre Daten aktualisieren oder ändern.",
    personalDetails: "Persönliche Daten",
    companyDetails: "Unternehmensdaten",
    emailAddress: "Emailadresse",
    title: "Ansprache",
    surname: "Nachname",
    firstName: "Vorname",
    jobPosition: "Nombre",
    streetAndHouseNumber: "Straße und Hausnummer",
    postalCode: "Postleitzahl",
    city: "Stadt",
    country: "Land",
    legalStructure: "Juristische Struktur",
    customerNumber: "Bridgestone Kundennummer",
    importantPleaseRead: "Wichtig, bitte lesen",
    customerNumberInfo:
      "Um Ihre Reifenbestellungen den verschiedenen Händlern korrekt zuordnen zu können, müssen Sie unbedingt Ihre Kundennummer angeben.",
    multipleCustomerNumbers:
      "Wenn Sie mehrere Kundennummern bei einem Vertriebspartner haben, z. B. für Ihr Hauptgeschäft und Ihre Filialen, geben Sie zuerst die Kundennummer des Hauptgeschäfts an.",
    salesAssignment:
      "Die Umsätze, die mit den zusätzlichen Kundennummern verbunden sind, werden Ihrem Hauptgeschäft zugerechnet.",
    supplier: "Lieferant",
    addAnotherCustomerNumber: "Kundennummer hinzufügen",
    save: "Speichern",
    delete: "Löschen",
  },
  inbox: {
    welcomeMessage:
      "Hier finden Sie Benachrichtigungen und wichtige Neuigkeiten.",
    sender: "Absender",
    subject: "Betreff",
    date: "Datum",
    formConsulting:
      "Formular erhalten - Vielen Dank für das Ausfüllen des Formulars",
    back: "Zurück",
  },
  legalAndContact: {
    cookieSettings: "Cookie-Einstellungen",
    dataPolicy: "Datenschutzbestimmung",
    termsOfUse: "Nutzungsbedingungen",
    helpAndContact: "Hilfe und Kontakt",
  },
  logInSignUp: {
    logIn: "Anmelden",
    signUp: "Registrieren",
    logInMessage:
      "Melden Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort an.",
    signUpMessage:
      "Wenn Sie ein neuer Benutzer sind, registrieren Sie sich bitte.",
  },
};

export default {
  messages,
};
