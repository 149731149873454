<template>
  <Loader :show="loading" v-if="loading" />
  <MDBRow v-else>
    <MDBCol col="12" class="pb-3">
      <h2 v-html="textPage.title[storedLocale]" class="banner-title font-40 m-0"></h2>
      <p class="font-16 description" v-html="textPage.description[storedLocale]"></p>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import Loader from "@/components/General/Loader/index.vue";
import { MDBCol, MDBRow } from "mdb-vue-ui-kit";

import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, onMounted, watch, ref } from "vue";

const store = useStore();
const route = useRoute();
const loading = ref(true)

const storedLocale = computed(() => store.getters.getCurrentLocale);
const fetchTextPages = async () => {
  try {
    loading.value = true
    await store.dispatch("loadUserTextPageById", route.params.id);
    loading.value = false
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  fetchTextPages();
});

watch(
  () => route.params.id,
  (id) => {
    if (id) {
      fetchTextPages();
    }
  }
);

const textPage = computed(() => store.getters.getTextPage);
</script>
