const messages = {
  title: "Domov",
  welcomeMessage:
    'Vitajte v BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Domov",
  search: "Hľadať",
  sortBy: "Zoradiť podľa",
  seeMore: "Zobraziť viac",
  bankstatement: {
    yourScore: "Váš aktuálny počet marketingových bodov:",
    lastUpdate: "Najnovšia aktualizácia:",
    link: "Prehľad bodového zostatku",
  },
  shop: {
    bestSold: "Najpredávanejšie",
    seasonal: "Sezónne",
    affordable: "Cenovo dostupné",
    forPoints: "za {points} bodov",
    reachedPoints: "Dosiahnutý požadovaný počet bodov",
    cartRemainPoints: "Zostávajúce body",
    remainingPoints: "Chýba ešte {points} bodov",
    noProducts: "Kritériá vyhľadávania nepriniesli žiadne výsledky.",
  },
  showAllProducts: "Choďte do obchodu",
};

export default {
  messages,
};
