<template>
  <MDBRow>
    <MDBCol col="12" xl="8" :class="`${introStep ? 'intro__step' : ''}`">
      <Slider :slides="slides" v-if="!loading" />
      <Loader :show="loading" v-if="loading" />
    </MDBCol>

    <MDBCol
      col="12"
      xl="4"
      :class="`${isGermany && introStep ? 'intro__step' : ''}`"
    >
      <div class="column-order h-100" v-if="!loading">
        <div v-for="(box, index) in mainBoxes" :key="index">
          <a
            :href="box.url[storedLocale]"
            :target="box.new_tab ? '_blank' : '_self'"
          >
            <img
              :src="box.image[storedLocale]"
              class="w-100"
              :alt="box.name[storedLocale]"
              :class="{ 'mt-3': index != 0 }"
            />
          </a>
        </div>
      </div>
      <div class="column-order h-100" v-if="loading">
        <Loader :show="loading" v-if="loading" />
      </div>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import Slider from "./Slider.vue";
import { MDBCol, MDBRow } from "mdb-vue-ui-kit";
import Loader from "@/components/General/Loader/index";

const store = useStore();

const slides = computed(() => store.getters.getUserSlides);
const user = computed(() => store.getters.getMe);
const storedLocale = computed(() => store.getters.getCurrentLocale);
const loading = computed(() => store.getters.getSliderIsLoading);
const isStaff = ref(false);
const isDealer = ref(false);

const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");

const introStep = computed(() => {
  if (isGermany.value) {
    return user.value?.commitment_letter_accept && user.value?.intro == 3;
  }
  return user.value?.commitment_letter_accept && user.value?.intro == 4;
});

const mainBoxes = computed(() => store.getters.getMainBoxes);

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "staff")) {
    isStaff.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "dealer")) {
    isDealer.value = true;
  }
});
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.item-icon {
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: 1;
  right: 24px;
  top: 50%;
  transform: translate(0, -50%);

  @media screen and (min-width: $lgBreakpoint) {
    width: 60px;
    height: 60px;
  }
}

.link-icon {
  width: 123px;
  height: 37px;
  position: absolute;
  z-index: 1;
  right: 24px;
  top: 50%;
  transform: translate(0, -50%);
}

.slider-wrap {
  @media screen and (max-width: $xxxlBreakpoint) {
    max-height: 420px;
  }

  @media screen and (max-width: $xxlBreakpoint) {
    min-height: 380px;
  }

  @media screen and (max-width: $xlBreakpoint) {
    max-height: none;
    margin-bottom: 1.5rem !important;
  }

  @media screen and (max-width: $lgBreakpoint) {
    min-height: 472px;
  }

  @media screen and (max-width: $mdBreakpoint) {
    min-height: 354px;
  }

  @media screen and (max-width: $smBreakpoint) {
    min-height: 65vw;
  }
}

.title {
  &::before {
    content: "";
    display: inline-block;
    width: 27px;
    height: 4px;
    background: #ed1c24;
    transform: rotate(110deg);
    margin: 8px 0;
  }
}
</style>
