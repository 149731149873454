const messages = {
  makeChoice: "Faceți alegerea dvs. aici.",
  seeAll: "Vezi tot",
  clear: "Șterge",
  back: "Înapoi",
  save: "Salvează",
  close: "Închide",
  of: "Din",
  to: "La",
  copyrights: "© {date} Bridgestone Solutions. Toate drepturile rezervate.",
  pageNotFound: "Pagină nu găsită",
  accessDenied: "Acces refuzat",
  search: "Căutare",
  buttons: {
    export: "Exportă",
    delete: "Șterge",
    save: "Salvează",
    cancel: "Anulează",
    next: "Următorul",
    back: "Înapoi",
    skip: "Sari",
    send: "Trimite",
    readNow: "Citeste acum",
    pointsList: "Lista de puncte",
    clear: "Șterge",
    more: "Mai mult",
    close: "Închide",
    reportError: "Raportați eroare",
    accept: "Accepta",
    download: "Download",
    dealerInfoDownload: "Download",
    toTheBasket: "To basket",
    toOrderDelivery: "Continue to the delivery address",
    discover: "Discover",
    newAddress: "Create new delivery address",
    selectAddressBtn: "Select delivery address",
    toOrderSummary: "To the order overview",
    placeOrder: "Binding order",
    toDashboard: "To homepage",
    newOrder: "New order",
    userOrders: "To your orders",
    toSearch: "Search",
    select: "Select",
  },
  tooltips: {
    info: "Ai nevoie de ajutor?",
  },
  confirmation: {
    header: "Confirmare necesară",
    message: "Dorești să ștergi această intrare?",
  },
  nav: {
    dashboard: "Acasă",
    account: "Profilul meu",
    mailbox: "Inbox",
    singleMailbox: "Mesaje",
    stammdaten: "Date principale",
    news_feeds: "Ştiri",
    pointsOverview: "Points overview",
    about: "Despre BS Plus",
    service_number: "Contact service",
    shop: "Magazin de recompense",
    salesPromotions: "Sales promotions",
    shopCategory: "Rewards shop",
    productDetails: "Product details",
    userOrders: "User orders",
    shoppingCart: "Shopping cart",
    orderDelivery: "Shipping address",
    orderSummary: "Order overview",
    orderComplete: "Order complete",
  },
  menu: {
    account: "Profilul meu",
    plus: 'Despre Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Suport Vânzări",
    mailbox: "Inbox",
    about: "Despre BS Plus",
    trainingAndCourses: "Training și Cursuri",
    externalTraining: "Training Extern",
    productInfo: "Informații despre Produse",
    productBrochures: "Broșuri Produse",
    priceLists: "Liste de Prețuri",
    price: "Preț",
    complaintForm: "Formular de Reclamație",
    sales_support: "Suport Vânzări",
    contact_service: "Informații și Serviciu Contact",
    logOut: "Deconectează-te",
    news: "Știri",
    news_feeds: "Ştiri",
    service_number: "Contact service",
    salesPromotions: "Sales promotions",
    shop: "Shop",
  },
  validation: {
    requiredField: "Câmp obligatoriu",
  },
};
export default {
  messages,
};
