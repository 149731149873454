const messages = {
  title: "Promoții de Vânzări",
  desc: "Utilizați activ promoțiile noastre de vânzări cu clienții dumneavoastră. Sunteți, desigur, bineveniți să oferiți clienților ambele promoții. Citiți mai multe despre Garanția Anvelopelor și Drive Our Best mai jos.",
  participation: {
    title: "Aplicați pentru Participare Acum",
    desc: "Ați dori să implementați una sau mai multe dintre promoțiile noastre în magazinul dumneavoastră? Atunci puteți aplica aici pentru a aplica pentru participare. Compania dumneavoastră va fi activată pentru promoție/promoții și veți primi materiale promoționale în termen de 4 săptămâni, materiale promoționale pentru visul dumneavoastră de vânzări.<br/><br/>Faceți alegerea dumneavoastră:",
    request: "APLICAȚI PENTRU PARTICIPARE",
    success: "Vă mulțumim, solicitarea dvs. a fost trimisă",
  },
};
export default {
  messages,
};
