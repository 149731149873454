const messages = {
  titleColorRev: "teisinga",
  buttons: {
    pending: "Laukiama",
    open: "ATIDARYTI",
    requestAccess: "Prašyti prieigos",
  },
  myBsApps: '<span class="text-danger">Mano programėlės</span> Bridgestone',
  my: "Mano programėlės",
  directRoute: "Tiesioginės nuorodos į Bridgestone internetines programėles.",
  welcomeMessage:
    'Sveiki atvykę į BRIDGESTONE PLUS <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  showProfile: "Rodyti profilį",
  newMessages: "Naujas pranešimas gautuosiuose",
  myApps: "Mano programėlės",
  allApps: "Visos programėlės",
  myAppsDescription:
    "Visos Bridgestone internetinės programėlės, kuriose esate užsiregistravę.",
  allAppsDescription:
    "Visos Bridgestone internetinės programėlės, kurios gali būti jums svarbios.",
  myProfile: {
    welcomeMessage:
      "Sveiki atvykę į savo asmeninę zoną. Čia galite atnaujinti arba koreguoti savo duomenis ir peržiūrėti savo gautuosius.",
    masterData: "Pagrindiniai duomenys",
    completeDetails:
      "Užpildykite savo asmeninius ir įmonės duomenis, kad užtikrintumėte teisingą registraciją.",
    goToMasterData: "Eiti į mano duomenis",
    goToInbox: "Eiti į gautuosius",
  },
  masterData: {
    welcomeMessage:
      "Sveiki atvykę į savo asmeninę zoną. Čia galite atnaujinti arba koreguoti savo pagrindinius duomenis.",
    personalDetails: "Asmeniniai duomenys",
    companyDetails: "Įmonės duomenys",
    emailAddress: "El. pašto adresas",
    title: "Kreipinys",
    surname: "Pavardė",
    firstName: "Vardas",
    jobPosition: "Pareigos",
    streetAndHouseNumber: "Įmonės adresas",
    postalCode: "Pašto kodas",
    city: "Miestas",
    country: "Šalis",
    legalStructure: "Teisinė forma",
    customerNumber: "Bridgestone kliento numeris",
    importantPleaseRead: "Svarbu, prašome perskaityti",
    customerNumberInfo:
      "Norint tinkamai priskirti jūsų padangų užsakymus įvairiems tiekėjams, būtina nurodyti jūsų kliento numerį.",
    multipleCustomerNumbers:
      "Jei turite kelis kliento numerius pas tiekėją, pvz., pagrindinei įmonei ir filialams, pirmiausia įveskite pagrindinės įmonės kliento numerį.",
    salesAssignment:
      "Pardavimai, susieti su kitais kliento numeriais, bus priskirti jūsų pagrindinei įmonei.",
    supplier: "Tiekėjas",
    addAnotherCustomerNumber: "Pridėti kliento numerį",
    save: "Išsaugoti",
    delete: "Ištrinti",
  },
  inbox: {
    welcomeMessage: "Čia galite rasti pranešimus ir svarbias naujienas.",
    sender: "Siuntėjas",
    subject: "Tema",
    date: "Data",
    formConsulting: "Forma gauta - Ačiū, kad ją užpildėte",
    back: "Atgal",
  },
  legalAndContact: {
    cookieSettings: "Slapukų nustatymai",
    dataPolicy: "Duomenų politika",
    termsOfUse: "Naudojimo sąlygos",
    helpAndContact: "Pagalba ir kontaktai",
  },
  logInSignUp: {
    logIn: "Prisijungti",
    signUp: "Registruotis",
    logInMessage: "Prisijunkite naudodami savo el. pašto adresą ir slaptažodį.",
    signUpMessage: "Jei esate naujas vartotojas, prašome registruotis.",
  },
};

export default {
  messages,
};
