const messages = {
  title: "Extrasul meu de cont personal",
  desc: "Aici puteți vedea extrasul dumneavoastră de cont personal Bridgestone Plus împreună cu punctele bonus acumulate și utilizate. Vă rugăm să rețineți că punctele pentru comenzile de anvelope pot fi adăugate în până la 6 săptămâni, în funcție de sursă.",
  score: "Soldul punctelor",
  regularPoints: "Puncte standard",
  boosterPoints: "Puncte bonus",
  date: "Data",
  supplySourceReason: "Sursă/Motivul achiziției",
  article: "Articol",
  brand: "Marcă",
  product: "Produs",
  type: "Tip",
  inch: "Inch",
  season: "Sezon",
  amount: "Cantitate",
  standardPoints: "Puncte",
  boosterProduct: "Produs bonus",
  boosterSeason: "Sezon bonus",
  otherActions: "Alte acțiuni sau motive",
  totalPoints: "Număr total de puncte",
  income: "Venit",
  spending: "Cheltuieli",
  lastMonth: "Soldul final",
  loadMore: "Încarcă încă 20 de tranzacții",
  typeFilter: "Filtrează după sursă",
  dateFilter: "Filtrează după perioadă",
  customer_number: "Număr de client",
  currentPoints: "Puncte curente",
  calculationDate: "Data calculului",
  positivePoints: "Puncte pozitive",
  negativePoints: "Deja cheltuite",
  tyresAmount: "Număr de anvelope pe an",
  pointsTotal: "Număr total de puncte",
  currentPointState: "Starea curentă a punctelor",
  currentKickbackState: "Rambursare curentă", // "Kickback" poate fi tradus ca "rambursare"
  points: "Points",
};
export default {
  messages,
};
