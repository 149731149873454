const messages = {
  title:
    "Üdvözöljük a Bridgestone <span class='text-danger'>PLUS</span> oldalán",
  termsAndConditions: "Felhasználási feltételek",
  accept: "Elfogadás",
  decline: "Elutasítás",
  dataModal:
    "Annak érdekében, hogy az ajánlatot az érdeklődési köréhez igazítsuk, kérjük, röviden adja meg termék- vagy üzleti érdeklődési területeit.",
  scrollToAccept:
    "Görgessen le az Általános Szerződési Feltételek elfogadásához és a folytatáshoz",
  welcomeMessage: "Üdvözöljük a Bridgestone Plus oldalon",
  tailorOffer:
    "Annak érdekében, hogy az ajánlatot az érdeklődési köréhez igazítsuk, kérjük, röviden adja meg termék- vagy üzleti érdeklődési területeit.",
  bussinessType:
    "Annak érdekében, hogy az ajánlatot az érdeklődési köréhez igazítsuk, kérjük, röviden adja meg termék- vagy üzleti érdeklődési területeit.",
  selectAreas: "Kérjük, válasszon az alábbi területek közül",
  multipleAnswers: "(több válasz is lehetséges):",
  personalPreferences: "Személyes preferenciák",
  carTyres: "Gumiabroncsok személyautókhoz, SUV-khoz és haszongépjárművekhez",
  motorcyclesTyres: "Gumiabroncsok motorkerékpárokhoz",
  truckBusTyres: "Gumiabroncsok teherautókhoz és buszokhoz",
  agricultureTyres: "Gumiabroncsok mezőgazdasági járművekhez",
  otrTyres: "OTR gumiabroncsok",
  save: "Mentés",
  businessRelationship: "Milyen üzleti kapcsolatban áll a Bridgestone-nal?",
  selectRelationship: "Kérjük, válasszon*:",
  tradingPartner: "Kereskedelmi partner vagyok, aki...",
  tradingPartnerDirect: "... közvetlenül rendel a Bridgestone-tól",
  tradingPartnerIndirect:
    "... közvetetten rendel (pl. nagykereskedésen keresztül)",
  tradingPartnerBoth: "... mind közvetlenül, mind közvetetten rendel",
  fleetResponsibleDirect: "Flottamenedzser vagyok, aki...",
  fleetResponsibleIndirect:
    "... közvetetten rendel (kereskedelem vagy nagykereskedelem útján)",
  fleetResponsibleBoth: "... közvetlenül és közvetetten is rendel",
  noBusinessRelationship:
    "<b>Nincs üzleti kapcsolatom a Bridgestone-nal </b>(sem közvetlen, sem közvetett)",
  yourCustomerNumber: "Az Ön Bridgestone ügyfélszáma*:",
  enterCustomerNumber: "Adja meg ügyfélszámát",
  waitPersonalisedView:
    "Kérjük, várjon egy pillanatot, az Ön személyre szabott BRIDGESTONE PLUS nézete generálásra kerül.",
  personalised: "SZEMÉLYRE SZABOTT",
  plusInfo:
    "Itt talál információkat a Plus-fiókjáról és a Plus-bejövő üzeneteiről.",
  continue: "Folytatás",
  skip: "Kihagyás",
  oneOfTwo: "1 a 2-ből",
  openApps:
    "Megnyithatja azokat az alkalmazásokat, amelyekhez már regisztrált. Kérhet hozzáférést más alkalmazásokhoz is, amelyek relevánsak lehetnek az Ön számára.",
  requiredFields:
    "Kérjük, töltse ki a kötelező mezőket: Megszólítás, Keresztnév, Vezetéknév, Keresztnév",
  stage: "szakasz",
  chooseOne: "(Kérjük, jelöljön meg legalább 1 mezőt)",
  required: "*kötelező mezők",
  lengthCsNumber: "Az ügyfélszám hossza: 6-10 számjegy",
  waitForAdminApproval:
    "Köszönjük, hogy feliratkozott a Bridgestone Plus Rewards programra. Jelenleg vizsgáljuk jelentkezését. A jelentkezés jóváhagyása után megerősítő e-mailt kap.",
};

export default {
  messages,
};
