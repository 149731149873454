import { ProfileService } from "@/services/profile.service";

export async function fetchUserProfile(store) {
  try {
    if (!store.getters.getIsMePending) {
      await store.dispatch("me");
    }
  } catch (error) {
    console.log(error);
  }
}

export async function afterSuccess(
  user_id,
  profile_id,
  fillPercent,
  plainId,
  store
) {
  try {
    await store.dispatch("storeProfilePercentage", {
      user_id: user_id,
      profile_id: profile_id,
      percentage: fillPercent,
    });

    if (fillPercent == 100) {
      await ProfileService.storeFullProfilePoints(plainId);
    }

    await store.dispatch("me");
  } catch (error) {
    console.log(error);
  }
}
