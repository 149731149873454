const messages = {
  title: "Shop",
  description:
    "Witamy w sklepie z nagrodami. Tutaj możesz wymienić swoje punkty Bridgestone Plus <br/> na nagrody z różnych kategorii. Asortyment produktów <br/> jest regularnie aktualizowany. Mamy nadzieję, że przeglądanie strony sprawi Ci przyjemność.",
  sort: {
    asc: "Cena od najzszej",
    desc: "Cena od najzszej",
  },
  productSearchBox: {
    from: "punkty od",
    to: "punkty do",
  },
  noProducts: "Nie znaleziono produtków",
  quantity: "Ilość",
  search: "Szukaj",
  timeUnavailable: "Niedostępne",
  product: "Produkt",
  cart: {
    pointsPerUnit: "Punkty/sztuka",
    pointsSum: "Suma punktów",
  },
  action: "Akcja",
  pointsTotal: "Całkowicie",
  cartRemainPoints: "Punkty w koszyku",
  shoppingCart: "Koszyk",
  emptyCart: "Koszyk jest pusty",
  attributes: "Atrybuty",
  selectAddressP: "Wybierz adres dostawy",
  orderDelivery: "Adres dostawy",
  selectedProducts: "Wybrane produkty",
  orderSummary: "Podsumowanie zamowienia",
  contactPerson: "Osoba kontaktowa",
  selectedAddress: "Wybrany adres dostawy",
  order: {
    success:
      "Thank you! Your order was successful. You will receive an order confirmation in your inbox in just a few minutes.",
    description: "Here you will find an overview of your reward shop orders.",
    date: "Order date",
    number: "Order no.",
    status: {
      created: "Created",
      confirm_order: "Confirm order",
      ready_for_shipment: "Ready for shipment",
      rejected: "Rejected",
      processed: "Processed",
      shipped: "Shipped",
      returned: "Returned",
      finished: "Finished",
    },
    anyOrder: "No orders",
    products: "Products",
    quantity_short: "pcs",
  },
  orders: "Orders",
  notEnoughPoints: "Not enough points available.",
};
export default {
  messages,
};
