const messages = {
  title: "Промоции за продажби",
  desc: "Използвайте активно нашите промоции за продажби за вашите клиенти. Разбира се, можете да предложите и двете промоции на клиентите. Прочетете повече за Гаранцията за гуми и Drive Our Best по-долу.",
  participation: {
    title: "Кандидатствайте за участие сега",
    desc: "Искате ли да внедрите една или повече от нашите промоции във вашия магазин? След това можете да кандидатствате тук, за да кандидатствате за участие. Вашата компания ще бъде активирана за промоцията/промоциите и ще получите рекламни материали в рамките на 4 седмици рекламни материали за вашата мечта за продажби.<br/><br/>Направете своя избор:",
    request: "КАНДИДАТСТВАЙТЕ ЗА УЧАСТИЕ",
    success: "Благодарим ви, вашата заявка е изпратена",
  },
};
export default {
  messages,
};
