const messages = {
  title:
    "Tere tulemast BRIDGESTONE <span class='text-danger'>PLUS</span> platformile",
  description:
    "<b>Kas teil on loodud konto?</b><br/>Logige sisse oma e-posti aadressi ja parooliga.<br/><br/><b>Olete siin uus?</b><br/>Kui olete uus kasutaja, palun registreeruge.<br/><br/>",
  info: "",
  buttons: {
    action: "Logi sisse / Registreeru",
    back: "Tagasi sisselogimise juurde",
    changeLanguage: "Muuda keelt",
    start: "Alusta",
  },
  languageSelect: {
    label: "Keele valik",
    description: "Palun valige oma keel",
  },
};

export default {
  messages,
};
