<template>
  <MDBCardBody v-if="!loading">
    <MDBCardTitle v-html="formattedWelcomeMessage"></MDBCardTitle>
    <MDBCardText>
      <template v-if="
        !isWholesaler && !isStaff && !isWholesalerStaff
      ">
      </template>
      <DashboardLink v-if="!isWholesaler && !isStaff" class="text-uppercase" :to="{ name: redirectLink }">
        {{ $t("account.buttons.showProfile") }}
      </DashboardLink>
    </MDBCardText>
  </MDBCardBody>
</template>

<script setup>
import { MDBCardBody, MDBCardTitle, MDBCardText } from "mdb-vue-ui-kit";
import DashboardLink from "../Dashboard/Dashboard-link.vue";
import { useStore } from "vuex";
import { computed, ref, watch, onMounted } from "vue"; // Import onMounted
import { useTenantConfig } from "@/composables/useTenantConfig";
import { setLinkInWelcomeBox } from "@/composables/useProfileFieldCheck";

const store = useStore();

import { useI18n } from "vue-i18n";
const { t } = useI18n() // Import the translation function from i18n

const user = computed(() => store.getters.getMe);
const profile = computed(() => store.getters.getProfile);
const { tenantConfig } = useTenantConfig();
const { welcomeBoxLink } = setLinkInWelcomeBox(tenantConfig);

const redirectLink = computed(() => welcomeBoxLink());
const isWholesaler = ref(false);
const isWholesalerStaff = ref(false);
const isStaff = ref(false);

const loading = ref(true); // Reactive variable to track loading state

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesalerStaff")) {
    isWholesalerStaff.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "staff")) {
    isStaff.value = true;
  }
});


const formattedWelcomeMessage = computed(() => {

  if (profile.value && profile.value.forename && profile.value.name) {
    return t("dashboard.welcomeMessage", {
      first_name: profile.value.forename,
      last_name: profile.value.name
    });
  } else {
    return t("dashboard.welcomeMessage", {
      first_name: '',
      last_name: ''
    });
  }
});

// Use onMounted to delay rendering until data is ready
onMounted(() => {
  // Wait for the profile data to be loaded
  if (profile.value) {
    loading.value = false; // Set loading to false when the profile data is available
  }
});

</script>


<style scoped lang="scss">
@import "../../styles/_variables.scss";
@import "@/styles/welcome_box.scss";
@import "@/styles/mailbox.scss";

:deep(.card-title) {
  font-size: 20px;

  @media screen and (min-width: $xxlBreakpoint) {
    font-size: 25px;
  }
}

.message-notification {
  background: #f2790d;
  color: white;
  display: inline-block;
  border-radius: 25px;
  width: 18px;
  height: 18px;
  font-size: 12px;
  text-align: center;
  margin-left: 5px;
}

.title {
  position: relative;
}
</style>
