const messages = {
  makeChoice: "Gör ditt val här.",
  seeAll: "Öppna",
  clear: "Radera",
  back: "Tillbaka",
  save: "Spara",
  close: "Nära",
  of: "Av",
  to: "till",
  copyrights: "© {date} Bridgestone Solutions. Alla rättigheter förbehållna",
  pageNotFound: "Sidan hittades inte",
  accessDenied: "åtkomst nekad",
  search: "Sök",
  buttons: {
    export: "Export",
    delete: "Radera",
    save: "Spara",
    cancel: "Avboka",
    next: "Fortsätt",
    back: "Tillbaka",
    skip: "Hoppa över",
    send: "Skicka",
    clear: "Radera",
    readNow: "Läs nu",
    pointsList: "Poänglista",
    more: "Mer",
    close: "Nära",
    reportError: "Rapportera fel",
    accept: "Prijať",
    download: "Download",
    dealerInfoDownload: "Download",
    toTheBasket: "To basket",
    toOrderDelivery: "Continue to the delivery address",
    discover: "Discover",
    newAddress: "Create new delivery address",
    selectAddressBtn: "Select delivery address",
    toOrderSummary: "To the order overview",
    placeOrder: "Binding order",
    toDashboard: "To homepage",
    newOrder: "New order",
    userOrders: "To your orders",
    toSearch: "Search",
    select: "Select",
  },
  tooltips: {
    info: "Behöver du hjälp? +46 60 140610",
  },
  confirmation: {
    header: "Bekräftelse krävs",
    message: "Do you want to delete this record?",
  },
  nav: {
    dashboard: "Hem",
    account: "Min profil",
    mailbox: "Inkorg",
    singleMailbox: "Meddelanden",
    stammdaten: "Masterdata",
    news_feeds: "Nyheter",
    pointsOverview: "Points overview",
    about: "Om BS Plus",
    service_number: "Contact service",
    shop: "Belöningsbutik",
    salesPromotions: "Sales promotions",
    shopCategory: "Rewards shop",
    productDetails: "Product details",
    userOrders: "User orders",
    shoppingCart: "Shopping cart",
    orderDelivery: "Shipping address",
    orderSummary: "Order overview",
    orderComplete: "Order complete",
  },
  menu: {
    account: "Min profil",
    plus: 'Om Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Soutien à la vente",
    mailbox: "Inkorg",
    about: "Om BS Plus",
    trainingAndCourses: "Formations et cours",
    externalTraining: "Formations externes",
    productInfo: "Informations sur les produits",
    productBrochures: "Brochures sur les produits",
    priceLists: "Listes de prix",
    price: "Pricat",
    complaintForm: "Formulaire de réclamation",
    sales_support: "Soutien à la vente",
    contact_service: "kontakt och service",
    logOut: "Logga ut",
    news: "Nyheter",
    news_feeds: "Nyheter",
    service_number: "Contact service",
    salesPromotions: "Sales promotions",
    shop: "Shop",
  },
  validation: {
    requiredField: "Campo obligatorio",
  },
};

export default {
  messages,
};
