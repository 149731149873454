const messages = {
  title: "My Profile",
  desc: "Welcome to your personal area. Here you can update or adjust your <br/>details and check your inbox.",
  descStaff:
    "Welcome to your personal area. Here you can update or adjust your details.",
  buttons: {
    showProfile: "Show Profile",
    userProfile: "GO TO MASTER DATA",
    mailbox: "GO TO INBOX",
  },
  categories: {
    baseData: {
      title: "Master Data",
      desc: "Fill in your personal and company details for proper registration.",
    },
    mailbox: {
      desc: "Receive important notifications and news in your inbox.",
    },
    bankstatement: {
      title: "Account Statement",
      desc: "Here you can check the status of your personal Bridgestone Plus account and review your earned and used reward points.",
    },
  },
  profile: {
    general: "PERSONAL DATA COMPANY DATA",
    title: "Master Data",
    description:
      "Welcome to your personal area. Here you can update or adjust your details.",
    block_description:
      "Welcome to your personal area. Here you can consult your details",
    personalData: "PERSONAL DATA",
    password: "Password",
    password1: "Password (optional)*",
    password2: "Password (repeat)*",
    formOfAddress: "Title",
    choose: "Choose title",
    mr: "Mr.",
    mrs: "Ms.",
    diverse: "Other",
    firstname: "First Name",
    surname: "Last Name",
    position: "POSITION IN COMPANY",
    operatingData: "COMPANY DATA",
    company: "Company",
    legalForm: "Legal Structure",
    name: "Name",
    streetHouseNr: "Company Address",
    street: "Street",
    postcode: "Postcode",
    location: "City",
    country: "Country",
    de: "Germany",
    at: "Austria",
    ch: "Switzerland",
    nl: "Netherlands",
    it: "Italy",
    language: "Language",
    french: "French",
    italian: "Italian",
    german: "German",
    nederland: "Dutch",
    openingHours: "Opening Hours & Contact",
    phone: "Phone",
    website: "Website",
    operatingIndicators: "Key Operating Figures",
    tireSale: "Annual tire sales approx. in units",
    tireSaleLabel: "Sell tires in large volumes",
    inches: "Of which ≥ 18 inches (in %)",
    inchesLabel: "Of which 18 inches or more in %",
    segmentSale: "Tire sales by segment (share Budget / Mid / Premium in %)",
    premBudget: "Premium",
    midBudget: "Mid",
    budBudget: "Budget",
    distribution: "DISTRIBUTION SUMMER / WINTER / ALL-SEASON (in %)",
    tyreSizes: "Number of seasonal storage slots (sets of tires)",
    diffDeliveryAddres: "Add a delivery address",
    yourEmailAddress: "Your email address.",
    clientNr: "Customer Numbers",
    clientNrSingular: "Customer Number",
    clientNrContent:
      "<span class='text-danger fw-bold'>IMPORTANT - PLEASE READ:</span><br /><br /> For proper allocation of your tire purchases with a wholesaler and/or Bridgestone, it is necessary to <b>provide your respective customer numbers.</b><br/><br/>If you have <b>multiple customer numbers with a wholesaler and/or Bridgestone,</b> e.g., for your main store and branches, please provide the main store’s customer number first.<br/><br/><b>Sales from other customer numbers will then be attributed to your main store.</b>",
    supplySource: "Supply Source",
    email: "Email Address",
    staffEmail: "Participant's Email Address",
    registered: "Registered On",
    registeredAt: "Registered On",
    lastLogIn: "Last Login",
    details: "Details",
    inDetail: "{name} in detail",
    bsClientNr: "Your Bridgestone Customer Number",
    bftClientNr: "Your Best4Tires Customer Number",
    acceptTerms: "Accept terms and conditions for participation.",
    newCustomerNumbersInfo:
      "<span class='text-danger fw-bold'>IMPORTANT - PLEASE READ:</span><br />Upon first entry of customer numbers, your data will be verified for accuracy and compliance with our distribution criteria. The decision to activate the customer number(s) and link them to your profile may take up to 5 working days.",
    selectWholeSaler: "at the supplier (please choose)",
    cooperation: "MEMBERSHIP / COOPERATION",
    error100: "The sum of the values must equal 100%.",
    errorRange: "Value must be between 0 and 100",
    btw_number_label: "Your company's VAT registration number",
    btw_number: "VAT Registration Number",
    mobile: "Phone Number",
    mobile_label: "Your mobile phone number",
    iban_number: "Your company's IBAN number",
    iban_number_label: "IBAN Number",
    yourCsNr: "Your Customer Numbers",
    yourLastName: "Your Last Name",
    positionLabel: "Select a Position",
    yourFirstName: "Your First Name",
    companyLabel: "Company",
    numberOfSetsOfSeasonalTiresBudget: "Annual Storage",
    contactInfo: "If you have any questions, please contact us:",
    addAnotherCsNumber: "Add another Bridgestone Customer Number",
    activityAndProfile: "BUSINESS OPERATIONS AND PROFILE",
  },
  countries: {
    al: "Albania",
    ad: "Andorra",
    at: "Austria",
    be: "Belgium",
    by: "Belarus",
    ba: "Bosnia and Herzegovina",
    bg: "Bulgaria",
    hr: "Croatia",
    cy: "Cyprus",
    cz: "Czechia",
    dk: "Denmark",
    ee: "Estonia",
    fi: "Finland",
    fr: "France",
    ge: "Georgia",
    gr: "Greece",
    es: "Spain",
    nl: "Netherlands",
    ie: "Ireland",
    is: "Iceland",
    kz: "Kazakhstan",
    xk: "Kosovo",
    li: "Liechtenstein",
    lt: "Lithuania",
    lu: "Luxembourg",
    lv: "Latvia",
    mk: "North Macedonia",
    mt: "Malta",
    md: "Moldova",
    mc: "Monaco",
    de: "Germany",
    no: "Norway",
    pl: "Poland",
    pt: "Portugal",
    ru: "Russia",
    ro: "Romania",
    sm: "San Marino",
    rs: "Serbia",
    sk: "Slovakia",
    si: "Slovenia",
    se: "Sweden",
    ch: "Switzerland",
    tr: "Turkey",
    ua: "Ukraine",
    hu: "Hungary",
    gb: "United Kingdom",
    it: "Italy",
    va: "Vatican City",
  },
};

export default {
  messages,
};
