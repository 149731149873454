<template>
  <MDBCard class="border-0">
    <CategoryTop small :title="mainTitle" :breadcrumbs-parent-routes="['account']"
      :description="$t('wholesaler.dashboard.description')" content-class="px-4 mt-4" left-col="10" right-col="2">
      <template v-if="image" #right>
        <DashboardCard>
          <template v-slot:content>
            <img :src="image" />
          </template>
        </DashboardCard>
      </template>
    </CategoryTop>
    <MDBRow class="m-3">
      <Germany v-if="isGermany || isSwitzerland" />
      <Netherlands v-if="!isGermany && !isSwitzerland" />

      <MDBCol col="12" xl="12" class="pt-4">
        <DashboardCard :title="$t('wholesaler.dashboard.details.title')" :description="$t('wholesaler.dashboard.details.description', {
          name: name,
        })
          ">
          <template v-slot:search>
            <div class="d-flex flex-column justify-content-end align-items-end">
              <div class="w-100">
                <MDBInput v-model="query" :label="$t('search')" />
              </div>
              <div>
                <MDBBtn color="danger" @click="search">{{
                  $t("global.buttons.toSearch")
                  }}</MDBBtn>
              </div>
            </div>
          </template>
          <template v-slot:table>
            <div class="overflow-x-auto">
              <MDBTable borderless class="table-dashboard">
                <thead>
                  <tr>
                    <th v-for="(head, id) in detailsHeaders" :key="`key_${id}`" :class="`${head.show ? '' : 'd-none'}`">
                      <div class="table-header-stats">
                        <span>{{ head.label }}</span>
                        <img v-if="head.field != 'details'" :src="require('@/assets/img/icons/filter_btn.svg')"
                          alt="filter_btn" class="icon-container--small cursor--pointer" @click="newSort(head.field)" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, key) in users" :key="`user_${key}`" class="cursor--pointer staffWsh-table-row">
                    <template v-for="(prop, val) in user" :key="`item_${val}`">
                      <td>
                        <div @click="openDetailsModal(prop, user)">
                          <template v-if="val == 'details'">
                            <div class="text-decoration-underline cursor--pointer">
                              {{ $t("account.profile.details") }}
                            </div>
                          </template>
                          <template v-else>
                            {{ prop }}
                          </template>
                        </div>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </MDBTable>
            </div>

            <div class="load-more d-flex justify-content-center">
              <button @click="loadMore" type="button" class="btn btn-danger text-uppercase px-5" :disabled="loading">
                {{ $t("wholesaler.dashboard.loadMore") }}
              </button>
              <button @click="loadAll" class="btn btn-danger text-uppercase px-5" v-if="isNetherland" type="button"
                :disabled="loading">
                {{ $t("buttons.all") }}
              </button>
              <downloadexcel v-if="isNetherland && !loading" class="btn btn-danger text-uppercase px-5" :fetch="loadAll"
                :data="users" :fields="exportLabels" type="xlsx" name="stats.xlsx">
                Download Excel
              </downloadexcel>
            </div>
          </template>
        </DashboardCard>
      </MDBCol>
    </MDBRow>
  </MDBCard>
  <MDBModal v-model="modal" tabindex="-1" centered size="xl">
    <PointsOverviewModal v-if="detailsId && userDetails" :user_id="detailsId" :user_data="userDetails"
      :profile_data="selectedProfile" :wsh_data="wshSelect" />
  </MDBModal>
</template>

<script setup>
import {
  MDBCard,
  MDBCol,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBTable,
  MDBModal,
} from "mdb-vue-ui-kit";

import { useStore } from "vuex";
import { ref, computed, toRaw, onMounted } from "vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import i18n from "@/plugins/i18n";
import downloadexcel from "vue-json-excel3";

import { ProfileService } from "@/services/profile.service";
import { WholesalerService } from "@/services/wholesaler.service.js";

import CategoryTop from "@/components/General/CategoryTop/index.vue";
import DashboardCard from "@/components/User/Wholesaler/DashboardCard.vue";
import PointsOverviewModal from "@/components/User/Seller/PointsOverviewModal.vue";
import Netherlands from "@/components/User/Wholesaler/Country/Netherlands.vue";
import Germany from "@/components/User/Wholesaler/Country/Germany.vue";

import dates from "@/plugins/dates";

const store = useStore();

const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const isSwitzerland = computed(() => LANG === "ch");
const isNetherland = computed(() => LANG === "nl");
const me = computed(() => store.getters.getMe);
const allDealersNumber = computed(() => store.getters.getAllDealersNumber);

const name = computed(() => {
  return me.value.whs ? me.value.whs.wholesaler.name : me.value.name;
});
const loading = computed(() => store.getters.getPending);

const image = computed(() => {
  return me.value.whs ? me.value.whs.wholesaler.image : me.value.image;
});

const modal = ref(false);
const detailsId = ref(null);
const userDetails = ref(null);
const selectedProfile = ref(null);
const exportLabels = ref([]);

const mainTitle = computed(() => {
  if (isNetherland.value) {
    return i18n.global.t("wholesaler.dashboard.extraTitle");
  } else {
    return i18n.global.t("wholesaler.dashboard.title", {
      name: name.value,
    });
  }
});

const sortData = ref({
  field: "",
  order: "asc",
});

const page = ref(0);
const users = ref([]);
const query = ref("");
const detailsHeaders = ref([]);
const wshSelect = ref(null);

const search = async () => {
  let whsId = me.value.whs ? me.value.whs.wholesaler_id : me.value.id;

  const usersResponse = await WholesalerService.wholesalerSearch(
    whsId,
    query.value
  );
  users.value = usersResponse.body;
  detailsHeaders.value = usersResponse.headers;
};

const newSort = (field) => {
  if (sortData.value.field == field) {
    sortData.value.order = sortData.value.order === "asc" ? "desc" : "asc";
  } else {
    sortData.value.order = "asc";
  }
  sortData.value.field = field;
  users.value.sort(dates.compareSortValues(sortData.value.field, sortData.value.order));
};

const loadMore = async () => {
  page.value += 1;

  let whsId = me.value.whs ? me.value.whs.wholesaler_id : me.value.id;

  const response = await WholesalerService.wholesalerSearch(
    whsId,
    query.value,
    page.value
  );

  if (response && response.body) {
    users.value.push(...Object.values(toRaw(response.body)));
    newSort(sortData.value.field);
  }
};

const loadAll = async () => {
  query.value = "";
  toast(i18n.global.t("toast.waitMoment"), {
    type: "success",
    theme: "colored",
    transition: toast.TRANSITIONS.SLIDE,
    position: toast.POSITION.BOTTOM_RIGHT,
  });

  users.value = [];
  page.value = 0;

  const attempts = allDealersNumber.value / 20;

  for (let i = 0; i < attempts; i++) {
    await loadMore();
  }

  toast(i18n.global.t("toast.downloadFinished"), {
    type: "success",
    theme: "colored",
    transition: toast.TRANSITIONS.SLIDE,
    position: toast.POSITION.BOTTOM_RIGHT,
  });

  return users.value;
};

const openDetailsModal = async (id, user) => {
  modal.value = true;
  detailsId.value = user.details;
  userDetails.value = user;

  const response = await ProfileService.userProfileData(user.details);
  selectedProfile.value = response.data.data;
  const selectData = await WholesalerService.wholesalerSelect();

  wshSelect.value = selectData.data;
  await store.dispatch("loadPointsByCsNumber", user.details);
};

onMounted(async () => {
  await store.dispatch('loadMenu')
  let whsId = me.value.whs ? me.value.whs.wholesaler_id : me.value.id;

  const usersResponse = await WholesalerService.wholesalerSearch(whsId);
  users.value = usersResponse.body;
  detailsHeaders.value = usersResponse.headers;

  exportLabels.value = detailsHeaders.value.reduce((acc, current) => {
    // Ensure 'field' property exists and has a value
    if (current.field) {
      acc[current.label] = current.field;
    } else {
      console.warn(`Skipping object without 'field' property:`, current);
    }
    return acc;
  }, {});
});
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
@import "@/styles/table_dashboard.scss";

.pointer {
  cursor: pointer;
}
</style>
