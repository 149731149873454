<template>
  <div v-if="show" id="personal_data">
    <h4 class="mb-3 text-uppercase">{{ $t("account.profile.personalData") }}</h4>

    <label for="email" class="label px-3 text-uppercase" :class="`${props.showEmailField ? '' : 'd-none'}`">
      {{ $t("account.profile.email") }}*
    </label>
    <input type="email" class="form-control" :placeholder="$t('account.profile.yourEmailAddress')"
      :class="`${props.showEmailField ? '' : 'd-none'}`" :aria-label="$t('account.profile.yourEmailAddress')"
      aria-describedby="basic-addon1" v-model="models.email" disabled id="email" />
    <MDBRow v-if="showProfileField('salutation')">
      <MDBCol col="12">
        <label for="salutation" class="label px-3 text-uppercase">
          {{ $t("account.profile.formOfAddress") }}{{ profileFieldRequired('salutation') ? '*' : '' }}
        </label>
        <Field as="select" class="form-select" :aria-label="$t('account.profile.formOfAddress')" id="salutation"
          name="salutation" :label="$t('account.profile.formOfAddress')" v-model="models.salutation"
          :rules="{ required: profileFieldRequired('salutation') }">
          <option disabled :value="null">{{ $t("account.profile.choose") }}</option>
          <option :value="$t('account.profile.mrs')">{{ $t("account.profile.mrs") }}</option>
          <option :value="$t('account.profile.mr')">{{ $t("account.profile.mr") }}</option>
        </Field>
        <div>
          <ErrorMessage name="salutation" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </MDBCol>
    </MDBRow>
    <label for="firstname" class="label px-3 text-uppercase">
      {{ $t("account.profile.firstname") }}{{ profileFieldRequired('firstname') ? '*' : '' }}
    </label>
    <Field type="text" class="form-control" :placeholder="$t('account.profile.yourFirstName')"
      :aria-label="$t('account.profile.firstname')" aria-describedby="basic-addon1" v-model="models.forename"
      id="firstname" :rules="{ required: profileFieldRequired('firstname') }" name="firstname"
      :label="$t('account.profile.firstname')" />
    <div>
      <ErrorMessage name="firstname" class="text-danger text-small text-end m-0 pt-1" />
    </div>

    <label for="surname" class="label px-3 text-uppercase">
      {{ $t("account.profile.surname") }}{{ profileFieldRequired('surname') ? '*' : '' }}
    </label>
    <Field type="text" class="form-control" :placeholder="$t('account.profile.yourLastName')"
      :aria-label="$t('account.profile.yourLastName')" aria-describedby="basic-addon1" v-model="models.name"
      :rules="{ required: profileFieldRequired('surname') }" name="surname" :label="$t('account.profile.surname')" />
    <div>
      <ErrorMessage name="surname" class="text-danger text-small text-end m-0 pt-1" />
    </div>

    <!-- Job Position -->
    <template v-if="showProfileField('job_position')">
      <label for="job_position" class="label px-3 text-uppercase">
        {{ $t("account.profile.positionLabel") }}{{ props.showDots ? '*' : '' }}
      </label>
      <Field type="text" class="form-control" :placeholder="$t('account.profile.positionLabel')"
        :aria-label="$t('account.profile.positionLabel')" aria-describedby="basic-addon1" v-model="models.job_position"
        :rules="{ required: profileFieldRequired('job_position') }" name="job_position"
        :label="$t('account.profile.positionLabel')" />
      <div>
        <ErrorMessage name="job_position" class="text-danger text-small text-end m-0 pt-1" />
      </div>
    </template>

    <!-- TODO: Probably to remove -->
    <MDBRow v-if="!isLite">
      <MDBCol col="12">
        <label for="position" class="label px-3 text-uppercase">
          {{ $t("account.profile.position") }}{{ props.showDots ? '*' : '' }}
        </label>
        <select class="form-select" :aria-label="$t('account.profile.positionLabel')" id="position"
          v-model="models.job_position_id">
          <option disabled :value="null">
            {{ $t("account.profile.positionLabel") }}
          </option>
          <option :value="jobPosition.id" v-for="(jobPosition, index) in jobPositions" :key="index">
            {{ jobPosition.name }}
          </option>
        </select>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { ref, reactive, computed, watch, defineExpose, defineProps } from "vue";
import { useStore } from "vuex";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { Field, ErrorMessage, defineRule } from "vee-validate";
import { required } from "@vee-validate/rules";
import { useTenantConfig } from "@/composables/useTenantConfig";
import { useProfileFieldShow, useProfileFieldRequired } from "@/composables/useProfileFieldCheck";

// Validation rules
defineRule('required', required);

// Vuex Store
const store = useStore();

// Props
const props = defineProps({
  showEmailField: { type: Boolean, default: true },
  showDots: { type: Boolean, default: false },
});

// Language and Lite Version Computed Properties]
const isLite = computed(() => store.getters.isLiteVersion);

// Tenant Configuration and Profile Field Check
const { tenantConfig, show } = useTenantConfig();
const { showProfileField } = useProfileFieldShow(tenantConfig);
const { profileFieldRequired } = useProfileFieldRequired(tenantConfig);


// Job Positions from Store
const jobPositions = computed(() => store.getters.getJobPositions);

// Models
const models = reactive({
  email: null,
  salutation: null,
  forename: null,
  name: null,
  job_position_id: null,
  job_position: null,
  username: null,
  id: null,
  user_id: null,
});

// Progress Percentage Calculation
const percentage = ref(0);
const indexToPercentage = ["email", "salutation", "forename", "name"];

watch(
  () => indexToPercentage.map((item) => models[item]),
  (newVal) => {
    percentage.value =
      (newVal.filter((i) => i).length / indexToPercentage.length) * 100;
  }
);

// Watchers for Store Data
watch(
  () => store.getters.getMe,
  (newVal) => {
    models.email = newVal.email;
    models.username = newVal.username;
    models.id = newVal.id;
  }
);

watch(
  () => store.getters.getProfile,
  (newVal) => {
    models.salutation = newVal.salutation;
    models.forename = newVal.forename;
    models.name = newVal.name;
    models.job_position_id = newVal.job_position_id;
    models.job_position = newVal.job_position;
    models.user_id = newVal.user_id;
  },
  { deep: true }
);

// Expose models and percentage
defineExpose({
  models,
  percentage,
});
</script>
