<template>
  <CategoryTop :title="$t('shop.title')" :description="categoriesData.current ? categoriesData.current.description : ''
    " :loader="categoriesRequestSent" :breadcrumbs-parent-routes="getBreadcrumbParentRoutes" :breadcrumbs-route-name="categoriesData.current ? categoriesData.current.name : ' '
      " />
</template>

<script setup>
import CategoryTop from "../General/CategoryTop/index.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { getCategoriesData } from "./products";

const store = useStore();
const categoriesRequestSent = computed(
  () => store.getters.getCategoriesRequestSent
);
const categoriesData = computed(() => getCategoriesData());

const getBreadcrumbParentRoutes = computed(() => {
  const routes = ["shop"];

  if (categoriesData.value.parent) {
    routes.push({
      name: "shopCategory",
      title: categoriesData.value.parent.name,
      params: {
        id: categoriesData.value.parent.id,
      },
      query: { page: 1 }
    });
  }

  return routes;
});

</script>

<style scoped lang="scss">
:deep(.description) {
  min-height: none;
}
</style>
