const messages = {
  titleColorRev: "verdadeiro",
  buttons: {
    pending: "Pendente",
    open: "Abrir",
    requestAccess: "Solicitar acesso ",
  },
  myBsApps: '<span class="text-danger">As minhas aplicações</span> Bridgestone',
  my: "As minhas aplicações",
  directRoute:
    "O seu caminho direto para todas as aplicações web da Bridgestone.",
  welcomeMessage:
    'Bem-vindo ao BRIDGESTONE PLUS <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  showProfile: "Ver perfil",
  newMessages: "Tem novas mensagens na sua caixa de entrada",
  myApps: "As minhas aplicações",
  allApps: "Todas as aplicações",
  myAppsDescription:
    "todas as aplicações web da Bridgestone nas quais já está registado.",
  allAppsDescription:
    "todas as aplicações web da Bridgestone que podem ser do seu interesse.",
  myProfile: {
    welcomeMessage:
      "Bem-vindo à sua área pessoal. Aqui, pode atualizar ou ajustar os seus dados principais e ver a sua caixa de entrada.",
    masterData: "Dados Principais ",
    completeDetails:
      "Preencha os seus dados pessoais e os da sua empresa para que o registo seja bem sucedido.",
    goToMasterData: "Ir a dados principais",
    goToInbox: "Ir à caixa de entrada",
  },
  masterData: {
    welcomeMessage:
      "Bem-vindo à sua área pessoal. Aqui pode atualizar ou ajustar os seus dados principais.",
    personalDetails: "Dados pessoais ",
    companyDetails: "Dados da Empresa ",
    emailAddress: "Endereço eletrónico",
    title: "Título",
    surname: "Apelido",
    firstName: "Primeiro Nome ",
    jobPosition: "Primeiro Nome ",
    streetAndHouseNumber: "Rua e número da porta",
    postalCode: "Código Postal",
    city: "Cidade",
    country: "País",
    legalStructure: "Legal",
    customerNumber: "Número de cliente Bridgestone",
    importantPleaseRead: "Importante, por favor leia",
    customerNumberInfo:
      "Para atribuir corretamente as suas encomendas de pneus aos distribuidores e/ou à Bridgestone, é essencial que forneça os seus respetivos números de cliente.",
    multipleCustomerNumbers:
      "Se tiver vários números de cliente com um distribuidor, por exemplo, para a sua atividade principal e para as suas sucursais, indique primeiro o número de cliente da atividade principal.",
    salesAssignment:
      "As vendas associadas aos números de clientes adicionais serão então atribuídas à sua empresa principal.",
    supplier: "Fornecedor ",
    addAnotherCustomerNumber: "Adicionar número de cliente ",
    save: "Guardar",
    delete: "Eliminar",
  },
  inbox: {
    welcomeMessage: "Encontre aqui notificações e notícias importantes.",
    sender: "Remetente",
    subject: "Assunto",
    date: "Data",
    formConsulting:
      "Consulta de formulários - Obrigado por processar o formulário",
    back: "Voltar atrás",
  },
  legalAndContact: {
    cookieSettings: "Política em matéria de Cookies ",
    dataPolicy: "Políticas de Confidencialidade ",
    termsOfUse: "Condições Gerais.",
    helpAndContact: "Ajuda e contactos",
  },
  logInSignUp: {
    logIn: "Iniciar sessão  ",
    signUp: "Registrar-se",
    logInMessage:
      "Inicie sessão com o seu endereço de correio eletrónico e a sua palavra-passe.",
    signUpMessage: "Se é um novo utilizador, por favor registe-se.",
  },
};
export default {
  messages,
};
