const messages = {
  title: "Gautieji",
  desc: "Čia galite rasti svarbius pranešimus ir naujienas.",
  newMessages: "Naujausios jūsų gautos žinutės",
  link: "Rodyti žinutes",
  showMessages: "Rodyti žinutes",
  message: {
    sender: "Siuntėjas",
    thread: "Tema",
    date: "Data",
  },
  sender: {
    system: "BRIDGESTONE PLUS",
  },
  back: "Atgal",
  time: {
    secondsAgo: "il y a {time} secondes",
    minutesAgo: "il y a {time} minutes",
    hoursAgo: "il y a {time} heures",
    daysAgo: "il y a {time} jours",
  },
};

export default {
  messages,
};
