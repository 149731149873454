<template>
  <div class="bg-white px-4 py-4 custom-card" :class="minHeight" v-if="props.data" @click="goTo(props.data?.link)">
    <img class="w-100 mb-3" :src="props.data.image" alt="" v-if="props.data?.image" />
    <h3 v-if="props.data.name" v-html="props.data.name" class="training-title font-28 lh-sm fw-bold mb-3"></h3>
    <p v-html="props.data.description" class="font-14"></p>

    <div class="d-grid gap-2 button-container">
      <router-link class="btn text-uppercase training-link" :class="props.btnClass" :to="props.data.link"
        :target="props.target" @click="($event) => $event.preventDefault()">{{ props.linkText }}</router-link>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps({
  data: Object,
  linkText: {
    type: String,
  },
  target: {
    type: String,
    default: "_self",
  },
  simpleLink: {
    type: Boolean,
    default: false,
  },
  btnClass: {
    type: String,
    default: "btn-danger",
  },
  minHeight: {
    type: String,
    default: "simple-container",
  },
});

const goTo = (link) => {
  if (!props.simpleLink) {
    router.push(link);
  } else if (props.target == "_blank" && props.simpleLink) {
    window.open(link, "_blank");
  } else {
    location.href = link;
  }
};
</script>

<style scoped lang="scss">
@import "../../../styles/_variables.scss";

.custom-card {
  cursor: pointer;
  display: flex;
  flex-flow: column;
}

.training-title {
  hyphens: auto;
  padding-top: 12px;
  color: #000000;
}

.training-link {

  &:hover,
  &:visited,
  &:active {
    color: white;
  }
}

.button-container {
  margin-top: auto;
}

.container-height {
  min-height: 530px;
}

.simple-container {
  min-height: 270px;
}
</style>
