const messages = {
  makeChoice: "Tee valintasi tästä.",
  seeAll: "Näytä kaikki",
  clear: "Poista",
  back: "Takaisin",
  save: "Tallenna ",
  close: "Sulje",
  of: "Of",
  to: "osoitteeseen ",
  copyrights: "© {date} Bridgestone Solutions. Kaikki oikeudet pidätetään.",
  pageNotFound: "Sivua ei löydy",
  accessDenied: "Pääsy evätty",
  search: "Etsi",
  buttons: {
    export: "Export",
    delete: "Poista",
    save: "Tallenna",
    cancel: "Peruuta",
    next: "Jatka",
    back: "Takaisin",
    skip: "Ohita",
    send: "Lähetä",
    readNow: "Lue nyt",
    pointsList: "Pisteiden lista",
    clear: "Poista",
    more: "Lisää",
    close: "Sulje",
    reportError: "Ilmoita virheestä",
    accept: "Hyväksyä",
    download: "Download",
    dealerInfoDownload: "Download",
    toTheBasket: "To basket",
    toOrderDelivery: "Continue to the delivery address",
    discover: "Discover",
    newAddress: "Create new delivery address",
    selectAddressBtn: "Select delivery address",
    toOrderSummary: "To the order overview",
    placeOrder: "Binding order",
    toDashboard: "To homepage",
    newOrder: "New order",
    userOrders: "To your orders",
    toSearch: "Search",
    select: "Select",
  },
  tooltips: {
    info: "Tarvitsetko apua? +358 207 936 200",
  },
  confirmation: {
    header: "Vaadittu vahvistus",
    message: "Haluatko poistaa tämän tietueen?",
  },
  nav: {
    dashboard: "Etusivu",
    account: "Oma Profiili",
    mailbox: "Saapuneet-kansio",
    singleMailbox: "Viestit",
    stammdaten: "Perustiedot",
    news_feeds: "Uutiset",
    pointsOverview: "Points overview",
    about: "Noin BS Plus",
    service_number: "Contact service",
    shop: "Palkintokauppa",
    salesPromotions: "Sales promotions",
    shopCategory: "Rewards shop",
    productDetails: "Product details",
    userOrders: "User orders",
    shoppingCart: "Shopping cart",
    orderDelivery: "Shipping address",
    orderSummary: "Order overview",
    orderComplete: "Order complete",
  },
  menu: {
    account: "Oma Profiili",
    plus: 'Noin Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Soutien à la vente",
    mailbox: "Saapuneet-kansio",
    about: "Noin BS Plus",
    trainingAndCourses: "Formations et cours",
    externalTraining: "Formations externes",
    productInfo: "Informations sur les produits",
    productBrochures: "Brochures sur les produits",
    priceLists: "Listes de prix",
    price: "Pricat",
    complaintForm: "Formulaire de réclamation",
    sales_support: "Soutien à la vente",
    contact_service: "Yhteystiedot ja palvelu",
    logOut: "Kirjaudu ulos",
    news: "Uutiset",
    news_feeds: "Uutiset",
    service_number: "Contact service",
    salesPromotions: "Sales promotions",
    shop: "Shop",
  },
  validation: {
    requiredField: "Pakollinen kenttä",
  },
};

export default {
  messages,
};
