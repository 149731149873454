const messages = {
  title: "Promozioni di Vendita",
  desc: "Utilizza attivamente le nostre promozioni di vendita con i tuoi clienti. Sei, ovviamente, invitato a offrire entrambe le promozioni ai clienti. Leggi di più sulla Garanzia Pneumatici e Drive Our Best di seguito.",
  participation: {
    title: "Richiedi la partecipazione ora",
    desc: "Desideri implementare una o più delle nostre promozioni nel tuo negozio? Allora puoi richiedere la partecipazione qui. La tua azienda sarà attivata per la(e) promozione(i) e riceverai materiale promozionale entro 4 settimane, materiale promozionale per il tuo sogno di vendita.<br/><br/>Fai la tua scelta:",
    request: "RICHIEDI LA PARTECIPAZIONE",
    success: "Kiitos, pyyntösi on lähetetty",
  },
};
export default {
  messages,
};
