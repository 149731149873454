const messages = {
  title: "Sveiki atvykę į Bridgestone <span class='text-danger'>PLUS</span>",
  termsAndConditions: "Taisyklės ir sąlygos",
  accept: "Priimti",
  decline: "Atmesti",
  dataModal:
    "Norėdami pritaikyti šį pasiūlymą jūsų interesams, prašome trumpai nurodyti savo produktų interesus ar verslo sritis.",
  scrollToAccept: "Slinkite žemyn, kad priimtumėte mūsų sąlygas ir tęstumėte",
  welcomeMessage: "Sveiki atvykę į Bridgestone Plus",
  tailorOffer:
    "Norėdami pritaikyti šį pasiūlymą jūsų interesams, prašome trumpai nurodyti savo produktų interesus ar verslo sritis.",
  bussinessType:
    "Norėdami pritaikyti šį pasiūlymą jūsų interesams, prašome trumpai nurodyti savo produktų interesus ar verslo sritis.",
  selectAreas: "Pasirinkite iš šių sričių",
  multipleAnswers: "(galimi keli atsakymai):",
  personalPreferences: "Asmeniniai pasirinkimai",
  carTyres: "Padangos automobiliams, visureigiams ir komerciniam transportui",
  motorcyclesTyres: "Padangos motociklams",
  truckBusTyres: "Padangos sunkvežimiams ir autobusams",
  agricultureTyres: "Padangos žemės ūkio technikai",
  otrTyres: "OTR padangos",
  save: "Išsaugoti",
  businessRelationship: "Koks yra jūsų verslo santykis su Bridgestone?",
  selectRelationship: "Pasirinkite*:",
  tradingPartner: "Esu prekybos partneris, kuris...",
  tradingPartnerDirect: "... tiesiogiai užsako iš Bridgestone",
  tradingPartnerIndirect: "... netiesiogiai užsako (pvz., per didmenininkus)",
  tradingPartnerBoth: "... užsako tiek tiesiogiai, tiek netiesiogiai",
  fleetResponsibleDirect: "Esu atsakingas už autoparką, kuris...",
  fleetResponsibleIndirect:
    "... netiesiogiai užsako (per mažmenininkus ar didmenininkus)",
  fleetResponsibleBoth: "... užsako tiek tiesiogiai, tiek netiesiogiai",
  noBusinessRelationship:
    "<b>Neturiu verslo santykių su Bridgestone </b>(nei tiesiogiai, nei netiesiogiai)",
  yourCustomerNumber: "Jūsų Bridgestone klientų numeris*:",
  enterCustomerNumber: "Įveskite savo klientų numerį",
  waitPersonalisedView:
    "Prašome palaukti, jūsų asmeninis BRIDGESTONE PLUS vaizdas generuojamas.",
  personalised: "PRITAIKYTAS",
  plusInfo: "Čia rasite informaciją apie savo Plus paskyrą ir pašto dėžutę.",
  continue: "Tęsti",
  skip: "Praleisti",
  oneOfTwo: "1 iš 2",
  openApps:
    "Galite tiesiogiai atidaryti programėles, kuriose jau esate užsiregistravę. Taip pat galite prašyti prieigos prie kitų programėlių, kurios gali būti svarbios jums.",
  requiredFields:
    "Prašome užpildyti privalomus laukus: Kreipinys, Vardas, Pavardė",
  stage: "etapas",
  chooseOne: "(Pažymėkite bent vieną laukelį)",
  required: "*privalomi laukai",
  lengthCsNumber: "Kliento numerio ilgis: 6-10 skaitmenų",
  waitForAdminApproval:
    "Dėkojame, kad prisiregistravote dalyvauti Bridgestone Plus Rewards programoje. Šiuo metu peržiūrime jūsų paraišką. Kai jūsų paraiška bus patvirtinta, gausite patvirtinimo el. laišką.",
};

export default {
  messages,
};
