const messages = {
  dashboard: {
    title: "Aussendienst Dashboard",
    description:
      "Herzlichen willkommen auf Ihrem Dashboard. Hier können Sie aktuelle Kennzahlen und teilnehmende Betriebe aus Ihrer Kundschaft einsehen.",
    overview: {
      title: "Übersicht teilnehmende Kunden",
      description:
        'Betriebe, die sich bei BRIDGESTONE <span class="text-danger">PLUS</span> mit angemeldet haben und die wir über die Kundennummer Ihnen zuordnen können.',
      month: "Neu diesen Monat",
      year: "Neu dieses Jahr",
      all: "Gesamt",
    },
    credits: {
      title: "Punktegutschriften",
      description:
        "Prämienpunkte, die teilnehmenden Betrieben aufgrund der übermittelten Absatzzahlen zugewiesen haben.",
      month: "Letzter Monat",
      year: "Aktuelles Jahr",
      prev_year: "Gesamt",
      allDealers: "Alle Händlerpunkte",
      allPoints: "Alle Punkte",
    },
    details: {
      title: "Teilnehmende Kunden im Detail",
      description:
        'Nachfolgend sind die Betriebe aufgeführt, die sich mit einer Bridgestone Kundennummer auf BRIDGESTONE <span class="text-danger">PLUS</span> registriert haben und die Ihnen als Zuständigem/r zugewiesen sind. Nutzen Sie einfach Sortierfunktionen für eine andere Reifenfolge oder die Suchmaske.<br/><br/> <b>Hinweis:</b> Wenn Sie auf den Punktestand klicken, gelangen Sie zum detaillierten Kontoauszug.',
    },
    lastImport:
      'Letzter Import von Absatzzahlen: <br/> <span class="text-danger">{date}</span>',
    kickback: {
      description:
        "Den teilnehmenden Unternehmen werden Kickback-Gebühren auf Basis der übermittelten Umsatzzahlen gutgeschrieben.",
    },
    tyres: {
      title: "Reifen",
      description: "Bon Programmteilnehmern gekaufte Reifen",
    },
  },
};
export default {
  messages,
};
