const messages = {
  title: "Személyes számlakivonatom",
  desc: "Itt megtekintheted személyes Bridgestone Plus számlakivonatodat, a megszerzett és felhasznált bónuszpontokkal együtt. Kérjük, vedd figyelembe, hogy a gumiabroncs-rendelésekhez kapcsolódó pontok jóváírása akár 6 hetet is igénybe vehet, a forrástól függően.",
  score: "Pont-egyenleg",
  regularPoints: "Rendszeres pontok",
  boosterPoints: "Bónusz pontok",
  date: "Dátum",
  supplySourceReason: "Forrás/Vásárlás oka",
  article: "Cikk",
  brand: "Márka",
  product: "Termék",
  type: "Típus",
  inch: "Hüvelyk",
  season: "Évszak",
  amount: "Mennyiség",
  standardPoints: "Pontok",
  boosterProduct: "Bónusz termék",
  boosterSeason: "Bónusz évszak",
  otherActions: "Egyéb műveletek vagy okok",
  totalPoints: "Összes pont",
  income: "Bevétel",
  spending: "Kiadás",
  lastMonth: "Záró egyenleg",
  loadMore: "További 20 tranzakció betöltése",
  typeFilter: "Szűrés forrás szerint",
  dateFilter: "Szűrés időszak szerint",
  customer_number: "Ügyfélszám",
  currentPoints: "Aktuális pontok",
  calculationDate: "Számítási dátum",
  positivePoints: "Pozitív pontok",
  negativePoints: "Már elköltött",
  tyresAmount: "Gumiabroncsok száma évente",
  pointsTotal: "Összes pont",
  currentPointState: "Aktuális pontállomány",
  currentKickbackState: "Aktuális visszatérítés", // "Kickback" jelentése "visszarúgás", de ebben az esetben "visszatérítés" a pontosabb
  points: "Points",
};
export default {
  messages,
};
