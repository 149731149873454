const messages = {
  makeChoice: "Fai la tua scelta qui.",
  seeAll: "Vedi tutto",
  clear: "Cancella",
  back: "Indietro",
  save: "Salva",
  close: "Chiudi",
  of: "Di",
  to: "A",
  copyrights: "© {date} Bridgestone Solutions. Tutti i diritti riservati.",
  pageNotFound: "Pagina non trovata",
  accessDenied: "Accesso negato",
  search: "Cerca",
  buttons: {
    export: "Esporta",
    delete: "Elimina",
    save: "Salva",
    cancel: "Annulla",
    next: "Avanti",
    back: "Indietro",
    clear: "Cancella",
    skip: "Salta",
    send: "Invia",
    readNow: "Leggi ora",
    pointsList: "Elenco dei punti",
    more: "Di più",
    close: "Chiudi",
    reportError: "Segnala errori",
    accept: "Accettare",
    download: "Download",
    dealerInfoDownload: "Download",
    toTheBasket: "To basket",
    toOrderDelivery: "Continue to the delivery address",
    discover: "Discover",
    newAddress: "Create new delivery address",
    selectAddressBtn: "Select delivery address",
    toOrderSummary: "To the order overview",
    placeOrder: "Binding order",
    toDashboard: "To homepage",
    newOrder: "New order",
    userOrders: "To your orders",
    toSearch: "Search",
    select: "Select",
  },
  tooltips: {
    info: "Hai bisogno di aiuto?",
  },
  confirmation: {
    header: "Conferma richiesta",
    message: "Vuoi eliminare questa voce?",
  },
  nav: {
    dashboard: "Home",
    account: "Il mio profilo",
    mailbox: "Posta in arrivo",
    singleMailbox: "Messaggi",
    stammdaten: "Anagrafica",
    news_feeds: "Notizia",
    pointsOverview: "Points overview",
    about: "Informazioni su BS Plus",
    service_number: "Contact service",
    shop: "Negozio a premi",
    salesPromotions: "Sales promotions",
    shopCategory: "Rewards shop",
    productDetails: "Product details",
    userOrders: "User orders",
    shoppingCart: "Shopping cart",
    orderDelivery: "Shipping address",
    orderSummary: "Order overview",
    orderComplete: "Order complete",
  },
  menu: {
    account: "Il mio profilo",
    plus: 'Informazioni su Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Supporto alle vendite",
    mailbox: "Posta in arrivo",
    about: "Informazioni su BS Plus",
    trainingAndCourses: "Formazione e corsi",
    externalTraining: "Formazione esterna",
    productInfo: "Informazioni sul prodotto",
    productBrochures: "Brochure dei prodotti",
    priceLists: "Listini prezzi",
    price: "Prezzo",
    complaintForm: "Modulo di reclamo",
    sales_support: "Supporto alle vendite",
    contact_service: "Informazioni di contatto e servizio",
    logOut: "Disconnetti",
    news: "Notizie",
    news_feeds: "Notizia",
    service_number: "Contact service",
    salesPromotions: "Sales promotions",
    shop: "Shop",
  },
  validation: {
    requiredField: "Campo obbligatorio",
  },
};

export default {
  messages,
};
