const messages = {
  seeAll: "Ver todo",
  clear: "Eliminar",
  back: "Atrás",
  save: "Guardar",
  close: "Cerrar",
  of: "De",
  to: "A",
  copyrights: "© {date} Bridgestone. Todos los derechos reservados.",
  pageNotFound: "Página no encontrada",
  accessDenied: "Acceso denegado",
  search: "Buscar",
  buttons: {
    export: "Exportar",
    delete: "Eliminar",
    save: "Guardar",
    cancel: "Cancelar",
    next: "Siguiente",
    back: "Atrás",
    skip: "Saltar",
    send: "Enviar",
    clear: "Borrar",
    close: "Cerca",
    readNow: "Lee ahora",
    pointsList: "Lista de puntos",
    more: "Más",
    reportError: "Informar error",
    accept: "Aceptar",
    download: "Descargar",
    dealerInfoDownload: "Descargar",
    toTheBasket: "A la cesta",
    toOrderDelivery: "Continuar a la dirección de entrega",
    discover: "Descubrir",
    newAddress: "Create new delivery address",
    selectAddressBtn: "Select delivery address",
    toOrderSummary: "To the order overview",
    placeOrder: "Binding order",
    toDashboard: "To homepage",
    newOrder: "New order",
    userOrders: "To your orders",
    toSearch: "Search",
    select: "Select",
  },
  tooltips: {
    info: "¿Necesitas ayuda?",
  },
  confirmation: {
    header: "Confirmación necesaria",
    message: "¿Deseas eliminar esta entrada?",
  },
  nav: {
    dashboard: "Inicio",
    account: "Mi Perfil",
    mailbox: "Bandeja de entrada",
    singleMailbox: "Mensajes",
    stammdaten: "Mis datos",
    news_feeds: "Noticias",
    pointsOverview: "Mis puntos",
    about: "Todo sobre BRIDGESTONE",
    service_number: "Contactos y Servicios",
    shop: "Tienda de recompensas",
    salesPromotions: "Promociones de ventas",
    shopCategory: "Tienda de recompensas",
    productDetails: "Detalles del producto",
    userOrders: "Órdenes de usuario",
    shoppingCart: "Carro de la compra",
    orderDelivery: "Shipping address",
    orderSummary: "Order overview",
    orderComplete: "Order complete",
  },
  menu: {
    account: "Mi Perfil",
    plus: 'Sobre Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Soporte de Ventas",
    mailbox: "Bandeja de entrada",
    about: "Todo sobre BRIDGESTONE",
    trainingAndCourses: "Formación  y Cursos",
    externalTraining: "Formación Externa",
    productInfo: "Información del Producto",
    productBrochures: "Folletos de Productos",
    priceLists: "Listas de Precios",
    price: "Precio",
    complaintForm: "Formulario de Quejas",
    sales_support: "Soporte de Ventas",
    contact_service: "Contacto y Servicio",
    logOut: "Cerrar sesión",
    news: "Noticias",
    news_feeds: "Noticias",
    service_number: "Contactos y Servicios",
    salesPromotions: "Sales promotions",
    shop: "Shop",
  },
  validation: {
    requiredField: "Campo obligatorio",
  },
  makeChoice: "Tomar una decisión",
};

export default {
  messages,
};
