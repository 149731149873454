const messages = {
  title: "Promociones de Venta",
  desc: "Utilice activamente nuestras promociones de venta con sus clientes. Por supuesto, puede ofrecer a los clientes ambas promociones. Lea más sobre la Garantía de Neumáticos y Drive Our Best a continuación.",
  participation: {
    title: "Solicite su Participación Ahora",
    desc: "¿Le gustaría implementar una o más de nuestras promociones en su tienda? Entonces puede solicitar aquí su participación. Su empresa será activada para la(s) promoción(es) y recibirá materiales promocionales dentro de 4 semanas materiales promocionales para su sueño de ventas.<br/><br/>Haga su elección:",
    request: "SOLICITAR PARTICIPACIÓN",
    success: "Thank you, your request has been sent",
  },
};
export default {
  messages,
};
