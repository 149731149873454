const messages = {
  title: "Home",
  welcomeMessage:
    'Benvenuto in BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Home",
  search: "Ricerca",
  sortBy: "Ordina per",
  seeMore: "Vedi di più",
  bankstatement: {
    yourScore: "Il tuo attuale numero di punti marketing:",
    lastUpdate: "Ultimo aggiornamento:",
    link: "Panoramica del saldo punti",
  },
  shop: {
    bestSold: "I più acquistati",
    seasonal: "Stagionale",
    affordable: "Ci siamo quasi",
    forPoints: "per {points} punti",
    reachedPoints: "Punteggio richiesto raggiunto",
    cartRemainPoints: "Punti rimanenti",
    remainingPoints: "{points} Punti rimanenti",
    noProducts: "I criteri di ricerca non hanno restituito alcun risultato",
  },
  showAllProducts: "Al negozio dei premi",
};

export default {
  messages,
};
