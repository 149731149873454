const messages = {
  title: "Strona główna",
  welcomeMessage:
    'Witamy w BRIDGESTONE <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  home: "Strona główna",
  search: "Wyszukiwanie",
  sortBy: "Sortuj według",
  seeMore: "Zobacz więcej",
  bankstatement: {
    yourScore: "Liczba punktów:",
    lastUpdate: "Ostatnia aktualizacja:",
    link: "Zobacz saldo",
  },
  shop: {
    bestSold: "Najlepiej sprzedawane",
    seasonal: "Sezonowe",
    affordable: "Przystępne cenowo",
    forPoints: "za {points} punktów",
    reachedPoints: "Osiągnięto wymaganą liczbę punktów",
    cartRemainPoints: "Pozostałe punkty",
    remainingPoints: "Brakuje jeszcze {points} punktów",
    noProducts: "Brak produktów",
  },
  showAllProducts: "Zobacz wszystko",
};

export default {
  messages,
};
