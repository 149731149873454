<template>
  <MDBRow>
    <MDBCol col="12" lg="12" xl="7">
      <div class="video">
        <iframe class="responsive-iframe" src="https://player.vimeo.com/video/916661616" allow="fullscreen" title=""
          data-ready="true" tabindex="-1"></iframe>
      </div>
    </MDBCol>
    <MDBCol col="12" lg="12" xl="5">
      <MDBCard class="h-100">
        <MDBCardBody class="infoBox">
          <div>
            <MDBCardTitle class="font-20 text-uppercase">{{
              $t("onlineVisibility.uberall.title")
              }}</MDBCardTitle>
            <MDBCardTitle class="font-20 text-uppercase"><span class="text-success">480</span>
              {{ $t("bankstatement.points") }}</MDBCardTitle>
            <ul class="pb-5">
              <li class="font-16">{{ $t("onlineVisibility.form.point1") }}</li>
              <li class="font-16">{{ $t("onlineVisibility.period") }}</li>
              <li class="font-16">{{ $t("onlineVisibility.form.point2") }}</li>
              <li class="font-16">{{ $t("onlineVisibility.form.point3") }}</li>
            </ul>
          </div>

          <div class="d-flex align-items-center w-100">
            <MDBBtn color="danger" class="me-5" :disabled="loading" @click="onSubmit()">{{ $t("buttons.sendRequest") }}
            </MDBBtn>
            <MDBBtn color="danger" :disabled="loading" @click="redirectToProduct()">{{ $t("buttons.directBuy") }}
            </MDBBtn>
          </div>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  </MDBRow>
  <MDBRow class="pt-5">
    <MDBCol col="12" lg="12" xl="7" class="pe-0">
      <div class="bg-white h-100 ps-2">
        <p class="pt-3">{{ $t("onlineVisibility.content") }}</p>
        <ul>
          <li>{{ $t("onlineVisibility.point1") }}</li>
          <li>{{ $t("onlineVisibility.point2") }}</li>
          <li>{{ $t("onlineVisibility.point3") }}</li>
        </ul>
      </div>
    </MDBCol>
    <MDBCol col="12" lg="12" xl="5" class="ps-0">
      <div class="bg-white h-100 ps-2">
        <p class="pt-3">{{ $t("haveQuestion") }}</p>
        <div class="pt-2">
          {{ $t("contactUs") }}
          <a href="mailto:plus@bridgestone.eu">plus@bridgestone.eu</a>.
        </div>
      </div>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import {
  MDBRow,
  MDBBtn,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
} from "mdb-vue-ui-kit";

import { useRouter } from "vue-router";

import { ref, computed } from "vue";
import { useStore } from "vuex";
import { OrderService } from "@/services/order.service";
import i18n from "@/plugins/i18n";
const store = useStore();
const router = useRouter();

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const user = computed(() => store.getters.getMe);
const apiError = ref(null);

const loading = computed(() => store.getters.getPending);

const onSubmit = async () => {
  apiError.value = null;
  const data = {
    user_id: user.value.id,
    points: 0,
    price: 0,
  };

  const response = await OrderService.storeUberall(data);

  if (response?.data.success) {
    await store.dispatch("me");

    toast(i18n.global.t("toast.requestSent"), {
      type: "success",
      theme: "colored",
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } else {
    apiError.value = response.data.message;
  }
};

const redirectToProduct = async () => {
  const response = await OrderService.getProductBySlug("uberall");

  router.push({
    name: "productDetails",
    params: {
      id: response?.data.product_id,
    },
  });
};
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
@import "@/styles/mailbox.scss";

.video {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.infoBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
