import ApiService from "./api.service";

const GroupService = {
  async parentAttributes(id) {
    try {
      return await ApiService.get(`parent-attributes/${id}`);
    } catch (error) {
      return error.response;
    }
  },

  async saveUpdateParentAttribute(data) {
    try {
      return await ApiService.post("parent-attribute/assign", data);
    } catch (error) {
      return error;
    }
  },

  async businessUnits() {
    try {
      return await ApiService.get("business-units");
    } catch (error) {
      return error;
    }
  },

  async parentAttributeAsignSelector(data) {
    try {
      return await ApiService.post("parent-attribute/assign/selector", data);
    } catch (error) {
      return error;
    }
  },
};

export { GroupService };
