const messages = {
  title: "Mein Profil",
  desc: "Willkommen in Ihrem persönlichen Bereich. Hier können Sie Ihre Daten aktualisieren oder anpassen und Ihren Posteingang einsehen.",
  descStaff:
    "Willkommen in Ihrem persönlichen Bereich. Hier können Sie Ihre Daten aktualisieren oder anpassen.",
  buttons: {
    showProfile: "Profil anzeigen",
    userProfile: "ZUM STAMMDATEN",
    mailbox: "ZUM POSTEINGANG",
  },
  categories: {
    baseData: {
      title: "Stammdaten",
      desc: "Vervollständigen Sie Ihre persönlichen und Unternehmensdaten für eine korrekte Registrierung.",
    },
    mailbox: {
      desc: "Empfangen Sie wichtige Nachrichten, Bestellbestätigungen und Neuigkeiten in Ihrem Posteingang.",
    },
    bankstatement: {
      title: "Kontoauszug",
      desc: "Hier können Sie den Status Ihres persönlichen Bridgestone Plus-Kontos einsehen und Ihre gesammelten und verwendeten Prämienpunkte überprüfen.",
    },
  },
  profile: {
    general: "PERSÖNLICHE DATEN UNTERNEHMENSDATEN",
    title: "Stammdaten",
    description:
      "Willkommen in Ihrem persönlichen Bereich. Hier können Sie deine Daten aktualisieren oder anpassen.",
    block_description:
      "Willkommen in Ihrem persönlichen Bereich. Hier können Sie Ihre Daten einsehen",
    personalData: "PERSÖNLICHE DATEN",
    password: "Passwort",
    password1: "Passwort (optional)*",
    password2: "Passwort (wiederholen)*",
    formOfAddress: "Anrede",
    choose: "Anrede auswählen",
    mr: "Herr",
    mrs: "Frau",
    diverse: "Sonstiges",
    firstname: "Vorname",
    surname: "Nachname",
    position: "POSITION IM UNTERNEHMEN",
    operatingData: "UNTERNEHMENSDATEN",
    company: "Unternehmen",
    legalForm: "Rechtsform",
    name: "Name",
    streetHouseNr: "Straße und Huasnummer",
    street: "Straße",
    postcode: "Postleitzahl",
    location: "Stadt",
    country: "Land",
    de: "Deutschland",
    at: "Österreich",
    ch: "Schweiz",
    nl: "Niederlande",
    it: "Italien",
    language: "Sprache",
    french: "Französisch",
    italian: "Italienisch",
    german: "Deutsch",
    nederland: "Niederländisch",
    openingHours: "Öffnungszeiten & Kontakt",
    phone: "Telefon",
    website: "Website",
    operatingIndicators: "Betriebskennzahlen",
    tireSale: "Jährlicher Reifenverkauf ca. in Stückzahlen",
    tireSaleLabel: "Reifenverkauf in großen Mengen",
    inches: "Davon ≥ 18 Zoll (in %)",
    inchesLabel: "Davon 18 Zoll oder mehr in %",
    segmentSale:
      "Reifenverkauf nach Segment (Anteil Budget / Mittelklasse / Premium in %)",
    premBudget: "Premium",
    midBudget: "Mittelklasse",
    budBudget: "Budget",
    distribution: "VERTEILUNG SOMMER / WINTER / GANZJAHR (in %)",
    tyreSizes: "Anzahl der Saisonlagerplätze (Reifensätze)",
    diffDeliveryAddres: "Lieferadresse hinzufügen",
    yourEmailAddress: "Deine E-Mail-Adresse.",
    clientNr: "Kundennummern",
    clientNrSingular: "Kundennummer",
    clientNrContent:
      "<span class='text-danger fw-bold'>WICHTIG - BITTE LESEN:</span><br /><br /> Für die korrekte Zuordnung Ihrer Reifenbestellungen bei einem Großhändler und/oder Bridgestone müssen Sie <b>Ihree jeweiligen Kundennummern angeben.</b><br/><br/>Falls Sie <b>mehrere Kundennummern bei einem Großhändler und/oder Bridgestone haben,</b> beispielsweise für Ihr Hauptgeschäft und Filialen, geben Sie bitte zuerst die Kundennummer des Hauptgeschäfts an.<br/><br/><b>Der Verkauf der anderen Kundennummern wird dann Ihrem Hauptgeschäft zugeordnet.</b>",
    supplySource: "Bezugsquelle",
    email: "E-Mail-Adresse",
    staffEmail: "E-Mail-Adresse des Teilnehmers",
    registered: "Registriert am",
    registeredAt: "Registriert am",
    lastLogIn: "Letzter Login",
    details: "Details",
    inDetail: "{name} im Detail",
    bsClientNr: "Bridgestone Kundennummer",
    bftClientNr: "Best4Tires Kundennummer",
    acceptTerms: "Akzeptieren Sie die Teilnahmebedingungen.",
    newCustomerNumbersInfo:
      "<span class='text-danger fw-bold'>WICHTIG - BITTE LESEN:</span><br />Bei der ersten Eingabe der Kundennummer werden Ihre Daten auf Genauigkeit und Erfüllung unserer Verteilungskriterien überprüft. Die Entscheidung, die Kundennummer(n) zu aktivieren und mit Ihrem Profil zu verknüpfen, kann bis zu 5 Werktage in Anspruch nehmen.",
    selectWholeSaler: "beim Lieferanten (bitte wählen)",
    cooperation: "MITGLIED / ZUSAMMENARBEIT",
    error100: "Die Summe der Werte muss 100% betragen.",
    errorRange: "Der Wert muss zwischen 0 und 100 liegen",
    btw_number_label: "Unternehmens-USt-IdNr.",
    btw_number: "Umsatzsteuer-Identifikationsnummer",
    mobile: "Telefonnummer",
    mobile_label: "Deine Mobiltelefonnummer",
    iban_number: "Deine Unternehmens-IBAN",
    iban_number_label: "IBAN-Nummer",
    yourCsNr: "Kundennummern",
    yourLastName: "Nachname",
    positionLabel: "Wählen Sie eine Position",
    yourFirstName: "Vorname",
    companyLabel: "Unternehmen",
    numberOfSetsOfSeasonalTiresBudget: "Jährliche Lagerung",
    contactInfo: "Wenn Sie Fragen haben, kontaktieren Sie uns bitte:",
    addAnotherCsNumber: "Bridgestone Kundennummer hinzufügen",
    activityAndProfile: "GESCHÄFTSBEZIEHUNG UND PROFIL",
  },
  countries: {
    al: "Albania",
    ad: "Andorra",
    at: "Austria",
    be: "Belgium",
    by: "Belarus",
    ba: "Bosnia and Herzegovina",
    bg: "Bulgaria",
    hr: "Croatia",
    cy: "Cyprus",
    cz: "Czechia",
    dk: "Denmark",
    ee: "Estonia",
    fi: "Finland",
    fr: "France",
    ge: "Georgia",
    gr: "Greece",
    es: "Spain",
    nl: "Netherlands",
    ie: "Ireland",
    is: "Iceland",
    kz: "Kazakhstan",
    xk: "Kosovo",
    li: "Liechtenstein",
    lt: "Lithuania",
    lu: "Luxembourg",
    lv: "Latvia",
    mk: "North Macedonia",
    mt: "Malta",
    md: "Moldova",
    mc: "Monaco",
    de: "Germany",
    no: "Norway",
    pl: "Poland",
    pt: "Portugal",
    ru: "Russia",
    ro: "Romania",
    sm: "San Marino",
    rs: "Serbia",
    sk: "Slovakia",
    si: "Slovenia",
    se: "Sweden",
    ch: "Switzerland",
    tr: "Turkey",
    ua: "Ukraine",
    hu: "Hungary",
    gb: "United Kingdom",
    it: "Italy",
    va: "Vatican City",
  },
};

export default {
  messages,
};
