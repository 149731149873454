const messages = {
  title: "Promoções de Venda",
  desc: "Utilize ativamente as nossas promoções de venda com os seus clientes. Pode, naturalmente, oferecer ambas as promoções aos clientes. Leia mais sobre a Garantia de Pneus e o Drive Our Best abaixo.",
  participation: {
    title: "Solicite a Participação Agora",
    desc: "Gostaria de implementar uma ou mais das nossas promoções na sua loja? Então pode solicitar a participação aqui. A sua empresa será ativada para a(s) promoção(ões) e receberá material promocional dentro de 4 semanas, material promocional para o seu sonho de vendas.<br/><br/>Faça a sua escolha:",
    request: "SOLICITAR PARTICIPAÇÃO",
    success: "Obrigado, a sua solicitação foi enviada",
  },
};
export default {
  messages,
};
