const messages = {
  titleColorRev: "totta",
  buttons: {
    pending: "Vireillä",
    open: "Avaa",
    requestAccess: "Pyydä pääsyä",
  },
  myBsApps: '<span class="text-danger">Omat sovellukset</span> Bridgestone',
  my: "Omat sovellukset",
  directRoute: "Suora reitti kaikkiin Bridgestonen verkkosovelluksiin.",
  welcomeMessage:
    'Tervetuloa BRIDGESTONE PLUS <span class="text-danger">PLUS</span>, %{first_name} %{last_name}',
  showProfile: "Näytä profiili",
  newMessages: "Uudet viestit saapuneet-kansiossa",
  myApps: "Omat sovellukset",
  allApps: "Kaikki sovellukset ",
  myAppsDescription:
    "Kaikki Bridgestonen verkkosovellukset, joihin olet jo rekisteröitynyt.",
  allAppsDescription:
    "Kaikki Bridgestonen verkkosovellukset, jotka saattavat kiinnostaa sinua. ",
  myProfile: {
    welcomeMessage:
      "Tervetuloa henkilökohtaiselle alueellesi. Täällä voit päivittää tai muuttaa tietojasi ja tarkastella postilaatikkoasi.",
    masterData: "Master Dataan",
    completeDetails: "Täytä henkilö- ja yritystietosi  rekisteröintiä varten.",
    goToMasterData: "Siirry Master Dataan",
    goToInbox: "Siirry saapuneet-kansioon",
  },
  masterData: {
    welcomeMessage:
      "Tervetuloa henkilökohtaiselle alueellesi. Täällä voit päivittää tai muuttaa tietojasi.",
    personalDetails: "Henkilökohtaiset tiedot",
    companyDetails: "Yrityksen tiedot",
    emailAddress: "Sähköpostiosoite",
    title: "Otsikko",
    surname: "Sukunimi",
    firstName: "Etunimi",
    jobPosition: "Työtehtävä",
    streetAndHouseNumber: "Katuosoite",
    postalCode: "postinumero",
    city: "Kaupunki",
    country: "Maa",
    legalStructure: "Oikeudellinen rakenne",
    customerNumber: "Bridgestone Asiakasnumero",
    importantPleaseRead: "Tärkeää lukea",
    customerNumberInfo:
      "Jotta rengastilauksesi voidaan kohdistaa asianmukaisesti tukkukauppiaille ja/tai Bridgestonelle, on tärkeää, että annat asiakasnumerosi.",
    multipleCustomerNumbers:
      "Jos sinulla on useita asiakasnumeroita tukkukauppiaalla ja/tai Bridgestonella, esimerkiksi pääliiketoiminnallesi ja sivuliikkeillesi, ilmoita ensin pääliiketoiminnan asiakasnumero.",
    salesAssignment:
      "Lisäasiakasnumeroihin liittyvä myynti osoitetaan sitten ensisijaiselle asiakasnumerollesi.",
    supplier: "Toimittaja",
    addAnotherCustomerNumber: "Lisää toinen Bridgestonen asiakasnumero",
    save: "Tallenna",
    delete: "Poista",
  },
  inbox: {
    welcomeMessage: "Täältä löydät tärkeitä ilmoituksia ja uutisia.",
    sender: "Lähettäjä",
    subject: "Aihe",
    date: "Päivämäärä",
    formConsulting: "Form Consulting - Kiitos lomakkeen käsittelystä",
    back: "Takaisin",
  },
  legalAndContact: {
    cookieSettings: "Evästeasetukset",
    dataPolicy: "Tietosuojakäytäntö",
    termsOfUse: "Käyttöehdot",
    helpAndContact: "Ohjeet ja yhteystiedot",
  },
  logInSignUp: {
    logIn: "Kirjaudu sisään",
    signUp: "Rekisteröidy",
    logInMessage: "Kirjaudu sisään sähköpostiosoitteellasi ja salasanallasi.",
    signUpMessage: "Jos olet uusi käyttäjä, rekisteröidy.",
  },
};

export default {
  messages,
};
