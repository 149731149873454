const messages = {
  title: "Mano profilis",
  desc: "Sveiki atvykę į savo asmeninę zoną. Čia galite atnaujinti arba redaguoti savo <br/>duomenis ir peržiūrėti savo pašto dėžutę.",
  descStaff:
    "Sveiki atvykę į savo asmeninį profilį. Čia galite atnaujinti arba redaguoti savo duomenis.",
  buttons: {
    showProfile: "Rodyti profilį",
    userProfile: "EITI Į PAGRINDINIUS DUOMENIS",
    mailbox: "EITI Į PAŠTO DĖŽUTĘ",
  },
  categories: {
    baseData: {
      title: "Pagrindiniai duomenys",
      desc: "Užpildykite savo asmeninius ir įmonės duomenis, kad būtų atlikta teisinga registracija.",
    },
    mailbox: {
      desc: "Gaukite svarbius pranešimus, užsakymų patvirtinimus ir naujienas savo pašto dėžutėje.",
    },
    bankstatement: {
      title: "Sąskaitos išrašas",
      desc: "Čia galite peržiūrėti savo asmeninės Bridgestone Plus paskyros būklę ir patikrinti sukauptus bei panaudotus taškus.",
    },
  },
  profile: {
    general: "ASMENINIAI/ĮMONĖS DUOMENYS",
    title: "Pagrindiniai duomenys",
    description:
      "Sveiki atvykę į savo asmeninį profilį. Čia galite atnaujinti arba redaguoti savo duomenis.",
    block_description:
      "Sveiki atvykę į jūsų asmeninę sritį. Čia galite peržiūrėti savo duomenis",
    personalData: "ASMENINIAI DUOMENYS",
    password: "Slaptažodis",
    password1: "Slaptažodis (neprivaloma)*",
    password2: "Pakartokite slaptažodį*",
    formOfAddress: "Kreipinys",
    choose: "Pasirinkite kreipinį",
    mr: "Ponas",
    mrs: "Ponia",
    diverse: "Kita",
    firstname: "Vardas",
    surname: "Pavardė",
    position: "PAREIGOS ĮMONĖJE",
    operatingData: "ĮMONĖS DUOMENYS",
    company: "Įmonė",
    legalForm: "Teisinė forma",
    name: "Pavadinimas",
    streetHouseNr: "Įmonės adresas",
    street: "Gatvė",
    postcode: "Pašto kodas",
    location: "Miestas",
    country: "Šalis",
    de: "Vokietija",
    at: "Austrija",
    ch: "Šveicarija",
    nl: "Nyderlandai",
    it: "Italija",
    language: "Kalba",
    french: "Prancūzų",
    italian: "Italų",
    german: "Vokiečių",
    nederland: "Olandų",
    openingHours: "Darbo valandos ir kontaktai",
    phone: "Telefonas",
    website: "Interneto svetainė",
    operatingIndicators: "Veiklos rodikliai",
    tireSale: "Metinis padangų pardavimas (vnt.)",
    tireSaleLabel: "Padangų pardavimas dideliais kiekiais",
    inches: "Iš jų ≥ 18 colių (%)",
    inchesLabel: "Iš jų 18 colių ar daugiau (%)",
    segmentSale:
      "Padangų pardavimas pagal segmentą (Biudžetas / Vidutinis / Premium procentais)",
    premBudget: "Premium",
    midBudget: "Vidutinis",
    budBudget: "Biudžetas",
    distribution: "PASKIRSTYMAS VASARA / ŽIEMA / VISUS METUS (%)",
    tyreSizes: "Sezoninių padangų saugojimo vietų skaičius (komplektai)",
    diffDeliveryAddres: "Pridėti pristatymo adresą",
    yourEmailAddress: "Jūsų el. pašto adresas.",
    clientNr: "Klientų numeriai",
    clientNrSingular: "Kliento numeris",
    clientNrContent:
      "<span class='text-danger fw-bold'>SVARBU - PRAŠOME SKAITYTI:</span><br /><br /> Norint tinkamai priskirti jūsų padangų pirkimus didmenininkui ir/arba Bridgestone, būtina <b>nurodyti savo atitinkamus klientų numerius.</b><br/><br/>Jei turite <b>kelis klientų numerius pas didmenininką ir/arba Bridgestone,</b> pvz., savo pagrindinei parduotuvei ir filialams, pirmiausia nurodykite pagrindinės parduotuvės klientų numerį.<br/><br/><b>Kitų klientų numerių pardavimai bus priskirti pagrindinei parduotuvei.</b>",
    supplySource: "Tiekimo šaltinis",
    email: "El. pašto adresas",
    staffEmail: "Dalyvio el. pašto adresas",
    registered: "Registruota",
    registeredAt: "Registruota",
    lastLogIn: "Paskutinis prisijungimas",
    details: "Išsami informacija",
    inDetail: "{name} išsamiai",
    bsClientNr: "Jūsų Bridgestone klientų numeris",
    bftClientNr: "Jūsų Best4Tires klientų numeris",
    acceptTerms: "Sutikite su dalyvavimo sąlygomis.",
    newCustomerNumbersInfo:
      "<span class='text-danger fw-bold'>SVARBU - PRAŠOME SKAITYTI:</span><br />Pirmą kartą įvedant klientų numerį jūsų duomenys bus tikrinami dėl tikslumo ir mūsų platinimo kriterijų atitikties. Sprendimas aktyvuoti klientų numerį(ius) ir susieti juos su jūsų profiliu gali užtrukti iki 5 darbo dienų.",
    selectWholeSaler: "pas tiekėją (pasirinkite)",
    cooperation: "NARYSTĖ / BENDRADARBIAVIMAS",
    error100: "Reikšmių suma turi būti lygi 100%.",
    errorRange: "Reikšmė turi būti tarp 0 ir 100",
    btw_number_label: "jūsų įmonės PVM numeris",
    btw_number: "PVM registracijos numeris",
    mobile: "Telefono numeris",
    mobile_label: "Jūsų mobiliojo telefono numeris",
    iban_number: "Jūsų įmonės IBAN numeris",
    iban_number_label: "IBAN numeris",
    yourCsNr: "Jūsų klientų numeriai",
    yourLastName: "Jūsų pavardė",
    positionLabel: "Pasirinkite pareigas",
    yourFirstName: "Jūsų vardas",
    companyLabel: "Įmonė",
    numberOfSetsOfSeasonalTiresBudget: "Metinė saugykla",
    contactInfo: "Jei turite klausimų, susisiekite su mumis:",
    addAnotherCsNumber: "Pridėti Bridgestone klientų numerį",
    activityAndProfile: "VERSLO SANTYKIAI IR PROFILIS",
  },
  countries: {
    al: "Albania",
    ad: "Andorra",
    at: "Austria",
    be: "Belgium",
    by: "Belarus",
    ba: "Bosnia and Herzegovina",
    bg: "Bulgaria",
    hr: "Croatia",
    cy: "Cyprus",
    cz: "Czechia",
    dk: "Denmark",
    ee: "Estonia",
    fi: "Finland",
    fr: "France",
    ge: "Georgia",
    gr: "Greece",
    es: "Spain",
    nl: "Netherlands",
    ie: "Ireland",
    is: "Iceland",
    kz: "Kazakhstan",
    xk: "Kosovo",
    li: "Liechtenstein",
    lt: "Lithuania",
    lu: "Luxembourg",
    lv: "Latvia",
    mk: "North Macedonia",
    mt: "Malta",
    md: "Moldova",
    mc: "Monaco",
    de: "Germany",
    no: "Norway",
    pl: "Poland",
    pt: "Portugal",
    ru: "Russia",
    ro: "Romania",
    sm: "San Marino",
    rs: "Serbia",
    sk: "Slovakia",
    si: "Slovenia",
    se: "Sweden",
    ch: "Switzerland",
    tr: "Turkey",
    ua: "Ukraine",
    hu: "Hungary",
    gb: "United Kingdom",
    it: "Italy",
    va: "Vatican City",
  },
};
export default {
  messages,
};
