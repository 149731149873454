const messages = {
  title: 'Bienvenido a BRIDGESTONE <span class="text-danger">PLUS</span>',
  termsAndConditions: "Términos y condiciones",
  accept: "Aceptar",
  decline: "Rechazar",
  dataModal:
    "Por favor, rellena tus datos personales y los de tu empresa para la creación de tu cuenta.",
  scrollToAccept:
    "Por favor, sigue leyendo para aceptar nuestras condiciones y continuar.",
  welcomeMessage: "Bienvenido a BRIDGESTONE PLUS",
  tailorOffer:
    "Para poder ofrecerte contenido de valor, te pedimos que indiques brevemente tus intereses en términos de productos o áreas de negocio.",
  bussinessType:
    "Por favor, indícanos cuál es tu relación comercial con Bridgestone",
  selectAreas:
    "Por favor, selecciona de las siguientes áreas aquella/s que sea/n de tu interés*",
  multipleAnswers: "(es posible seleccionar varias opciones):",
  personalPreferences: "Preferencias personales",
  carTyres: "Neumáticos para coches, SUV, vehículos utilitarios",
  motorcyclesTyres: "Neumáticos para motocicletas",
  truckBusTyres: "Neumáticos para camiones y autobuses",
  agricultureTyres: "Neumáticos para vehículos agrícolas",
  otrTyres: "Neumáticos OTR",
  save: "Guardar",
  businessRelationship: "¿Cuál es tu relación comercial con Bridgestone?*",
  selectRelationship: "Por favor, selecciona de las siguientes opciones:",
  tradingPartner: "Soy un socio comercial que está...",
  tradingPartnerDirect: "... realizando pedidos directamente a Bridgestone",
  tradingPartnerIndirect:
    "... realizando pedidos de manera indirecta (a través de mayoristas, por ejemplo)",
  tradingPartnerBoth:
    "... realizando pedidos tanto directamente como indirectamente",
  fleetResponsibleDirect: "Soy responsable de flotas que está...",
  fleetResponsibleIndirect:
    " ... realizando pedidos de manera indirecta (a través del comercio o mayoristas)",
  fleetResponsibleBoth:
    "... realizando pedidos tanto directamente como indirectamente",
  noBusinessRelationship:
    "<b>No tengo relación comercial con Bridgestone </b>(ni directamente ni indirectamente)",
  yourCustomerNumber: "Tu número de cliente de Bridgestone*:",
  enterCustomerNumber: "Ingresa tu número de cliente",
  waitPersonalisedView:
    "Por favor, espera un momento mientras se genera tu vista personalizada de BRIDGESTONE PLUS.",
  personalised: "Personalizado",
  plusInfo:
    "Aquí encontrarás información sobre su cuenta Plus y tu bandeja de entrada.",
  continue: "Continuar",
  skip: "Omitir",
  oneOfTwo: "1 de 2",
  openApps:
    "Puedes abrir directamente las aplicaciones para las que ya estás registrado. También puedes solicitar acceso a otras aplicaciones que puedan ser relevantes para ti.",
  requiredFields: "Rellene los campos obligatorios: Título, Nombre, Apellidos",
  stage: "Paso",
  chooseOne: "(Assinalar pelo menos 1 quadrícula)",
  required: "*campos obligatorios",
  lengthCsNumber: "Por favor, incluye un valor entre 6-10 dígitos",
  termsAndConditionsModal: {
    direct: {
      title:
        "“Términos y Condiciones” y “Política de Privacidad” general de participación ",
      description:
        "Después de aceptar los “Términos y Condiciones” y la “Política de Privacidad” podrás encontrarlos en tu Buzón personal de Bridgestone Plus. ",
    },
    indirect: {
      title:
        "Términos y Condiciones” y “Política de Privacidad” general de participación",
      description:
        "Después de aceptar los “Términos y Condiciones” y la “Política de Privacidad” podrás encontrarlos en tu Buzón personal de Bridgestone Plus. ",
    },
    options: {
      privacyPolicy: "Accept privacy policy",
      termsAndCons: "Accept terms and cons",
    },
  },
  privacyPolicyModal: {
    direct: {
      title: "POLÍTICA DE PRIVACIDAD",
      description:
        "Después de aceptar los “Términos y Condiciones” y la “Política de Privacidad” podrás encontrarlos en tu Buzón personal de Bridgestone Plus. ",
    },
    indirect: {
      title:
        "“Términos y Condiciones” y “Política de Privacidad” general de participación ",
      description:
        "Después de aceptar los “Términos y Condiciones” y la “Política de Privacidad” podrás encontrarlos en tu Buzón personal de Bridgestone Plus. ",
    },
    options: {
      privacyPolicy: "Accept privacy policy",
      termsAndCons: "Accept terms and cons",
    },
  },
  waitForAdminApproval:
    "Gracias por unirte al programa Bridgestone Plus Rewards. Actualmente estamos revisando tu solicitud. Recibirás un correo electrónico de confirmación una vez que tu solicitud haya sido aprobada.",
};

export default {
  messages,
};
