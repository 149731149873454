const messages = {
  title: "Mon relevé de compte personnel",
  desc: "Vous pouvez consulter ici votre relevé de compte personnel Bridgestone Plus avec vos points bonus gagnés et utilisés. Veuillez noter que l'attribution des points pour vos commandes de pneus peut prendre jusqu'à 6 semaines, selon la source.",
  score: "Solde de points",
  regularPoints: "Points standard",
  boosterPoints: "Points bonus",
  date: "Date",
  supplySourceReason: "Source/Raison de l'achat",
  article: "Article",
  brand: "Marque",
  product: "Produit",
  type: "Type",
  inch: "Pouce",
  season: "Saison",
  amount: "Quantité",
  standardPoints: "Points",
  boosterProduct: "Produit bonus",
  boosterSeason: "Saison bonus",
  otherActions: "Autres actions ou raisons",
  totalPoints: "Total de points",
  income: "Revenus",
  spending: "Dépenses",
  lastMonth: "Solde final",
  loadMore: "Charger 20 transactions supplémentaires",
  typeFilter: "Filtrer par source",
  dateFilter: "Filtrer par période",
  customer_number: "Numéro de client",
  currentPoints: "Points actuels",
  calculationDate: "Date de calcul",
  positivePoints: "Points positifs",
  negativePoints: "Déjà dépensés",
  tyresAmount: "Nombre de pneus par an",
  pointsTotal: "Total de points",
  currentPointState: "État actuel des points",
  currentKickbackState: "Remboursement actuel", // "Kickback" peut être traduit par "remboursement"
  points: "Points",
};
export default {
  messages,
};
