// tenantConfig.js
export const tenantConfigs = {
  tenants: {
    DEFAULT: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    DE: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: false,
      showLogo: false,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: [
        "firstname",
        "surname",
        "company",
        "countrySelect",
      ],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    CH: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: false,
      showLogo: false,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company", "language"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    NL: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--rotated",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: [
        "firstname",
        "surname",
        "company",
        "iban_number",
        "btw_number",
      ],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    FR: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    PT: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: true,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    ES: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: true,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    BE: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      profileFieldsExtraShow: ["job_position", "country"],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    AT: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    FI: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      profileFieldsExtraHide: ["title", "legalForm"],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    SE: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      profileFieldsExtraHide: ["title", "legalForm"],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    DK: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      profileFieldsExtraHide: ["title", "legalForm", "salutation"],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    IE: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    UK: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    IT: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: true,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: false,
      linkInWelcomeBox: "account",
    },
    RO: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: true,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: false,
      linkInWelcomeBox: "account",
    },
    EE: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    LV: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    LT: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    CZ: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    HU: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    PL: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    SK: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
    BG: {
      topLogo: require("@/assets/img/B_MarkLogo.svg"),
      logo: require("@/assets/img/bs_logo_solution.svg"),
      background: "background--default",
      showTopLogo: true,
      showLogo: true,
      modules: [],
      readOnlyProfile: false,
      requiredProfileFields: ["firstname", "surname", "company"],
      requiredOnboardSteps: [1, 2, 3],
      allowDuplicatedCustomerNumber: false,
      allowOnboard: true,
      linkInWelcomeBox: "stammdaten",
    },
  },
};
