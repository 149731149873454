<template>
    <div>
        <CategoryTop :breadcrumbs-parent-routes="['account']" :title="$t('pageName.staffVouchers')"
            :description="$t('vouchers.short_description')" />

        <MDBRow>
            <MDBCol col="12">
                <DashboardCard class="bg-white">
                    <template v-slot:search>
                        <div class="d-flex flex-column justify-content-end align-items-end">
                            <div class="w-100">
                                <MDBInput v-model="query" :label="$t('search')" />
                            </div>
                            <div>
                                <MDBBtn color="danger" @click="search">{{
                                    $t("global.buttons.toSearch")
                                    }}</MDBBtn>
                            </div>
                        </div>
                    </template>
                    <template v-slot:table>
                        <div class="overflow-x-auto">
                            <MDBTable borderless class="table-dashboard">
                                <thead>
                                    <tr>
                                        <th v-for="(head, id) in detailsHeaders" :key="`key_${id}`"
                                            :class="`${head.show ? '' : 'd-none'}`">
                                            <div class="table-header-stats">
                                                <span>{{ head.label }}</span>
                                                <img :src="require('@/assets/img/icons/filter_btn.svg')"
                                                    alt="filter_btn" class="icon-container--small cursor--pointer"
                                                    @click="newSort(head.field)" />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="vouchers.length > 0">
                                    <tr v-for="voucher in vouchers" :key="voucher.code">
                                        <td>{{ voucher.code }}</td>
                                        <td>{{ voucher.company ?? "-" }}</td>
                                        <td>{{ voucher.street ?? "-" }}</td>
                                        <td>{{ voucher.postal_code ?? "-" }}</td>
                                        <td>{{ voucher.city ?? "-" }}</td>
                                        <td>{{ voucher.solved_at ?? "-" }}</td>
                                        <td>{{ voucher.points ?? "-" }}</td>
                                    </tr>
                                </tbody>
                            </MDBTable>
                        </div>
                    </template>
                </DashboardCard>
            </MDBCol>
        </MDBRow>
    </div>

</template>

<script setup>
import {
    MDBCol,
    MDBRow,
    MDBInput,
    MDBBtn,
    MDBTable,
} from "mdb-vue-ui-kit";
import i18n from "@/plugins/i18n";

import CategoryTop from "@/components/General/CategoryTop/index.vue";
import DashboardCard from "@/components/User/Seller/DashboardCard.vue";

import { WholesalerService } from "@/services/wholesaler.service";
import { useStore } from "vuex";

import { ref, onMounted, computed } from "vue";
import dates from "@/plugins/dates";

const store = useStore();
const user = computed(() => store.getters.getMe);

const query = ref(null)
const vouchers = ref([])
const initialVouchers = ref([])

const search = () => {
    if (query.value) {
        const filteredUsers = vouchers.value.filter(voucher => voucher.solved_at === query.value || voucher.code === query.value || voucher.company === query.value || voucher.street === query.value || voucher.postal_code === query.value || voucher.city === query.value);

        vouchers.value = filteredUsers
    } else {
        vouchers.value = initialVouchers.value
    }
}
const sortData = ref({
    field: "",
    order: "asc",
});

const detailsHeaders = ref([
    {
        show: true,
        label: i18n.global.t('vouchers.table.headers.voucher'),
        field: "code"
    },
    {
        show: true,
        label: i18n.global.t('vouchers.table.headers.company'),
        field: "company"
    },
    {
        show: true,
        label: i18n.global.t('vouchers.table.headers.street'),
        field: "street"
    },
    {
        show: true,
        label: i18n.global.t('vouchers.table.headers.postcode'),
        field: "postal_code"
    },
    {
        show: true,
        label: i18n.global.t('vouchers.table.headers.location'),
        field: "city"
    },
    {
        show: true,
        label: i18n.global.t('vouchers.table.headers.solved_at'),
        field: "solved_at"
    },
    {
        show: true,
        label: i18n.global.t('vouchers.table.headers.points'),
        field: "points"
    }
])

onMounted(async () => {
    const response = await WholesalerService.vouchers(user.value.plainId)
    vouchers.value = response.data
    initialVouchers.value = response.data
})

const newSort = (field) => {
    if (sortData.value.field == field) {
        sortData.value.order = sortData.value.order === "asc" ? "desc" : "asc";
    } else {
        sortData.value.order = "asc";
    }
    sortData.value.field = field;
    vouchers.value.sort(dates.compareSortValues(sortData.value.field, sortData.value.order));
};

</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
@import "@/styles/table_dashboard.scss";

.pointer {
    cursor: pointer;
}
</style>