<template>
  <Loader :show="loading" v-if="loading"></Loader>
  <MDBRow v-else>
    <MDBCol lg="4" class="mb-4" v-for="(category, i) in mainCategories" :key="`category_${i}`">
      <Card :data="category" class="h-100" :linkText="$t('global.buttons.discover')" />
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import Card from "@/components/General/Card/index";
import Loader from "@/components/General/Loader/index";

import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();

store.dispatch("loadCategories");

const loading = computed(() => store.getters.getPending);

const mainCategories = computed(() =>
  store.getters.getCategories
    .filter((item) => !item.parent_id)
    .map((item) => {
      return {
        ...item,
        link: item.link || {
          name: "shopCategory",
          params: {
            id: item.id,
          },
          query: { page: 1 }
        },
      };
    })
);
</script>
